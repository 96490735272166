import React, { useEffect, useState } from "react";

import Navigation from "../component/navigation";

import Footer from "../component/footer";
import TopNavbar from "../component/topNavbar";
import { ScreenSizeProvider } from "../context/ScreenSizeContext";
const CategoryPage = () => {

  return (
    <>
      <ScreenSizeProvider>
        <TopNavbar />
      </ScreenSizeProvider>
      <ScreenSizeProvider>
        <Navigation />
      </ScreenSizeProvider>
         
      <Footer />
      
    </>
  );
};
export default React.memo(CategoryPage);
