// import React, { useContext, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import Nav from "react-bootstrap/Nav";
// import NavDropdown from "react-bootstrap/NavDropdown";

// import {

//   BsUiRadiosGrid,
//   BsHouseDoor,
//   BsNut,
//   BsNewspaper,
//   BsListNested,
//   BsPatchQuestion,
//   BsTelephonePlus,
//   BsChevronDown,
//   BsGear,
// } from "react-icons/bs";

// import "../css/nav.css";
// import { selectCategory, setSelectedCategory } from "../redux/categorySlice";
// import { Link, useNavigate } from "react-router-dom";
// import { fetchProducts, selectProducts } from "../redux/productSlice";
// import AllCatagory from "./allCatagory";

// const iconMapping = {
//     BsHouseDoor: <BsHouseDoor />,
//     BsNut: <BsNut />,
//     BsNewspaper: <BsNewspaper />,
//     BsListNested: <BsListNested />,
//     BsPatchQuestion: <BsPatchQuestion />,
//     BsTelephonePlus: <BsTelephonePlus />,
//     BsGear: <BsGear />,
//   };
// const NavMenu=()=>{
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const products = useSelector(selectProducts);

//   const [input, setInput] = useState("");
//   const [otp, setOtp] = useState(new Array(4).fill(""));
//   const [showOtpField, setShowOtpField] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [agreed, setAgreed] = useState(false);
//   const [counter, setCounter] = useState(120);
//   const [show, setShow] = useState(true);
//   const selectedCategory = useSelector(selectCategory);
//   useEffect(() => {
//     setLoading(true); // Set loading to true when fetching products
//     dispatch(fetchProducts())
//       .then(() => setLoading(false))  // On success, set loading to false
//       .catch((err) => {
//         setLoading(false);
//       });
//   }, [dispatch]);

//    const categories = [...new Set(products.map((product) => product.category))]
//   .map((category) => ({
//     name: category,
//     subcategories: [...new Set(
//       products
//         .filter((product) => product.category === category)
//         .map((product) => product.subcategory)
//         .filter(Boolean)
//     )],
//   }));

//     // Access the navigation state from Redux
//     const {  menu } = useSelector((state) => state.navigation);

//     const handleCategoryClickNav = (category) => {
//       dispatch(setSelectedCategory(category)); // Set category in Redux
//       navigate("/productCategory"); // Navigate to ProductCategory page
//     };
//     return(
//         <>

//           {menu.map((menuItem, index) => {
//                 return (
//                   <Link className="nav-link" to={menuItem.linkmenus} key={index}>
//                     {menuItem.icon && iconMapping[menuItem.icon]} {menuItem.name}
//                   </Link>
//                 );
//               })}
//            <AllCatagory
//             categories={categories}
//             selectedCategory={selectedCategory}
//             onSelectCategory={handleCategoryClickNav}

//           />
//         </>
//     )

// }

// export default NavMenu

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { selectCategory, setSelectedCategory } from "../redux/categorySlice";
import { fetchProducts, selectProducts } from "../redux/productSlice";
import "../css/nav.css";

import {
  BsHouseDoor,
  BsNut,
  BsNewspaper,
  BsListNested,
  BsPatchQuestion,
  BsTelephonePlus,
  BsChevronDown,
  BsGear,
  BsChevronRight,
} from "react-icons/bs";

const iconMapping = {
  BsHouseDoor: <BsHouseDoor />,
  BsNut: <BsNut />,
  BsNewspaper: <BsNewspaper />,
  BsListNested: <BsListNested />,
  BsPatchQuestion: <BsPatchQuestion />,
  BsTelephonePlus: <BsTelephonePlus />,
  BsGear: <BsGear />,
};

const NavMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector(selectProducts);
  const selectedCategory = useSelector(selectCategory); // Redux state for selected category
  const [categories, setCategories] = useState([]);
  const { menu } = useSelector((state) => state.navigation);
  useEffect(() => {
    // Fetch products and derive categories from them
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // Update categories when products are updated
    const derivedCategories = [
      ...new Set(products.map((product) => product.category)),
    ].map((category) => ({
      name: category,
      subcategories: [
        ...new Set(
          products
            .filter((product) => product.category === category)
            .map((product) => product.subCategory)
            .filter(Boolean)
        ),
      ],
    }));
    setCategories(derivedCategories);
  }, [products]);

  const handleCategoryClickNav = (category, subCategory = null) => {
    dispatch(setSelectedCategory({ category, subCategory })); // Update selected category in Redux
    navigate("/productCategory"); // Navigate to the Product Category page
  };

  const getDropdownLabel = () => {
    // Update dropdown label based on selected category
    if (selectedCategory.category === "All") return "All Product";
    if (selectedCategory.subCategory)
      return `${selectedCategory.category} - ${selectedCategory.subCategory}`;
    return selectedCategory.category;
  };

  return (
    <>
      {menu.map((menuItem, index) => (
        <Link className="nav-link" to={menuItem.linkmenus} key={index}>
          {menuItem.icon && iconMapping[menuItem.icon]} {menuItem.name}
        </Link>
      ))}

      {/* Category Dropdown */}
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="category-dropdown">
          {getDropdownLabel()}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleCategoryClickNav("All")}>
            All Product
          </Dropdown.Item>
          {categories.map((cat) => (
            <div key={cat.name} className="dropdownNAvMenuShow">
              {/* Parent Category */}

              <Dropdown.Item
                onClick={() => handleCategoryClickNav(cat.name)}
                className={
                  cat.subcategories.length > 0 ? "dropdown-parent" : ""
                }
              >
                {cat.name}{" "}
                <p className="arowrightdrop">
                  <BsChevronRight />
                </p>
              </Dropdown.Item>
              {/* Subcategories */}
              {cat.subcategories.length > 0 && (
                <div className="dropdown-submenu dropdown-submenuNav">
                  {cat.subcategories.map((subcat) => (
                    <Dropdown.Item
                      key={subcat}
                      onClick={() => handleCategoryClickNav(cat.name, subcat)}
                      className="dropdown-subitem"
                    >
                      {subcat}
                    </Dropdown.Item>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NavMenu;
