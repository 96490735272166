// // compareSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import { toast } from "react-toastify";

// const compareSlice = createSlice({
//   name: 'compare',
//   initialState: {
//     compareItems: []
//   },
//   reducers: {
//     addToCompare: (state, action) => {
//       const itemExists = state.compareItems.some(item => item.id === action.payload.id);
//          if (!itemExists) {
//          state.compareItems.push(action.payload);
//         toast.success(`Compare Added Successfully: ${action.payload.name}!`);
//        }
//     },
//     clearCompare: (state) => {
//       state.compareItems = [];
//       toast.info('Comparison list cleared.');
//     },
//     removeFromCompare: (state, action) => {
//       state.compareItems = state.compareItems.filter(item => item.id !== action.payload);
//      toast.info('Product removed from comparison list.');
//     }
//   }
// });

// export const { addToCompare, removeFromCompare, clearCompare } = compareSlice.actions;
// export default compareSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Load initial state from localStorage
const initialState = {
  compareItems: JSON.parse(localStorage.getItem("compareItems")) || [], // Get from localStorage or initialize as an empty array
  compareLimit: 4, // Maximum number of items to compare
};

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    addToCompare: (state, action) => {
      const itemExists = state.compareItems.some(item => item.id === action.payload.id);

      if (!itemExists) {
        // Check if compareItems has reached the limit
        if (state.compareItems.length >= state.compareLimit) {
          state.compareItems.shift(); // Remove the first item if the limit is exceeded
          toast.info("The oldest product was removed to make space for the new one.");
        }

        state.compareItems.push(action.payload);
        // Save updated list to localStorage
        localStorage.setItem("compareItems", JSON.stringify(state.compareItems));
        toast.success(`Compare Added Successfully: ${action.payload.name}!`);
      } else {
        toast.warning(`${action.payload.name} is already in the comparison list.`);
      }
    },
    removeFromCompare: (state, action) => {
      state.compareItems = state.compareItems.filter(item => item.id !== action.payload);
      // Save updated list to localStorage
      localStorage.setItem("compareItems", JSON.stringify(state.compareItems));
      toast.info("Product removed from comparison list.");
    },
    clearCompare: (state) => {
      state.compareItems = [];
      // Clear localStorage
      localStorage.removeItem("compareItems");
      toast.info("Comparison list cleared.");
    },
  },
});

export const { addToCompare, removeFromCompare, clearCompare } = compareSlice.actions;
export default compareSlice.reducer;
