// AuthProvider.js
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setLoginSuccess, logout } from "../redux/authSlice";
import endpoints from "../api/endpoints";

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      axios
        .get(endpoints.verifiToken, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status === "success") {
            dispatch(setLoginSuccess(true));
          } else {
            dispatch(logout());
          }
        })
        .catch(() => {
          dispatch(logout());
        });
    }
  }, [dispatch]);

  return children;
};

export default AuthProvider;
