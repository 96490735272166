// import React from "react";
// import { useSelector } from "react-redux";
// import { Card, Col, Table } from "react-bootstrap";
// import {
//   BsRepeat,
//   BsEye,
//   BsHeart,
//   BsHeartFill,
//   BsCartPlusFill,
// } from "react-icons/bs";
// import { AiOutlineShoppingCart } from "react-icons/ai";
// import "../css/productCard.css";
// import useProductActions from "../utils/productThanos";
// import StartRating from "./starRating";
// import {
//   selectCombinedReviews,
//   selectAverageRating,
//   selectNumberOfReviews,
// } from "../utils/reviewSelector";

// const CompareProduct = ({ product }) => {
//   const {
//     finalPrice,
//     isWishlisted,
//     cartItem,
//     cartItems,
//     isComparelisted,
//     averageRating,
//     numberOfReviews,
//     handleViewProduct,
//     handleAddToWishlist,
//     handleAddToCards,
//     handleRemoveCompare,
//   } = useProductActions(product);

//   return (
//     <Col md={4} className="mb-1 ps-2 pe-2 mb-sm-3 mainProductCart">
//       <Card className="productCard">
//         <div className="productsThumb">
//           <div className="productsThumbLink">
//             {product.discount && (
//               <div className="productLabel">
//                 <span className="onsale">-{product.discount}%</span>
//               </div>
//             )}
//             <button
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 fontSize: "10px",
//               }}
//               onClick={() => handleRemoveCompare(product.id)}
//             >
//               Remove
//             </button>
//             <div className="productImg">
//               <img src={product.image} alt={product.name} />
//             </div>
//             <div className="productMenuList">
//               <ul>
//                 <li
//                   onClick={handleAddToCards}
//                   className={cartItem ? "stayColor added" : "stayColor"}
//                 >
//                   {cartItem ? <BsCartPlusFill /> : <AiOutlineShoppingCart />}
//                 </li>

//                 <li
//                   onClick={handleAddToWishlist}
//                   className={isWishlisted ? "stayColor added" : "stayColor"}
//                 >
//                   {isWishlisted ? <BsHeartFill /> : <BsHeart />}
//                 </li>
//                 <li onClick={handleViewProduct} aria-label="View product">
//                   <BsEye />
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="productCaption" style={{ height: "auto" }}>
//           <div className="ProductReview">
//             <div className="ratings mt-1 d-flex align-items-center gap-2">
//               <div className="rating-stars">
//                 <ul id="stars">
//                   <StartRating productRating={averageRating} />
//                 </ul>
//               </div>
//               <p className="p-0 m-0 mt-1 starratingtext">({numberOfReviews})</p>
//             </div>
//           </div>
//           <div className="productName">
//             <h3 onClick={handleViewProduct}>{product.name}</h3>
//           </div>
//           <div className="productPrize d-block mt-2 mt-sm-0 d-sm-flex align-items-center justify-content-between w-100">
//             {product.discount ? (
//               <>
//                 <h4 className="discountedPrice">${finalPrice.toFixed(2)}</h4>
//                 <h4 className="originalPrice">
//                   <del style={{ fontSize: "13px", opacity: "0.6" }}>
//                     ${product.price.toFixed(2)}
//                   </del>
//                 </h4>
//               </>
//             ) : (
//               <h4>${product.price.toFixed(2)}</h4>
//             )}
//           </div>
//           <div>
//             <div className="product-attribute">
//               {product.attributes && product.attributes.length > 0 ? (
//                 <Table bordered responsive>

//                   <tbody>
//                     {product.attributes.map((attribute, index) => (
//                       <tr key={index}>
//                         <td>{attribute.attributeName}</td>
//                         <td>{attribute.attributeValue}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>
//               ) : (
//                 <p></p>
//               )}
//             </div>
//           </div>
//         </div>
//       </Card>
//     </Col>
//   );
// };

// export default CompareProduct;


import React from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col, Table } from "react-bootstrap";
import {
  BsRepeat,
  BsEye,
  BsHeart,
  BsHeartFill,
  BsCartPlusFill,
  BsFillTrash3Fill,
} from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "../css/productCard.css";
import useProductActions from "../utils/productThanos";
import StartRating from "./starRating";
import {
  selectCombinedReviews,
  selectAverageRating,
  selectNumberOfReviews,
} from "../utils/reviewSelector";

const CompareProduct = ({ product,gridTabs }) => {
  const {
    finalPrice,
    isWishlisted,
    cartItem,
    cartItems,
    showPrice,
    isComparelisted,
    averageRating,
    numberOfReviews,
    handleViewProduct,
    handleAddToWishlist,
    handleAddToCards,
    handleRemoveCompare,
  } = useProductActions(product);





  
  return (
    <Col  md={gridTabs? gridTabs : 3}  className="mb-1 ps-2 pe-2 mb-sm-3 mainProductCart">
      <Card className="productCard">
        <div className="productsThumb">
          <div className="productsThumbLink">
            {product.discount>0 && (
              <div className="productLabel">
                <span className="onsale">-{product.discount}%</span>
              </div>
            )}
            <button
              className="removeCompareBtn"
              onClick={() => handleRemoveCompare(product.id)}
            >
              <BsFillTrash3Fill />
            </button>
            <div className="productImg"  onClick={handleViewProduct} style={{cursor:'pointer'}}>
              <img src={product.image} alt={product.name} />
            </div>
            <div className="productMenuList">
              <ul>
                <li
                  onClick={handleAddToCards}
                  className={cartItem ? "stayColor added" : "stayColor"}
                >
                  {cartItem ? <BsCartPlusFill /> : <AiOutlineShoppingCart />}
                </li>

                <li
                  onClick={handleAddToWishlist}
                  className={isWishlisted ? "stayColor added" : "stayColor"}
                >
                  {isWishlisted ? <BsHeartFill /> : <BsHeart />}
                </li>
                <li onClick={handleViewProduct} aria-label="View product">
                  <BsEye />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="productCaption" style={{ height: "auto" }}>
          <div className="ProductReview">
            <div className="ratings mt-1 d-flex align-items-center gap-2">
              <div className="rating-stars">
                <ul id="stars">
                  <StartRating productRating={averageRating} />
                </ul>
              </div>
              <p className="p-0 m-0 mt-1 starratingtext">({numberOfReviews})</p>
            </div>
          </div>
          <div className="productName">
            <h3 onClick={handleViewProduct}>{product.name}</h3>
          </div>
          {showPrice=='NotShow' ? (<>
            <div className="productPrize notshowPriceBtn d-block mt-2 mt-sm-0 d-sm-flex align-items-center justify-content-between w-100">
             <Button onClick={handleViewProduct}>Check Details & Price</Button>
            </div>
          </>) : (<>
          <div className="productPrize d-block mt-2 mt-sm-0 d-sm-flex align-items-center justify-content-between w-100">
            {product.discount>0 ? (
              <>
                <h4 className="discountedPrice">Tk {finalPrice.toFixed(2)}</h4>
                <h4 className="originalPrice">
                  <del style={{ fontSize: "13px", opacity: "0.6" }}>
                    Tk {product.price.toFixed(2)}
                  </del>
                </h4>
              </>
            ) : (
              <h4>Tk {product.price.toFixed(2)}</h4>
            )}
          </div>
          </>)}

          <div>
            {/* <div className="product-attribute">
              {product.attributes && product.attributes.length > 0 ? (
                <Table bordered responsive>

                  <tbody>
                    {product.attributes.map((attribute, index) => (
                      <tr key={index}>
                        <td>{attribute.attributeName}</td>
                        <td>{attribute.attributeValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p></p>
              )}
            </div> */}
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default CompareProduct;


