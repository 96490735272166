

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import endpoints from "../api/endpoints";
// import { useSelector } from 'react-redux';
// // Initial state
// const initialState = {
//   products: [],
//   loading: false,
//   error: null,
// };

// //  const { accessToken } = useSelector((state) => state.auth);
// // Thunk for fetching products
// export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
//   // const response = await fetch(endpoints.products);
 
//   const response = await fetch(endpoints.products, {
//     method: "GET",
//     mode: "cors", 
//     headers: {
//         Authorization: `Bearer: 7878WEERT35466RDDS`,
//         "Content-Type": "application/json",
//     },

// });


//   if (!response.ok) {
//     throw new Error(`HTTP error! status: ${response.status}`);
//   }
  
//   const result = await response.json();
//   // console.log('API Response:', result);

//   // Assuming the data structure from your API
//   if (result.success && result.data && result.data.products) {
//     return result.data.products; // Extract products from the response
//   } else {
//     throw new Error(result.message || 'Failed to fetch products');
//   }
// });

// const productSlice = createSlice({
//   name: 'products',
//   initialState: {
//     ...initialState,
//     searchQuery: '',
//     filteredProducts: [],
//   },
//   reducers: {
//     setSearchQuery(state, action) {
//       state.searchQuery = action.payload;
//       state.filteredProducts = state.products.filter((product) =>
//         product.name.toLowerCase().includes(action.payload.toLowerCase())
//       );
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchProducts.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchProducts.fulfilled, (state, action) => {
//         state.products = action.payload; // Set fetched products
//         state.loading = false;
//       })
//       .addCase(fetchProducts.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.error.message; // Use action.error.message for better clarity
//       });
//   },
// });


// export const { setSearchQuery } = productSlice.actions;
// export const selectFilteredProducts = (state) => state.products.filteredProducts;
// export const selectProducts = (state) => state.products.products;
// export const selectProductLoading = (state) => state.products.loading;
// export const selectProductError = (state) => state.products.error;

// export default productSlice.reducer;



import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoints from "../api/endpoints";

// Initial state
const initialState = {
  products: [],
  filteredProducts: [],
  searchResults: [],
  loading: false,
  error: null,
};

// Async thunk for fetching products
export const fetchProducts = createAsyncThunk("products/fetchProducts", async (_, { getState }) => {
  
  const response = await fetch(endpoints.products, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer: 7878WEERT35466RDDS`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();

  if (result.success && result.data && result.data.products) {
    return result.data.products; // Extract products from the response
  } else {
    throw new Error(result.message || "Failed to fetch products");
  }
});

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },
    setSearchQuery: (state, action) => {
      const query = action.payload.toLowerCase();
      state.searchResults = state.products.filter((product) =>
        product.name.toLowerCase().includes(query)
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.filteredProducts = action.payload; // Initially, filtered products are all products
        state.loading = false;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setFilteredProducts, setSearchQuery } = productSlice.actions;

export const selectProducts = (state) => state.products.products;
export const selectFilteredProducts = (state) => state.products.filteredProducts;
export const selectSearchResults = (state) => state.products.searchResults;
export const selectProductLoading = (state) => state.products.loading;
export const selectProductError = (state) => state.products.error;

export default productSlice.reducer;
