
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from "react-bootstrap";
import LoadingSpinner from "./loadingSpinner";
import { fetchReviewHistory } from "../redux/reviewHistorySlice";
import '../css/reviewHistory.css'
import useProductActions from "../utils/productThanos";
import { useNavigate } from "react-router-dom";

import Paginationsorq from "./paginationsorq";
import { fetchPaymentHistory } from "../redux/paymentHistorySlice";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { paymentshistory, status, error } = useSelector((state) => state.paymentHistory);
  const { currentPage, itemsPerPage } = useSelector((state) => state.pagination);
  const indexOfLastPayment = currentPage * itemsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - itemsPerPage;
  const currentpaymentshistory = paymentshistory.slice(indexOfFirstPayment, indexOfLastPayment);
  useEffect(() => {
    if (status === 'idle' && accessToken) {
      dispatch(fetchPaymentHistory(accessToken));
    }
  }, [status, accessToken, dispatch]);

  if (status === 'loading') return <LoadingSpinner />;

  if (status === 'failed') {
    return <div className="alert alert-danger m-4">Error: {error}</div>;
  }

  return (
    <div className="mt-4 container personalreviewHistory">
      {currentpaymentshistory?.length === 0 ? (
        <div className="reviewqnaorderhistory">
        <img src="../imgs/nopayment.gif" alt="No reviews available" />
        </div>
      ) : (
       <Row>
         <div className="card-columns" >
          {(currentpaymentshistory || []).map((paymenth) => (
           
              
                  <Card className="w-100 mb-2 p-1 paymentHistryCssCard" key={paymenth.payment_id} 
                 
                >
                    <Card.Body>
                      <div className="">
                       
                        <div className="paymentHistryCss">
                           
                           
                            <p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Account Name: </span> 
  {paymenth.account_name === null ? <span style={{color:'#f07777'}}>No  Name</span> : <span>{paymenth.account_name}</span>}
</p>


<p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Payment Type: </span> 
  {paymenth.payment_method === null ? <span style={{color:'#f07777'}}>Unauthorized</span> : <span>{paymenth.payment_method}</span>}
</p>


<p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Payment Date: </span> 
  {paymenth.payment_date === null ? <span style={{color:'#f07777'}}>No Date</span> : <span>{paymenth.payment_date}</span>}
</p>


<p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Bank Name: </span> 
  {paymenth.bank_name === null ? <span style={{color:'#f07777'}}>No Name</span> : <span>{paymenth.bank_name}</span>}
</p>                      
                           
<p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Received By: </span> 
  {paymenth.received_by === null ? <span style={{color:'#f07777'}}>No Receiver</span> : <span>{paymenth.received_by}</span>}
</p>                                

<p>
  <span style={{ fontWeight: '600', opacity: '0.8', color: '#000' }}>Transaction : </span> 
  {paymenth.transaction_id === null ? <span style={{color:'#f07777'}}>No ID Found</span> : <span>{paymenth.transaction_id}</span>}
</p>                                
                            
            
                           



                         
                          
                          
                          
                         
                          {/* <div className="text-muted small">
                            Price: Tk {review.price.toLocaleString('en-BD')}
                          </div> */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
           
           
          ))}
           </div>
           
         </Row>
      )}
       <Paginationsorq totalItems={paymentshistory.length} />
      <br></br>
    </div>
  );
};

export default PaymentHistory;