// import { createSlice } from '@reduxjs/toolkit';

// // Initial state for Today's Deals
// const initialState = {
//   activeDeals: [],
// };

// // Today Deals Slice
// const todayDealsSlice = createSlice({
//   name: 'todayDeals',
//   initialState,
//   reducers: {
//     setActiveDeals: (state, action) => {
//       state.activeDeals = action.payload; // Set the active deals
//     },
//     updateExpiredDeals: (state) => {
//       const now = Date.now();
//       state.activeDeals = state.activeDeals.map((product) => {
//         const expiryDate = new Date(product.expiryDate).getTime();
//         if (expiryDate <= now) {
//           return {
//             ...product,
//             isDeal: false,
//             discount: '', // Reset discount when deal expires
//           };
//         }
//         return product;
//       });
//     },
//     // Optional: You could also implement a mechanism to set a product back to a deal if the expiry date is extended, etc.
//   },
// });

// // Actions
// export const { setActiveDeals, updateExpiredDeals } = todayDealsSlice.actions;

// // Selectors
// export const selectActiveDeals = (state) => state.todayDeals.activeDeals;

// export default todayDealsSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

// Initial state for Today's Deals
const initialState = {
  activeDeals: [],
};

// Today Deals Slice
const todayDealsSlice = createSlice({
  name: 'todayDeals',
  initialState,
  reducers: {
    setActiveDeals: (state, action) => {
      state.activeDeals = action.payload.filter((product) => product.isDeal); // Filter in the slice
    },
    updateExpiredDeals: (state) => {
      const now = Date.now();
      state.activeDeals = state.activeDeals.map((product) => {
        const expiryDate = new Date(product.expiryDate).getTime();
        return expiryDate <= now
          ? { ...product, isDeal: false, discount: '' }
          : product;
      }).filter((product) => product.isDeal); // Remove expired deals
    },
  },
});

// Actions
export const { setActiveDeals, updateExpiredDeals } = todayDealsSlice.actions;

// Selectors
export const selectActiveDeals = (state) => state.todayDeals.activeDeals;

export default todayDealsSlice.reducer;
