import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col } from "react-bootstrap";
import {
  BsRepeat,
  BsEye,
  BsHeart,
  BsHeartFill,
  BsCartPlusFill,
  BsShuffle,
} from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "../css/productCard.css";
import StartRating from "./starRating";

import useProductActions from "../utils/productThanos";
const ProductCard = React.memo(({ product, axlio, viewMode, axliox }) => {
  const {
    finalPrice,
    isWishlisted,
    cartItem,
    showPrice,
    isComparelisted,
    averageRating,
    numberOfReviews,
    handleViewProduct,
    handleCompareProduct,
    handleAddToWishlist,
    handleAddToCards,
  } = useProductActions(product);
  const isOutOfStock = useMemo(() => product.stock === 0, [product.stock]);
  // Check if product data is available
  if (!product || !product.image || !product.price) {
    console.error("Product data is undefined or missing essential fields");
    return null;
  }

  return (
    <Col
      md={12}
      lg={axliox}
      className={`mb-1 ps-2 pe-2 mb-sm-3 mainProductCart ${
        viewMode === "list" ? "product-list-view" : "product-grid-view"
      }`}
    >
      <Card className={`productCard ${isOutOfStock ? "out-of-stock" : ""}`}>
        <div className="productsThumb">
          <div
            className="productsThumbLink"
            style={
              !isOutOfStock ? { cursor: "pointer" } : { cursor: "default" }
            }
            onClick={
              !isOutOfStock
                ? handleViewProduct
                : () => console.log("Out of stock")
            }
          >
            {isOutOfStock && (
              <div className="outOfStockLabel">
                <span className="outOfStock">Stock Out</span>
              </div>
            )}
            {(product.discount > 0 || product.discount == "") &&
              !isOutOfStock && (showPrice=='Show') && (
                <div className="productLabel">
                  <span className="onsale">-{product.discount}%</span>
                </div>
              )}

            <div className="productImg">
              <img src={product.image} alt={product.name} />
            </div>
          </div>
          <div className="wishlistTopbar">
            <p
              onClick={handleAddToWishlist}
              className={isWishlisted ? "stayColor added" : "stayColor"}
            >
              {isWishlisted ? <BsHeartFill /> : <BsHeart />}
            </p>
          </div>
          {!isOutOfStock && (
            <div className="productMenuList">
              <ul>
                <li
                  onClick={handleAddToCards}
                  className={cartItem ? "stayColor added" : "stayColor"}
                >
                  {cartItem ? <BsCartPlusFill /> : <AiOutlineShoppingCart />}
                </li>
                <li
                  onClick={handleCompareProduct}
                  className={isComparelisted ? "stayColor added" : "stayColor"}
                >
                  <BsShuffle />
                </li>

                <li onClick={handleViewProduct} aria-label="View product">
                  <BsEye />
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="productCaption">
          <div className="ProductReview">
            <div className="ratings mt-1 d-flex align-items-center gap-2">
              <div className="rating-stars">
                <ul id="stars">
                  <StartRating productRating={averageRating} />
                </ul>
              </div>
              <p className="p-0 m-0 mt-1 starratingtext">({numberOfReviews})</p>
            </div>
          </div>
          <div className="productName">
            <div
              onClick={
                !isOutOfStock
                  ? handleViewProduct
                  : () => console.log("Out of stock")
              }
            >
              <h3>{product.name}</h3>
            </div>
          </div>
          {showPrice=='NotShow' ? (<>
            <div className="productPrize notshowPriceBtn d-block mt-2 mt-sm-0 d-sm-flex align-items-center justify-content-between w-100">
             <Button onClick={handleViewProduct}>Check Details & Price</Button>
            </div>
          </>): (<>
          <div className="productPrize d-block mt-2 mt-sm-0 d-sm-flex align-items-center justify-content-between w-100">
            {product.discount > 0 || product.discount == ""   ? (
              <>
                <h4 className="discountedPrice">Tk {finalPrice.toFixed(2)}</h4>
                <h4 className="originalPrice">
                  <del style={{ fontSize: "13px", opacity: "0.6" }}>
                    Tk {product.price.toFixed(2)}
                  </del>
                </h4>
              </>
            ) : (
              <h4>Tk {product.price.toFixed(2)}</h4>
            )}
          </div>
          </>)}

        </div>
      </Card>
    </Col>
  );
});

export default ProductCard;
