import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../api/endpoints";



export const fetchWeekDeals = createAsyncThunk("weekDeals/fetchWeekDeals", async (_, { getState }) => {
  
  const response = await fetch(endpoints.weekdeals , {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer: 7878WEERT35466RDDSww`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();

  if (result.success && result.data && result.data.products) {
    return result.data.products; // Extract products from the response
  } else {
    throw new Error(result.message || "Failed to fetch products");
  }
});

const weekDealsSlice = createSlice({
  name: "weekDeals",
  initialState: {
    weekDeals: [],
    status: "idle", // "idle" | "loading" | "succeeded" | "failed"
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeekDeals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWeekDeals.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.weekDeals = action.payload;
      })
      .addCase(fetchWeekDeals.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectWeekDeals = (state) => state.weekDeals.weekDeals;
export default weekDealsSlice.reducer;
