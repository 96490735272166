



import React, { useState, useEffect, useMemo } from 'react';  
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Modal, Pagination, DropdownButton, Dropdown, ProgressBar } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { toast } from "react-toastify";
import '../css/rating.css';
import { BsFunnel, BsArrowDownUp } from 'react-icons/bs';
import { addReview, initializeReviews, selectReviews } from '../redux/reviewSlice';
import { selectProducts } from '../redux/productSlice';
import { selectCombinedReviews, selectAverageRating, selectNumberOfReviews } from '../utils/reviewSelector';
import StartRating from './starRating';
import axios from 'axios';
import { fetchReviewHistory } from '../redux/reviewHistorySlice';

const ReviewForm = ({ productId }) => {
  const products = useSelector(selectProducts);
  const reviews = useSelector(selectReviews);
  const dispatch = useDispatch();

  const product = products.find(p => p.id === productId);
  const staticReviews = product?.reviewsList || [];
  const combinedReviewsList = useSelector(selectCombinedReviews(product?.id));
  const averageRating = selectAverageRating(combinedReviewsList);
  const numberOfReviews = selectNumberOfReviews(combinedReviewsList);

  useEffect(() => {
    if (!reviews[productId] && staticReviews.length > 0) {
      dispatch(initializeReviews({ productId, existingReviews: staticReviews }));
    }
  }, [dispatch, productId, staticReviews, reviews]);

  const [name, setName] = useState('');
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [filteredRating, setFilteredRating] = useState(5);
  const [sortOrder, setSortOrder] = useState('recent-to-old');
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [hover, setHover] = useState(null);
 const { loginSuccess,accessToken } = useSelector(
    (state) => state.auth
  );
  const handleSubmit = async(e) => {
    e.preventDefault();
  if (!isAnonymous && name.trim() === '') {
      setModalMessage('Please provide your name or choose to post anonymously.');
      setShowModal(true);
      return;
  }
    if (review.trim() === '') {
      setModalMessage('Please write your review.');
      setShowModal(true);
      return;
  }



  // Check if the rating is not selected
  if (rating === 0) {
      setModalMessage('Please provide a rating.');
      setShowModal(true);
      return;
  }


    const newReview = { name: isAnonymous ? "Anonymous" : name, PersonRating: rating, review };
    try {
      const response = await axios.post(
        'http://store-admin.scldev.com:8084/api/v1/product-reviews',
        {
          is_anonymous:isAnonymous ? 1 : 0,
          product_id: productId,
          customer_name: newReview.name,
          review_star: newReview.PersonRating,
          review: newReview.review,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    
      if (response.status === 200 || response.status === 201) {
        dispatch(addReview({ productId, newReview }));
        setName('');
        setRating(0);
        setReview('');
        setIsAnonymous(false);
        dispatch(fetchReviewHistory(accessToken));
        toast.success('Review added successfully!');
      } else {
        toast.error('Failed to submit review. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('An error occurred while submitting the review.');
    }

  };

  // Filter and Sort Logic
  const filteredReviews = useMemo(() => {
    const filtered = filteredRating === 'all' 
      ? combinedReviewsList 
      : combinedReviewsList.filter(rev => rev.PersonRating === filteredRating);

    return sortOrder === 'recent-to-old' 
      ? filtered.slice().reverse() 
      : filtered;
  }, [filteredRating, combinedReviewsList, sortOrder]);

  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);
  const totalPages = Math.ceil(filteredReviews.length / reviewsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Reset pagination to first page when filter or sort order changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filteredRating, sortOrder]);

  // Calculate rating counts for progress bars
  const ratingCounts = combinedReviewsList.reduce((acc, review) => {
    acc[review?.PersonRating]++;
    return acc;
  }, { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });

  const totalReviews = numberOfReviews; // You can directly use numberOfReviews

  return (
      <div className='ratingReview'>
        <h3>Ratings & Reviews of {product?.name}</h3>
        <div className='avarageRatingProgressBar d-flex w-100 gap-5 align-items-center mt-2'>
          <div className='avarageRating'>
            <p className='avarageRatingPoint'>{averageRating}<span>/5</span></p>
            <ul id="stars" className="d-flex list-unstyled p-0 m-0">
              {/* <StartRating productRating={averageRating} /> */}
              <StartRating productRating={averageRating || 0} numberOfReviews={numberOfReviews || 0} />
            </ul>
            <span className='avarageRatingMan'>{numberOfReviews} Reviews</span>
          </div>
          <div className='ratingProgressBar w-100'>
          {Object.entries(ratingCounts)
            .reverse() // Show 5-star first
            .map(([stars, count]) => (
              <div key={stars} className="d-flex align-items-center mb-2">
                <div className="star-label">
                  <span>{stars}</span> <FaStar />
                </div>
                <ProgressBar
                  now={totalReviews > 0 ? (count / totalReviews) * 100 : 0} // Prevent division by zero
                  label={`${count}`}
                  className="flex-grow-1 mx-0"
                />
              </div>
            ))}
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center product-Review-rating'>
         <h6>Product Review</h6>
          <div className='d-flex gap-2 align-items-center flex-row'>
            <div className="sort-dropdown">
                <DropdownButton id="sort-order-dropdown" title={<><BsArrowDownUp /> <span>Sort:</span>  {sortOrder === 'recent-to-old' ? 'Recent to Old' : 'Old to Recent'}</>}>
                  <Dropdown.Item active={sortOrder === 'recent-to-old'} onClick={() => setSortOrder('recent-to-old')}>
                    Recent to Old
                  </Dropdown.Item>
                  <Dropdown.Item active={sortOrder === 'old-to-recent'} onClick={() => setSortOrder('old-to-recent')}>
                    Old to Recent
                  </Dropdown.Item>
                </DropdownButton>
            </div>
            <div className="filter-dropdown">
              <DropdownButton id="filter-rating-dropdown" title={<><BsFunnel /> <span>Filter: </span> {filteredRating === 'all' ? 'All Stars' : filteredRating + ' Star'}</>}>
                <Dropdown.Item active={filteredRating === 'all'} onClick={() => setFilteredRating('all')}>
                All Stars
               </Dropdown.Item>
                {[...Array(5)].map((_, index) => (
                <Dropdown.Item key={index + 1} active={filteredRating === index + 1} onClick={() => setFilteredRating(index + 1)}>
                  {index + 1} Star
                </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
      </div>
     <div className="filtered-reviews mt-1">
         {currentReviews.length > 0 ? (
            currentReviews.map((review, index) => (
              <div key={index} className="review-item">
                <div className='d-flex align-items-center gap-1'>
                  <strong>{review.name}</strong> 
                  <ul id="stars" className="d-flex list-unstyled p-0 m-0">
                    <StartRating productRating={review.PersonRating} />
                  </ul>
                </div>
                <p>{review.review}</p>
              </div>
            ))
          ) : (
            <p className='mt-3 mb-3'><img src='../imgs/noreview.gif' alt="No reviews available"/></p>
          )}
        <div className='custom-pagination-rq'>
          {filteredReviews.length > reviewsPerPage && (
            <Pagination className="mt-3">
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
              {[...Array(totalPages).keys()].map((number) => {
                if (number < 3) {
                  return (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                      {number + 1}
                    </Pagination.Item>
                  );
                }
                if (number === currentPage - 1 || number === currentPage || number === currentPage + 1) {
                  return (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                      {number + 1}
                    </Pagination.Item>
                  );
                }
                if (number === 3 && currentPage > 4) {
                  return <Pagination.Ellipsis key="ellipsis1" />;
                }
                if (number >= totalPages - 2) {
                  return (
                    <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                      {number + 1}
                    </Pagination.Item>
                  );
                }
                return null;
              })}
              <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
            </Pagination>
          )}
        </div>
     </div>
{loginSuccess && (
  <>
      <h4>Leave a Review</h4>
    <div className='reviewfromLeave'>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="anonymous">
          <Form.Check
            type="checkbox"
            label="Post as Anonymous"
            checked={isAnonymous}
            onChange={() => setIsAnonymous(!isAnonymous)}
          />
        </Form.Group>
        <Form.Group controlId="name">
        <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            value={isAnonymous ? "Anonymous" : name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
            disabled={isAnonymous}
            required={!isAnonymous}
          />
        </Form.Group>
        <Form.Group controlId="review" className='mt-2'>
        <Form.Label>Your Review</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Your Review"
           
          />
        </Form.Group>
        
        <Form.Group controlId="rating" className='mt-2'>
        <Form.Label className='mt-1 mb-0'>Your Rating</Form.Label>
          <div className="rating-stars">
            {[...Array(5)].map((_, index) => (
              <label key={index} style={{fontSize:'16px',cursor:"pointer"}}>
                <input
                  type="radio"
                  name="rating"
                  style={{ display: 'none' }}
                  value={index + 1}
                  onClick={() => setRating(index + 1)}
                />
                <FaStar
                  className="star"
                  color={(hover || rating) > index ? "#ffc107" : "#e4e5e9"}
                  onMouseEnter={() => setHover(index + 1)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            ))}
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit Review
        </Button>
      </Form>
    </div>
  </>
)}

  
    

      {/* Modal for submission confirmation */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Submission Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReviewForm;
