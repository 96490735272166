// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element: Component, ...rest }) => {

  const {loginSuccess } = useSelector((state) => state.auth);
  return loginSuccess ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/signin" replace />
  );
};

export default PrivateRoute;
