import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import endpoints from '../api/endpoints';

export const fetchOrderHistory = createAsyncThunk('orderHistory/fetchOrderHistory', 
    
// async () => {
//   const response = await axios.get('http://127.0.0.1:8000/api/v1/customer-orders');
//   return response.data.data.customer_orders;
// }

async (accessToken, { rejectWithValue }) => {
    try {
      // axios.defaults.baseURL = endpoints.customersBaseurl; // Replace with your API URL
      // axios.defaults.withCredentials = true;
      const response = await axios.get(endpoints.orderHistoryDetails, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data.data.customer_orders;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || "Failed to fetch orders");
    }
  }

);

const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState: {
    orders: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchOrderHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default orderHistorySlice.reducer;
