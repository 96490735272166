// src/redux/slices/topNavbarSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch topNavbar data
export const fetchTopNavbarData = createAsyncThunk(
  "topNavbar/fetchData",
  async () => {
    const response = await fetch("../api/topNavbarData.json");
    return await response.json();
  }
);

const topNavbarSlice = createSlice({
  name: "topNavbar",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopNavbarData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTopNavbarData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTopNavbarData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default topNavbarSlice.reducer;
