


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import ProductCard from './productCard';
// import { FaRegClock } from 'react-icons/fa';
// import { Button } from 'react-bootstrap';
// import { selectProducts } from '../redux/productSlice';
// import { setActiveDeals, updateExpiredDeals, selectActiveDeals } from '../redux/todayDealsSlice';

// const TodaysDeals = () => {
//   const dispatch = useDispatch();
//   const products = useSelector(selectProducts);
//   const activeDeals = useSelector(selectActiveDeals);

//   // Set active deals when products are fetched
//   useEffect(() => {
//     if (products.length > 0) {
//       dispatch(setActiveDeals(products));
//     }
//   }, [products, dispatch]);

//   // Update expired deals every second
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       dispatch(updateExpiredDeals());
//     }, 1000);

//     return () => clearInterval(intervalId); // Clean up the interval on unmount
//   }, [dispatch]);

//   const formatTime = (timeInMillis) => {
//     const hours = Math.floor(timeInMillis / 1000 / 60 / 60);
//     const minutes = Math.floor((timeInMillis / 1000 / 60) % 60);
//     const seconds = Math.floor((timeInMillis / 1000) % 60);
//     return `${hours}h ${minutes}m ${seconds}s`;
//   };

//   return (
//     <div>
//       <h2>Today's Deals</h2>
//       {activeDeals.length > 0 ? (
//         <div className="row">
//           {activeDeals.map((product) => {
//             const timeRemaining = new Date(product.expiryDate).getTime() - Date.now();
//             return (
//               <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
//                 <ProductCard product={product} />
//                 {timeRemaining > 0 ? (
//                   <>
//                     <div className="countdown-timer">
//                       <FaRegClock /> {formatTime(timeRemaining)}
//                     </div>
//                     <Button variant="primary">Buy Now</Button>
//                   </>
//                 ) : (
//                   <Button variant="secondary" disabled>
//                     Deal Expired
//                   </Button>
//                 )}
//               </div>
//             );
//           })}
//         </div>
//       ) : (
//         <p>No active deals today.</p>
//       )}
//     </div>
//   );
// };

// export default TodaysDeals;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from './productCard';
import { FaRegClock } from 'react-icons/fa';
import { Button, Container } from 'react-bootstrap';
import { selectProducts } from '../redux/productSlice';
import { setActiveDeals, updateExpiredDeals, selectActiveDeals } from '../redux/todayDealsSlice';

const TodaysDeals = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const activeDeals = useSelector(selectActiveDeals);

  // Set active deals when products are fetched
  useEffect(() => {
    if (products.length > 0) {
      // Simulating expiry in 2 hours
      const updatedProducts = products.map((product) => ({
        ...product,
        expiryDate: new Date(Date.now() + 2 * 60 * 60 * 1000).toISOString(), // 2 hours from now
      }));
      dispatch(setActiveDeals(updatedProducts)); // Use the updated expiry date for the test
    }
  }, [products, dispatch]);

  // Update expired deals every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(updateExpiredDeals());
    }, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [dispatch]);

  const formatTime = (timeInMillis) => {
    const hours = Math.floor(timeInMillis / 1000 / 60 / 60);
    const minutes = Math.floor((timeInMillis / 1000 / 60) % 60);
    const seconds = Math.floor((timeInMillis / 1000) % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <section>
      <Container>
      <h2>Today's Deals</h2>
      {activeDeals.length > 0 ? (
        <div className="row">
          {activeDeals.map((product) => {
            const timeRemaining = new Date(product.expiryDate).getTime() - Date.now();
            return (
              <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                <ProductCard product={product} />
                {timeRemaining > 0 ? (
                  <>
                    <div className="countdown-timer">
                      <FaRegClock /> {formatTime(timeRemaining)}
                    </div>
                    <Button variant="primary">Buy Now</Button>
                  </>
                ) : (
                  <Button variant="secondary" disabled>
                    Deal Expired
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <p>No active deals today.</p>
      )}
      </Container>
    </section>
  );
};

export default TodaysDeals;
