
import { addToCart, removeFromCart } from "../redux/cartSlice";
import { addToWishlist, removeFromWishlist } from "../redux/wishlistSlice";
import { toast } from "react-toastify";
import { calculateDiscountAndFinalPrice } from "./priceCalculation";

export const handleAddToCart = (dispatch, product, cartItems, setIsAdded) => {
  const isProductInCart = cartItems.find(item => item.id === product.id);

  if (isProductInCart) {
    const updatedQuantity = isProductInCart.orderQuantity + 1;

    // Re-add the product with the updated quantity
    dispatch(
      addToCart({
        ...product,
        orderQuantity: updatedQuantity,
        // price: isProductInCart.price,
        orderPrice:isProductInCart.price
      })
    );

    toast.info(`Quantity updated for ${product.name}!`);
  } else {
    // Calculate the final price using the utility
    const { finalPrice } = calculateDiscountAndFinalPrice(product.price, product.discount);

    // Add the product to the cart
    dispatch(
      addToCart({
        ...product,
        orderQuantity: product.orderQuantity || 1, // Default to 1 if no quantity is provided
        orderPrice: finalPrice // Use the final price after discount
      })
    );

    setIsAdded(true);
    toast.success(`${product.name} added to cart!`);
  }
};


export const toggleWishlist = (
  dispatch,
  product,
  wishlistItems,
  isWishlisted,
  setIsAddedW,
  
) => {
  const { finalPrice } = calculateDiscountAndFinalPrice(product.price, product.discount);
  if (isWishlisted) {
    dispatch(removeFromWishlist({ id: product.id }));
    toast.info(`${product.name} removed from wishlist.`);
    setIsAddedW(false);
  } else {
    dispatch(addToWishlist
      ({
        ...product,
      
        orderPrice:finalPrice
      })

    );
    toast.success(`${product.name} added to wishlist!`);
    setIsAddedW(true);
  }
};



export const handleRemoveProduct = (dispatch, productId, cartItems) => {
  const cartItem = cartItems.find(item => item.id === productId);
  if (cartItem) {
    dispatch(removeFromCart({ id: productId }));
    toast.info(`${cartItem.name} removed from the cart.`);
  }
};