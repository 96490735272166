// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
// import axios from "axios";
// import Select from "react-select";
// import useProductActions from "../utils/productThanos";
// import Navigation from "../component/navigation";
// import endpoints from "../api/endpoints";
// import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
// import "../css/checkout.css";
// import { BsCheck2, BsFillPlusCircleFill, BsXLg } from "react-icons/bs";
// import { Navigate, useNavigate } from "react-router-dom";
// import {useForm} from "react-hook-form";
// import { fetchOrderHistory } from "../redux/orderHistorySlice";
// import LoadingSpinner from "../component/loadingSpinner";

// const Checkout = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [divisions, setDivisions] = useState([]);
//   const [districts, setDistricts] = useState([]);
//   const [upazilas, setUpazilas] = useState([]);
//   const [paymentType, setPaymentType] = useState(1);
//  const [saveNewAddress,setsaveNewAddress]=useState(null)
//   const [selectedDivision, setSelectedDivision] = useState(null);
//   const [selectedDistrict, setSelectedDistrict] = useState(null);
//   const [selectedUpazila, setSelectedUpazila] = useState(null);
//   const { accessToken,user } = useSelector((state) => state.auth);
//   const { customer, status, error } = useSelector((state) => state.customerDetails);
//   const [loading, setLoading] = useState(false)
//   const { cartItems,totalPrice ,handleClearCart,vat_percentage,totalWithVat,vat,deleverCharge} = useProductActions();
// // Define state hooks at the top of your component
// const [partialPayment, setPartialPayment] = useState(false); // Boolean for enabling partial payment
// const [partialAmount, setPartialAmount] = useState(0);;

//   const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
//   const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
//   const [newAddress, setNewAddress] = useState(null);
//   const [editedData, setEditedData] = useState({
//     name: "",
//     email: "",
//     phone:"",
//     address: [],
//   });
//   const [remark, setRemark] = useState('');  // Track textarea value

//   const handleRemarkChange = (e) => {
//     setRemark(e.target.value); // Update state on textarea input
//   };



//   // Fetch Divisions

//   useEffect(() => {
//     const fetchDivisions = async () => {
//       try {
//         const response = await axios.get("https://bdapis.com/api/v1.2/divisions",
//           {
//           withCredentials: false, // Ensure credentials are not sent
         
//           }
//         );
//         const divisionOptions = response.data.data.map((division) => ({
//           value: division.division,
//           label: division.division,
//         }));
//         setDivisions(divisionOptions);
//       } catch (error) {
//         console.error("Error fetching divisions:", error);
//       }
//     };

//     fetchDivisions();
//   }, []);


//   // Fetch Districts when a Division is selected
//   useEffect(() => {
//     if (!selectedDivision) return;

    
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(`https://bdapis.com/api/v1.2/division/${selectedDivision.value.toLowerCase()}`,
//           {
//           withCredentials: false, // Ensure credentials are not sent
         
//           }
//         );
        
//         console.log('add')
//         const districtOptions = response.data.data.map((district) => ({
//           value: district.district,
//           label: district.district,
//           upazilas: district.upazilla,
//         }));
//         setDistricts(districtOptions);
//         setUpazilas([]); // Reset upazilas
//         setSelectedDistrict(null); // Reset selected district
//         setSelectedUpazila(null); // Reset selected upazila
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };

//     fetchDistricts();
//   }, [selectedDivision]);

//   // Update Upazilas when a District is selected
//   useEffect(() => {
//     if (!selectedDistrict) return;

//     const upazilaOptions = selectedDistrict.upazilas.map((upazila) => ({
//       value: upazila,
//       label: upazila,
//     }));
//     setUpazilas(upazilaOptions);
//     setSelectedUpazila(null); // Reset selected upazila
//   }, [selectedDistrict]);








//   useEffect(() => {
//     if (customer) {
//       setEditedData((prevData) => ({
//         name: prevData.name || customer?.customer_details?.name || "",
//         email: prevData.email || customer?.customer_details?.email || "",
//         phone: prevData.phone || customer?.user_id || "",
//         address: customer?.customer_address || [],
//       }));
//     }
//   }, [customer]);

//   // const handleAddNewAddress = () => {
//   //   setNewAddress({
//   //     street_no: "",
//   //     post_office: "",
//   //     thana: thanaOptions[0],
//   //     dist: distOptions[0],
//   //   });
//   // };
//   const handleAddAddress = (type) => {
//     // const maxAddressId = customer.customer_address?.reduce((maxId, address) => Math.max(maxId, address.customer_address_id), 0) || 0;
//     // const newAddressId = maxAddressId + 1;

//     setNewAddress({
//       address_type_id: type === "Delivery" ? 1 : 2,
//       address_type: type,
//       deliver_to:"",
//       street_no: "",
//       post_office: "",
//       division:divisions[0],
//       thana: upazilas[0],
//       dist: districts[0],
//       state: "",
//       country: "",
//     });

//   };

//   const handleSaveAddress = async () => {
//     try {
     
//       const address = newAddress;
//      await axios.post(endpoints.addAddress, address, {
//           headers: { Authorization: `Bearer ${accessToken}` },
//       });
//       console.log(address)
//       console.log('successfull added')
//       setNewAddress(null);
//       dispatch(fetchCustomerDetails(accessToken));
//     } catch (error) {
//       console.log('faild added')
//     }
//   };


//   //####################################
//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: {errors},
//   } = useForm();
//   //####################################

  
//   const handlePlaceOrder = async () => {
//     try {
//       if (
//         !editedData.name.trim() ||
//         !editedData.email.trim() ||
//         !editedData.phone.trim() ||
//         !paymentType ||
//         !selectedBillingAddress ||
//         !selectedDeliveryAddress.customer_address_id ||
//         cartItems.length === 0
//       ) {
//         alert("Please fill all the required fields before placing the order.");
//         return;
//       }
  
//       if (paymentType === 2 && partialPayment && partialAmount < totalWithVat * 0.6) {
//         alert("You must pay at least 60% of the total amount for partial payment.");
//         return;
//       }
  
//       const partialPaymentAmount = partialPayment
//         ? partialAmount.toFixed(2) // Use the dynamically adjusted partial amount
//         : null;
  
//       const duePayment = partialPayment
//         ? (totalWithVat - partialAmount).toFixed(2)
//         : null;
  
//       const payload = {
//         customer_id: customer?.id,
//         customer_name: editedData.name,
//         email: editedData.email,
//         mobile_number: editedData.phone,
//         order_date: new Date().toISOString().split("T")[0],
//         order_status: 1,
//         order_amount: totalPrice.toFixed(2),
//         discount_percentage: "0",
//         total_discount: "0",
//         vat_percentage: vat_percentage,
//         total_vat: vat.toFixed(2),
//         grand_total: totalWithVat.toFixed(2),
//         partial_payment: partialPaymentAmount,
//         due_payment: duePayment,
//         total_payment:partialPaymentAmount===null ? totalWithVat.toFixed(2) :  partialPaymentAmount,
//         delivery_charge: deleverCharge,
//         payment_type_id: paymentType,
//         bill_address_id:
//           selectedBillingAddress === "same"
//             ? selectedDeliveryAddress.customer_address_id
//             : selectedBillingAddress.customer_address_id,
//         delivery_address_id: selectedDeliveryAddress.customer_address_id,
//         remarks: remark,
//         order_lines: cartItems.map((item) => ({
//           product_id: item.id,
//           product_code: item.id,
//           quantity: item.orderQuantity,
//           unit_price: item.price.toFixed(2),
//           discount_percentage: item.discount > 0 ? item.discount : 0.0,
//           is_offer_product: item.discount > 0 ? 1 : 0,
//           is_gift_product: "false",
//         })),
//         currency: "BDT",
//       };
//       console.log(payload)
//       setLoading(true);
//       if (paymentType === 1) {
//         // Cash on delivery
//         await axios.post(endpoints.confirmOrder, payload, {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         });
//         dispatch(fetchOrderHistory(accessToken));
//         navigate("/orderSuccess");
//         handleClearCart();
//       } 
//       else if (paymentType === 2) {
//         // Online payment
//         // console.log(payload)
//         await axios.post(endpoints.confirmOrderOnline, payload, {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         }).then(function(resp){

//                         if(resp.data.original){
//                           window.location.replace(resp.data.original);
//                         }
                       
                        
//                      }).catch(function(err){
//                         console.log('api data error')
//                    }).then(function(resp){
//                         console.log(resp);
                        
//                    });

                 
//       }
//     } 
//     catch (error) {
//       console.log("Failed to place order");
//     }finally {
//       setLoading(false); // Hide the loader
//       handleClearCart();
//     }
   
//   };
  


//   const billingAddresses = editedData.address.filter(addr => addr.address_type === "Billing");
//   // Filter only delivery addresses for the delivery dropdown
//   const deliveryAddresses = editedData.address.filter(addr => addr.address_type === "Delivery");

//   const handlePaymentTypeChange = (type) => {
//     setPaymentType(type);
//     if (type !== 2) {
//       // Reset partial payment when not online
//       setPartialPayment(false);
//       setPartialAmount(0);
//     }
//   };
  
//   const handlePartialAmountChange = (e) => {
//     const value = parseFloat(e.target.value) || 0;
//     const minimumPartialAmount = totalWithVat * 0.6; // 60% of total
//     setPartialAmount(Math.max(minimumPartialAmount, value));
//   };

//   return (
//     <>
//    <Navigation />
//      <div className="checkoutPageSection">
//         <Container>
//          <Row>
//           <Col md={8} className="">
//              <div className="boxCenter mw-100">
//                <h2 className="checkoutTitle">Checkout</h2>
//                   <div className="chekoutUserDetails mt-3">
//                     <Row>
//                         <Col md={6}>
//                            <div className="mb-2 inputUser">
//                               <label>Name:</label>
//                               <input
//                                 className="form-control"
//                                 type="text"
//                                 value={editedData.name}
//                                 onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
//                                 placeholder="Enter your name...."
//                                 />
//                             </div>
//                         </Col>
//                         <Col md={6}>
//                             <div className="mb-2  inputUser">
//                               <label>Email:</label>
//                               <input
//                                 className="form-control"
//                                 type="email"
//                                 value={editedData.email}
//                                 onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
//                                 placeholder="Enter your email...."
//                                     />
//                             </div>
//                         </Col>
//                     </Row>
                    

                   
                    
//                     <div className="mb-2 inputUser" >
//                        <label>Mobile:</label>
//                       <input
//                        className="form-control"
//                         type="number"
//                         value={editedData.phone}
//                         onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
//                          />
//                     </div>
//                   </div>
                   

//                   <div className="bellingAddressAndDeleveryAddress">
//                       <div className="deleveryAddress">
//                           <div className="or addressAddTitle mb-3 mt-4">
//                               <span>Delivery Address</span>
//                               {/* <button onClick={() => handleAddAddress("Delivery")}><BsFillPlusCircleFill /></button> */}
//                           </div>
//                           <div className="selectUser">
//                             <Select
//                                 options={[
//                                   {
//                                   label: (
//                                     <button 
//                                       style={{ all: "unset", cursor: "pointer", color: "rgb(246,140,30)", fontWeight: "bold" }}
//                                       onClick={() => handleAddAddress("Delivery")}
//                                     >
//                                       <BsFillPlusCircleFill style={{ marginRight: "5px" }} />
//                                       Add New Delivery Address
//                                     </button>
//                                   ),
//                                   value: "addNew"
//                                 },
                                 

//                                   ...deliveryAddresses.map((addr, index) => ({
//                                     label: `${addr.street_no}, ${addr.thana}, ${addr.dist},${addr.country}`,
//                                     value: index,
//                                   })),
//                                 ]}
//                                 onChange={(option) =>
//                                   setSelectedDeliveryAddress(deliveryAddresses[option.value])
//                                 }
//                                 placeholder="Select a delivery address"
//                               />
//                           </div>
//                        </div>
//                         <div className="bellingAddress">
//                           <div className="or addressAddTitle mb-3 mt-4">
//                               <span>Billing Address</span>
//                               {/* <button onClick={() => handleAddAddress("Billing")}><BsFillPlusCircleFill /></button> */}
//                           </div>
//                           <div className="selectUser">
           
//                                 <Select
//                                     options={[
//                                       {
//                                         label: (
//                                           <button
//                                             style={{
//                                               all: "unset",
//                                               cursor: "pointer",
//                                               color: "rgb(246,140,30)",
//                                               fontWeight: "bold"
//                                             }}
//                                             onClick={() => handleAddAddress("Billing")}
//                                           >
//                                             <BsFillPlusCircleFill style={{ marginRight: "5px" }} />
//                                             Add New Billing Address
//                                           </button>
//                                         ),
//                                         value: "addNew"
//                                       },
//                                       {
//                                         label: (
//                                           <p
//                                             style={{
//                                               cursor: "pointer",
//                                               color: "rgb(246,140,30)",
//                                               fontWeight: "bold"
//                                             }}
//                                           >
//                                             Same as Delivery Address
//                                           </p>
//                                         ),
//                                         value: "same"
//                                       },
                                   
//                                       ...billingAddresses.map((addr, index) => ({
//                                         label: `${addr.street_no}, ${addr.thana}, ${addr.dist}`,
//                                         value: index
//                                       }))
//                                     ]}
//                                     onChange={(option) => setSelectedBillingAddress(option.value === "same" ? "same" : billingAddresses[option.value])
//                                       }
//                                     placeholder="Select a billing address"
//                                   />

//                           </div>
                         
//                         </div>
                       
//                   </div>
//                   <div className="chekoutUserDetails mt-3">
//                     <div className="mb-2 inputUser remarkInstructions" >
//                        <label>Your Instructions:</label>
//                        <textarea
//                           className="form-control"
//                           value={remark}  // Set the value of the textarea to the `remark` state
//                           onChange={handleRemarkChange}  // Update the state when the textarea changes
//                         />
//                     </div>
//                   </div>
//                   <div className="payemntOptionOptions mt-3">
//                   <div className="radio-buttons">
//                     <label className="custom-radio">
//                       <input
//                         type="radio"
//                         name="radio"
//                         value="2"
//                         checked={paymentType === 2}
//                         onChange={(e) => {
//                           setPaymentType(parseInt(e.target.value));
//                           setPartialPayment(false); // Reset partial payment
//                         }}
//                       />
//                       <span className="radio-btn">
//                         <i className="las la-check"><BsCheck2 /></i>
//                         <div className="hobbies-icon">
//                           <img src="../imgs/atm-card.png" style={{ width: '20px', height: '20px' }} />
//                           <h3>Online Payment</h3>
//                         </div>
//                       </span>
//                     </label>
//                     <label className="custom-radio">
//                       <input
//                         type="radio"
//                         name="radio"
//                         value="1"
//                         checked={paymentType === 1}
//                         onChange={(e) => setPaymentType(parseInt(e.target.value))}
//                       />
//                       <span className="radio-btn">
//                         <i className="las la-check"><BsCheck2 /></i>
//                         <div className="hobbies-icon">
//                           <img src="../imgs/delevery.png" />
//                           <h3>Cash On Delivery</h3>
//                         </div>
//                       </span>
//                     </label>
//                   </div>

//                 {paymentType === 2 && (
//                   <div className="partial-payment-options">
//                     <div className="partial-payment-driven">
//                       <label>
//                       <input
//                           type="checkbox"
//                           className="form-check-input"
//                           checked={!partialPayment}
//                           onChange={() => {
//                             setPartialPayment(false);
//                             setPartialAmount(0);
//                           }}
//                         />
//                       &nbsp;Full Payment
//                       </label>
//                       <label>
//                         <input
                       
//                           type="checkbox"
//                           className="form-check-input"
//                           checked={partialPayment}
//                           onChange={() => {
//                             setPartialPayment(true);
//                             setPartialAmount(totalWithVat * 0.6); // Default to 60%
//                           }}
//                         />
//                         &nbsp;Partial Payment
//                       </label>
//                     </div>


//       {partialPayment && (
//   <div className="partial-payment-percentage">
//     <label>
//    <span  style={{
//           color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
//         }}>TK</span> &nbsp;
//       <input
//         type="number"
//         style={{
//           color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
//         }}
//         value={partialAmount}
//         min="0"
//         max={totalWithVat.toFixed(2)}
//         onChange={(e) => {
//           const value = parseFloat(e.target.value) || 0; // Ensure the value is a number
//           if (value >= 0 && value <= totalWithVat) {
//             setPartialAmount(value); // Update state only for valid input
//           }
//         }}
//       />
      
      
//     </label>
//     <p  style={{
//           color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
//         }}>{` You must pay at least 60% (Tk ${totalWithVat * 0.6}) of the total amount Tk`}  {totalWithVat.toFixed(2)}</p>
//   </div>
// )}
//     </div>
//   )}
// </div>


                
                  
                 
//                         {newAddress && (
//                           <div className="newAddressModal">
//                              <div className="addtingNewAddressHeader">
//                                 <h6 className="addtingNewAddressTitle">PLease Add {newAddress.address_type} Address</h6>
//                                 <button  className="btn btn-primary" onClick={() => setNewAddress(null)}><BsXLg /></button>
//                              </div>
//                              <div className="newAddressModalContainer">
//                                <Container fluid>
//                                 <div className="mb-2 inputUser" style={{display:'none'}}>
//                                   <label>Type:</label>
//                                   <input className="form-control" type="text" value={newAddress.address_type}  />
//                                 </div>
//                                 <Row>
//                                   <Col md={6}>
//                                       <div className="mb-2 inputUser  w-100">
//                                         <label>Delivery To :</label>
//                                         <input  placeholder="Enter your product receiver name.." className="form-control" type="text" value={newAddress.deliver_to}
//                                         onChange={(e) => setNewAddress({ ...newAddress, deliver_to: e.target.value })}
//                                         />
//                                       </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser w-100">
//                                         <label>Street:</label>
//                                         <input
//                                         className="form-control"
//                                           type="text"
//                                           placeholder="Enter your street name.." 
//                                           value={newAddress.street_no}
//                                           onChange={(e) => setNewAddress({ ...newAddress, street_no: e.target.value })}
//                                         />
//                                       </div>
//                                   </Col>
//                                 </Row>
//                                 <Row>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser " >
//                                       <label>post office:</label>
//                                       <input
//                                       className="form-control"
//                                         type="text"
//                                          placeholder="Enter your post ofiice name.."
//                                         value={newAddress.post_office}
//                                         onChange={(e) => setNewAddress({ ...newAddress, post_office: e.target.value })}
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                         <label htmlFor="division">Division:</label>
//                                           <Select
//                                             id="division"
//                                             options={divisions}
//                                             value={divisions.find((division) => division.value === newAddress.division) || null}
                                          
//                                             onChange={(selectedOption) => {
//                                               setNewAddress({ ...newAddress, division: selectedOption ? selectedOption.value : "" });
//                                               setSelectedDivision(selectedOption || null);
//                                             }}
//                                             placeholder="Select a Division"
//                                             maxMenuHeight={120}
//                                           />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                       <label htmlFor="district">District</label>
                                    
//                                       <Select
//                                           id="district"
//                                           options={districts} // districts should be formatted as { value, label } objects
//                                           value={districts.find((district) => district.value === newAddress.dist) || null}
//                                           onChange={(selectedOption) => {
//                                             setNewAddress({ ...newAddress, dist: selectedOption ? selectedOption.value : "" });
//                                             setSelectedDistrict(selectedOption || null);
//                                           }}
//                                           placeholder="Select a District"
//                                           isDisabled={!selectedDivision} // Disables the dropdown if no division is selected
//                                           maxMenuHeight={200}
//                                         />
                                        
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                       <label htmlFor="upazila">Upazila (Thana)</label>
//                                       <Select
//                                             id="upazila"
//                                             options={upazilas} // districts should be formatted as { value, label } objects
//                                             value={upazilas.find((upazila) => upazila.value === newAddress.thana) || null}
//                                             onChange={(selectedOption) => {
//                                               setNewAddress({ ...newAddress, thana: selectedOption ? selectedOption.value : "" });
//                                               setSelectedUpazila(selectedOption || null);
//                                             }}
//                                             placeholder="Select a upozila"
//                                             isDisabled={!selectedDistrict} // Disables the dropdown if no division is selected
//                                             maxMenuHeight={200}
//                                           />


                                         
//                                         </div>
//                                   </Col>
//                                 </Row>
                               
//                                   <Row>
//                                     <Col md={6}>
//                                         <div className="mb-2 inputUser" >
//                                           <label>State:</label>
//                                           <input
//                                           className="form-control"
//                                             placeholder="Enter your state name.." 
//                                             type="text"
//                                             value={newAddress.state}
//                                             onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
//                                           />
//                                         </div>
//                                       </Col>
//                                       <Col md={6}>
//                                         <div className="mb-2 inputUser" >
//                                           <label>Country:</label>
//                                           <input
//                                           className="form-control"
//                                             type="text"
//                                             placeholder="Enter your country name.." 
//                                             value={newAddress.country}
//                                             onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}
//                                           />
//                                         </div>
//                                       </Col>
//                                   </Row>
//                                 </Container>
//                               </div>
//                               <div className="addressbarFooter  d-flex align-items-center ">
//                                   <button className="btn btn-primary" onClick={() => handleSaveAddress(null)}>Add</button>
//                                    <button  className="btn btn-primary d-flex align-items-center gap-1" onClick={() => setsaveNewAddress(null)}> <Form.Check/>Save New Address</button>
//                                   <button  className="btn btn-primary" onClick={() => setNewAddress(null)}>Cancel</button>
                                 
//                               </div>
                            
//                             </div>
//                         )}
                    
//                   {/* <button className="placeorderbutton" onClick={handlePlaceOrder}>Place Order</button> */}
//                   <button
//   className="placeorderbutton"
//   onClick={() => {
//     if (paymentType === 2 && partialPayment && partialAmount < totalWithVat * 0.6) {
//       setPartialAmount(totalWithVat * 0.6);
//       alert(`You must pay at least 60% (Tk  ${totalWithVat * 0.6}) of the total amount!`);
//       return;
//     }
//     handlePlaceOrder(); // Call the actual place order function
//   }}
//   disabled={loading}
// >
// Place Order
// </button>
// {
//   loading && <LoadingSpinner/> 
// }
//               </div>
//             </Col>
//             <div className="col-md-4">
//               <div className="order-summary">
//                 <h5 className="ordersummeryHeaderTitle">Cart Summary</h5>
                
//                 {cartItems.length > 2 ? (
//                       <Accordion className="mt-3">
//                         <Accordion.Item eventKey="0">
//                           <Accordion.Header>Your Order Product</Accordion.Header>
//                           <Accordion.Body>
//                             <ul className="oderSummeryList mt-0">
//                               {cartItems.map((item) => (
//                                 <li key={item.id}>
//                                   <p>
//                                     <img src={item.image} alt={item.name} />
//                                     <span>{item.orderQuantity}</span>
//                                   </p>
//                                   <p>{item.name}</p>
//                                   <p><span style={{color:'rgba(55,0,0,0.4)'}}>({item.orderQuantity} X {item.price.toFixed(2)})</span> Tk {item.price.toFixed(2) * item.orderQuantity} </p>
//                                 </li>
//                               ))}
//                             </ul>
//                           </Accordion.Body>
//                         </Accordion.Item>
//                       </Accordion>
//                     ) : (
//                       <ul className="oderSummeryList mt-0">
//                         {cartItems.map((item) => (
//                           <li key={item.id}>
//                             <p>
//                               <img src={item.image} alt={item.name} />
//                               <span>{item.orderQuantity}</span>
//                             </p>
//                             <p>{item.name}</p>
//                             <p><span style={{color:'rgba(55,0,0,0.4)'}}>(1 X {item.price.toFixed(2)})</span> Tk {item.price.toFixed(2) * item.orderQuantity} </p>
//                           </li>
//                         ))}
//                       </ul>
//                     )}
                
//                 <ul className="oderTotalPRicethings">
//   <li>
//     <p>Subtotal</p>
//     <p>Tk {totalPrice.toFixed(2)}</p>
//   </li>
//   <li>
//     <p>VAT</p>
//     <p>Tk {vat.toFixed(2)}</p>
//   </li>
//   <li>
//     <p>Total with VAT</p>
//     <p>Tk {totalWithVat.toFixed(2)}</p>
//   </li>
//   <li>
//     <p>Shipping Charge</p>
//     <p>Tk {deleverCharge}</p>
//   </li>
//   {paymentType === 2 && partialPayment && (
//     <>
//       {/* <li>
//         <p>Partial Payment</p>
//         <p>Tk {partialAmount.toFixed(2)}</p>
//       </li> */}
//       {/* <li>
//         <p>Due Payment</p>
//         <p>Tk {(totalWithVat - partialAmount).toFixed(2)}</p>
//       </li> */}
//     </>
//   )}
// </ul>
// <p className="grandTotal">
//   <span>Total</span> <span>Tk {totalWithVat.toFixed(2)}</span>
// </p>
// {paymentType === 2 && partialPayment? (<>
//   <p className="grandTotal" style={{background:"#e5e5e5",color:"#000000"}}>
//   <span>Total Partial Pay</span> <span>Tk {partialAmount.toFixed(2)}</span>
// </p>
// </>) :(
//   <>
  
//   </>
// )
   
// }


                
//                   {/* <p className="grandTotal">
//                     <span>Total</span> <span>Tk {totalWithVat.toFixed(2)}</span>
//                   </p> */}
//               </div>
//               <div className="weeAcceptCard mt-3">
//                 <h6 className="mb-2">We Accept Card: </h6>
//               <ul>
//                 <li>
//                   <a href="https://www.visa.com" target="_blank">
//                     <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" style={{height:'15px'}} />
//                   </a>
//                 </li>
//                 <li>
//                   <a href="https://www.mastercard.com" target="_blank">
//                     <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg" alt="MasterCard"  />
//                   </a>
//                 </li>
//                 <li>
//                   <a href="https://www.paypal.com" target="_blank">
//                     <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="PayPal"  />
//                   </a>
//                 </li>
//                 <li>
//     <a href="https://www.americanexpress.com" target="_blank">
//       <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg" alt="American Express" />
//     </a>
//   </li>
//              </ul>

//               </div>
//           </div>

          
//           </Row>  
//         </Container> 
//       </div>
//     </>

//   );
// };

// export default Checkout





import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
import axios from "axios";
import Select from "react-select";
import useProductActions from "../utils/productThanos";
import Navigation from "../component/navigation";
import endpoints from "../api/endpoints";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import "../css/checkout.css";
import { BsCheck2, BsFillPlusCircleFill, BsXLg } from "react-icons/bs";
import { Navigate, useNavigate } from "react-router-dom";
import {useForm} from "react-hook-form";
import { fetchOrderHistory } from "../redux/orderHistorySlice";
import LoadingSpinner from "../component/loadingSpinner";
import Footer from "../component/footer";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [divisions, setDivisions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);
  const [paymentType, setPaymentType] = useState(1);
 const [saveNewAddress,setsaveNewAddress]=useState(null)
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedUpazila, setSelectedUpazila] = useState(null);
  const { accessToken,user } = useSelector((state) => state.auth);
  const { customer, status, error } = useSelector((state) => state.customerDetails);
  const [loading, setLoading] = useState(false)
  const { cartItems,totalPrice ,handleClearCart,vat_percentage,totalWithVat,vat,deleverCharge} = useProductActions();
// Define state hooks at the top of your component
const [partialPayment, setPartialPayment] = useState(false); // Boolean for enabling partial payment
const [partialAmount, setPartialAmount] = useState(0);;

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [newAddress, setNewAddress] = useState(null);
  const [editMode, setEditMode] = useState({});
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedBilling, setSelectedBilling] = useState(null);
  const [editedData, setEditedData] = useState({
    name: "",
    email: "",
    phone:"",
    address: [],
  });
  const [remark, setRemark] = useState('');  // Track textarea value

  const handleRemarkChange = (e) => {
    setRemark(e.target.value); // Update state on textarea input
  };


  useEffect(() => {
    if (customer) {
      setEditedData((prevData) => ({
        name: prevData.name || customer?.customer_details?.name || "",
        email: prevData.email || customer?.customer_details?.email || "",
        phone: prevData.phone || customer?.user_id || "",
        address: customer?.customer_address || [],
      }));
    }
  }, [customer]);


  const handleChange = (e, index = null, addressField = null) => {
    const { name, value } = e.target;
    if (addressField !== null) {
      const updatedAddresses = editedData.address.map((addr, idx) =>
        idx === index ? { ...addr, [addressField]: value } : addr
      );
      setEditedData({ ...editedData, address: updatedAddresses });
    } else {
      setEditedData({ ...editedData, [name]: value });
    }
  };



  const handleSetDefaultAddress = async (index) => {
    try {
      const address = editedData.address[index];
      await axios.put(
        endpoints.defaultAddress,
        {
          customer_address_id: address.customer_address_id,
          make_default: 1
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      alert("Default address set successfully!");
      dispatch(fetchCustomerDetails(accessToken));
      
      // Update UI immediately
      const updatedAddresses = editedData.address.map(addr => ({
        ...addr,
        is_default: addr.customer_address_id === address.customer_address_id ? 1 : 0
      }));
      setEditedData(prev => ({ ...prev, address: updatedAddresses }));
    } catch (error) {
      console.error("Error setting default address:", error);
      alert("Failed to set default address.");
    }
  };
  useEffect(() => {
    if (customer?.customer_address) {
      const deliveryIndex = customer.customer_address.findIndex(
        addr => addr.address_type === "Delivery" && addr.is_default === 1
      );
      if (deliveryIndex !== -1) setSelectedDelivery(deliveryIndex);
      
      const billingIndex = customer.customer_address.findIndex(
        addr => addr.address_type === "Billing" && addr.is_default === 1
      );
      if (billingIndex !== -1) setSelectedBilling(billingIndex);
    }
  }, [customer]);
  const handleEdit = (index) => {
    const isEditMode = !editMode[index];
    setEditMode((prev) => ({ ...prev, [index]: isEditMode }));


  };

  const handleAddAddress = (type) => {

    setNewAddress({
      address_type_id: type === "Delivery" ? 1 : 2,
      address_type: type,
      deliver_to:"",
      street_no: "",
      zip_code: "",
      division:divisions[0],
      thana: upazilas[0],
      dist: districts[0],
      state: "",
      country: "",
    });

  };


  const handleSaveAddress = async (index) => {
    try {
      const address = index === null ? newAddress : editedData.address[index];
      const isNew = index === null;

      const config = { headers: { Authorization: `Bearer ${accessToken}` } };
      const endpoint = isNew
        ? endpoints.addAddress
        : `${endpoints.updateAddress}/${address.customer_address_id}`;

      await axios[isNew ? "post" : "put"](endpoint, address, config);

      alert("Address saved successfully!");
      setNewAddress(null);
      setEditMode({});
      dispatch(fetchCustomerDetails(accessToken));
    } catch (error) {
      alert("Failed to save address.");
    }
  };


  //####################################
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();
  //####################################

  
  const handlePlaceOrder = async () => {
    try {
      if (
        !editedData.name.trim() ||
        !editedData.email.trim() ||
        !editedData.phone.trim() ||
        !paymentType ||
        !selectedBilling ||
        !editedData.address[selectedDelivery]?.customer_address_id  ||
        cartItems.length === 0
      ) {
        alert("Please fill all the required fields before placing the order.");
        return;
      }
  
      if (paymentType === 2 && partialPayment && partialAmount < totalWithVat * 0.6) {
        alert("You must pay at least 60% of the total amount for partial payment.");
        return;
      }
  
      const partialPaymentAmount = partialPayment
        ? partialAmount.toFixed(2) // Use the dynamically adjusted partial amount
        : null;
  
      const duePayment = partialPayment
        ? (totalWithVat - partialAmount).toFixed(2)
        : null;
  
      const payload = {
        customer_id: customer?.id,
        customer_name: editedData.name,
        email: editedData.email,
        mobile_number: editedData.phone,
        order_date: new Date().toISOString().split("T")[0],
        order_status: 1,
        order_amount: totalPrice.toFixed(2),
        discount_percentage: "0",
        total_discount: "0",
        vat_percentage: vat_percentage,
        total_vat: vat.toFixed(2),
        grand_total: totalWithVat.toFixed(2),
        partial_payment: partialPaymentAmount,
        due_payment: duePayment,
        total_payment:partialPaymentAmount===null ? totalWithVat.toFixed(2) :  partialPaymentAmount,
        delivery_charge: deleverCharge,
        payment_type_id: paymentType,
        bill_address_id:
    selectedBilling === "same"
      ? editedData.address[selectedDelivery]?.customer_address_id // Access via index
      : editedData.address[selectedBilling]?.customer_address_id, // Access via index
  delivery_address_id: editedData.address[selectedDelivery]?.customer_address_id,
        remarks: remark,
        order_lines: cartItems.map((item) => ({
          product_id: item.id,
          product_code: item.id,
          quantity: item.orderQuantity,
          unit_price: item.price.toFixed(2),
          discount_percentage: item.discount > 0 ? item.discount : 0.0,
          is_offer_product: item.discount > 0 ? 1 : 0,
          is_gift_product: "false",
        })),
        currency: "BDT",
      };
      console.log(payload)
      setLoading(true);
      if (paymentType === 1) {
        // Cash on delivery
        await axios.post(endpoints.confirmOrder, payload, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        dispatch(fetchOrderHistory(accessToken));
        navigate("/orderSuccess");
        handleClearCart();
      } 
      else if (paymentType === 2) {
        // Online payment
        // console.log(payload)
        await axios.post(endpoints.confirmOrderOnline, payload, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function(resp){

                        if(resp.data.original){
                          window.location.replace(resp.data.original);
                        }
                       
                        
                     }).catch(function(err){
                        console.log('api data error')
                   }).then(function(resp){
                        console.log(resp);
                        
                   });

                 
      }
    } 
    catch (error) {
      console.log("Failed to place order");
    }finally {
      setLoading(false); 
      
    }
   
  };
  
  const deliveryOptions = editedData.address
  ?.map((addr, originalIndex) => ({ addr, originalIndex }))
  .filter(({ addr }) => addr.address_type === "Delivery")
  .map(({ addr, originalIndex }) => ({
    value: originalIndex,
    label: `${addr.is_default ? "[Default Address] " : ""}Delivery To ${addr.deliver_to} -  ${addr.street_no}, ${addr.zip_code}`,
  }));

const billingOptions = editedData.address
  ?.map((addr, originalIndex) => ({ addr, originalIndex }))
  .filter(({ addr }) => addr.address_type === "Billing")
  .map(({ addr, originalIndex }) => ({
    value: originalIndex,
    label: `${addr.is_default ? "[Default Address] " : ""}Billing To ${addr.deliver_to} -  ${addr.street_no}, ${addr.zip_code} `,
  }));
 
  const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
    if (type !== 2) {
      // Reset partial payment when not online
      setPartialPayment(false);
      setPartialAmount(0);
    }
  };
  
  const handlePartialAmountChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    const minimumPartialAmount = totalWithVat * 0.6; // 60% of total
    setPartialAmount(Math.max(minimumPartialAmount, value));
  };

  return (
    <>
   <Navigation />
     <div className="checkoutPageSection">
        <Container>
         <Row>
          <Col md={8} className="">
             <div className="boxCenter mw-100">
               <h2 className="checkoutTitle">Checkout</h2>
                  <div className="chekoutUserDetails mt-3">
                    <Row>
                        <Col md={6}>
                           <div className="mb-2 inputUser">
                              <label>Name:</label>
                              <input
                                className="form-control"
                                type="text"
                                value={editedData.name}
                                onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
                                placeholder="Enter your name...."
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-2  inputUser">
                              <label>Email:</label>
                              <input
                                className="form-control"
                                type="email"
                                value={editedData.email}
                                onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
                                placeholder="Enter your email...."
                                    />
                            </div>
                        </Col>
                    </Row>
                    

                   
                    
                    <div className="mb-2 inputUser" >
                       <label>Mobile:</label>
                      <input
                       className="form-control"
                        type="number"
                        value={editedData.phone}
                        onChange={(e) => setEditedData({ ...editedData, phone: e.target.value })}
                         />
                    </div>
                  </div>
                   

                  <div className="bellingAddressAndDeleveryAddress">
                      <div className="deleveryAddress">
                          <div className="or addressAddTitle mb-3 mt-4">
                              <span>Delivery Address</span>
                              {/* <button onClick={() => handleAddAddress("Delivery")}><BsFillPlusCircleFill /></button> */}
                          </div>
                          <div className="selectUser">
                                  <Select
                                    options={[
                                      {
                                        label: (
                                          <button
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleAddAddress("Delivery")
                                            }
                                          >
                                            <BsFillPlusCircleFill
                                              style={{ marginRight: "5px" }}
                                            />
                                            Add New Delivery Address
                                          </button>
                                        ),
                                        value: "addNew", // Ensure this value is unique
                                        isDisabled: true, // Prevent this option from being selectable
                                      },

                                      ...(deliveryOptions || []), // Spread your existing delivery options here
                                    ]}
                                    value={deliveryOptions?.find(
                                      (opt) => opt.value === selectedDelivery
                                    )}
                                    onChange={(e) =>
                                      setSelectedDelivery(e.value)
                                    }
                                    placeholder="Select a delevery address"
                                  />
                                </div>

                                <div className="selectUser">
                                  {selectedDelivery !== null && (
                                    <>
                                      <Form>
                                        <Form.Group controlId="formDeliveryTo">
                                          <Form.Label>Delivery To:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.deliver_to || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "deliver_to"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group controlId="formStreet">
                                          <Form.Label>Street:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.street_no || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "street_no"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                       
                                   
                                        <Form.Group controlId="formState">
                                          <Form.Label>State:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.state || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "state"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group controlId="formCountry">
                                          <Form.Label>Country:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.country || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "country"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group controlId="formZipCode">
                                          <Form.Label>Zip Code:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.zip_code || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "zip_code"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                      </Form>

                                      <div className="action-buttons">
                                        <button
                                          onClick={() =>
                                            editMode[selectedDelivery]
                                              ? handleSaveAddress(
                                                  selectedDelivery
                                                )
                                              : handleEdit(selectedDelivery)
                                          }
                                        >
                                          {editMode[selectedDelivery]
                                            ? "Save"
                                            : "Edit"}
                                        </button>
                                        <button
                                          className={`ml-2 ${editedData.address[selectedDelivery]?.is_default ? "btn-success" : "btn-warning"}`}
                                          onClick={() => handleSetDefaultAddress(selectedDelivery)}
                                        >
                                          {editedData.address[selectedDelivery]?.is_default ? "Default Address" : "Make Default"}
                                        </button>
                                      
                                      </div>
                                    </>
                                  )}
                                </div>
                       </div>
                        <div className="bellingAddress">
                          <div className="or addressAddTitle mb-3 mt-4">
                              <span>Billing Address</span>
                              {/* <button onClick={() => handleAddAddress("Billing")}><BsFillPlusCircleFill /></button> */}
                          </div>
                          {/* <div className="selectUser">
           
                                <Select
                                    options={[
                                      {
                                        label: (
                                          <button
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold"
                                            }}
                                            onClick={() => handleAddAddress("Billing")}
                                          >
                                            <BsFillPlusCircleFill style={{ marginRight: "5px" }} />
                                            Add New Billing Address
                                          </button>
                                        ),
                                        value: "addNew"
                                      },
                                      {
                                        label: (
                                          <p
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold"
                                            }}
                                          >
                                            Same as Delivery Address
                                          </p>
                                        ),
                                        value: "same"
                                      },
                                   
                                      ...billingAddresses.map((addr, index) => ({
                                        label: `${addr.street_no}, ${addr.thana}, ${addr.dist}`,
                                        value: index
                                      }))
                                    ]}
                                    onChange={(option) => setSelectedBillingAddress(option.value === "same" ? "same" : billingAddresses[option.value])
                                      }
                                    placeholder="Select a billing address"
                                  />

                          </div> */}
                         <div className="selectUser">
                                  <Select
                                    options={[
                                      {
                                        label: (
                                          <button
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleAddAddress("Billing")
                                            }
                                          >
                                            <BsFillPlusCircleFill
                                              style={{ marginRight: "5px" }}
                                            />
                                            Add New Billing Address
                                          </button>
                                        ),
                                        value: "addNew",
                                        isDisabled: true,
                                      },
                                      {
                                        label: (
                                          <p
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Same as Delevery Address
                                          </p>
                                        ),
                                        value: "same", // Value for "Same as Billing Address"
                                      },
                                      ...(billingOptions || []),
                                    ]}
                                    value={billingOptions?.find(
                                      (opt) => opt.value === selectedBilling
                                    )}
                                    onChange={(e) =>
                                      setSelectedBilling(
                                        e.value === "same" ? "same" : e.value
                                      )
                                    }
                                    placeholder="Select a billing address"
                                  />
                                </div>
                                <br></br>
                                <div className="selectUser">
                                  {selectedBilling !== "same" &&
                                    selectedBilling !== null && (
                                      <>
                                        <Form>
                                          <Form.Group controlId="formBillingTo">
                                            <Form.Label>Billing To:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.deliver_to || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "deliver_to"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formStreet">
                                            <Form.Label>Street:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.street_no || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "street_no"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formState">
                                            <Form.Label>State:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.state || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "state"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        

                                         
                                          

                                          <Form.Group controlId="formCountry">
                                            <Form.Label>Country:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.country || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "country"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formPostOffice">
                                            <Form.Label>
                                              Zip Code:
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.zip_code || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "zip_code"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Form>

                                        <div className="action-buttons">
                                        
                                          <button
                                            onClick={() =>
                                              editMode[selectedBilling]
                                              ? handleSaveAddress(
                                                selectedBilling
                                                )
                                              : handleEdit(selectedBilling)
                                            }
                                          >
                                            {editMode[selectedBilling]
                                              ? "Save"
                                              : "Edit"}
                                          </button>
                                          <button
                                          className={`ml-2 ${editedData.address[selectedBilling]?.is_default ? "btn-success" : "btn-warning"}`}
                                          onClick={() => handleSetDefaultAddress(selectedBilling)}
                                        >
                                          {editedData.address[selectedBilling]?.is_default ? "Default Address" : "Make Default"}
                                        </button>
                                         
                                   
                                        </div>
                                      </>
                                    )}
                                </div>
                        </div>
                       
                  </div>
                  <div className="chekoutUserDetails mt-3">
                    <div className="mb-2 inputUser remarkInstructions" >
                       <label>Your Instructions:</label>
                       <textarea
                          className="form-control"
                          value={remark}  // Set the value of the textarea to the `remark` state
                          onChange={handleRemarkChange}  // Update the state when the textarea changes
                        />
                    </div>
                  </div>
                  <div className="payemntOptionOptions mt-3">
                  <div className="radio-buttons">
                    <label className="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value="2"
                        checked={paymentType === 2}
                        onChange={(e) => {
                          setPaymentType(parseInt(e.target.value));
                          setPartialPayment(false); // Reset partial payment
                        }}
                      />
                      <span className="radio-btn">
                        <i className="las la-check"><BsCheck2 /></i>
                        <div className="hobbies-icon">
                          <img src="../imgs/atm-card.png" style={{ width: '20px', height: '20px' }} />
                          <h3>Online Payment</h3>
                        </div>
                      </span>
                    </label>
                    <label className="custom-radio">
                      <input
                        type="radio"
                        name="radio"
                        value="1"
                        checked={paymentType === 1}
                        onChange={(e) => setPaymentType(parseInt(e.target.value))}
                      />
                      <span className="radio-btn">
                        <i className="las la-check"><BsCheck2 /></i>
                        <div className="hobbies-icon">
                          <img src="../imgs/delevery.png" />
                          <h3>Cash On Delivery</h3>
                        </div>
                      </span>
                    </label>
                  </div>

                {paymentType === 2 && (
                  <div className="partial-payment-options">
                    <div className="partial-payment-driven">
                      <label>
                      <input
                          type="checkbox"
                          className="form-check-input"
                          checked={!partialPayment}
                          onChange={() => {
                            setPartialPayment(false);
                            setPartialAmount(0);
                          }}
                        />
                      &nbsp;Full Payment
                      </label>
                      <label>
                        <input
                       
                          type="checkbox"
                          className="form-check-input"
                          checked={partialPayment}
                          onChange={() => {
                            setPartialPayment(true);
                            setPartialAmount(totalWithVat * 0.6); // Default to 60%
                          }}
                        />
                        &nbsp;Partial Payment
                      </label>
                    </div>


      {partialPayment && (
  <div className="partial-payment-percentage">
    <label>
   <span  style={{
          color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
        }}>TK</span> &nbsp;
      <input
        type="number"
        style={{
          color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
        }}
        value={partialAmount}
        min="0"
        max={totalWithVat.toFixed(2)}
        onChange={(e) => {
          const value = parseFloat(e.target.value) || 0; // Ensure the value is a number
          if (value >= 0 && value <= totalWithVat) {
            setPartialAmount(value); // Update state only for valid input
          }
        }}
      />
      
      
    </label>
    <p  style={{
          color: partialAmount < totalWithVat * 0.6 ? "rgba(203, 28, 30, 1)" : "rgb(106, 177, 112)", // Conditionally set text color
        }}>{` You must pay at least 60% (Tk ${totalWithVat * 0.6}) of the total amount Tk`}  {totalWithVat.toFixed(2)}</p>
  </div>
)}
    </div>
  )}
</div>


                
                  
                 
                        {newAddress && (
                          <div className="newAddressModal">
                             <div className="addtingNewAddressHeader">
                                <h6 className="addtingNewAddressTitle">PLease Add {newAddress.address_type} Address</h6>
                                <button  className="btn btn-primary" onClick={() => setNewAddress(null)}><BsXLg /></button>
                             </div>
                             <div className="newAddressModalContainer">
                               <Container fluid>
                                <div className="mb-2 inputUser" style={{display:'none'}}>
                                  <label>Type:</label>
                                  <input className="form-control" type="text" value={newAddress.address_type}  />
                                </div>
                                <Row>
                                  <Col md={6}>
                                      <div className="mb-2 inputUser  w-100">
                                        <label>Delivery To :</label>
                                        <input  placeholder="Enter your product receiver name.." className="form-control" type="text" value={newAddress.deliver_to}
                                        onChange={(e) => setNewAddress({ ...newAddress, deliver_to: e.target.value })}
                                        />
                                      </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-2 inputUser w-100">
                                        <label>Street:</label>
                                        <input
                                        className="form-control"
                                          type="text"
                                          placeholder="Enter your street name.." 
                                          value={newAddress.street_no}
                                          onChange={(e) => setNewAddress({ ...newAddress, street_no: e.target.value })}
                                        />
                                      </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <div className="mb-2 inputUser " >
                                      <label>Zip Code </label>
                                      <input
                                      className="form-control"
                                        type="text"
                                         placeholder="Enter your post ofiice name.."
                                        value={newAddress.zip_code}
                                        onChange={(e) => setNewAddress({ ...newAddress, zip_code: e.target.value })}
                                      />
                                    </div>
                                  </Col>
                                  
                                </Row>
                               
                                  <Row>
                                    <Col md={6}>
                                        <div className="mb-2 inputUser" >
                                          <label>State:</label>
                                          <input
                                          className="form-control"
                                            placeholder="Enter your state name.." 
                                            type="text"
                                            value={newAddress.state}
                                            onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={6}>
                                        <div className="mb-2 inputUser" >
                                          <label>Country:</label>
                                          <input
                                          className="form-control"
                                            type="text"
                                            placeholder="Enter your country name.." 
                                            value={newAddress.country}
                                            onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}
                                          />
                                        </div>
                                      </Col>
                                  </Row>
                                </Container>
                              </div>
                              <div className="addressbarFooter  d-flex align-items-center ">
                                  <button className="btn btn-primary" onClick={() => handleSaveAddress(null)}>Add</button>
                                   <button  className="btn btn-primary d-flex align-items-center gap-1" onClick={() => setsaveNewAddress(null)}> <Form.Check/>Save New Address</button>
                                  <button  className="btn btn-primary" onClick={() => setNewAddress(null)}>Cancel</button>
                                 
                              </div>
                            
                            </div>
                        )}
                    
                  {/* <button className="placeorderbutton" onClick={handlePlaceOrder}>Place Order</button> */}
                  <button
  className="placeorderbutton"
  onClick={() => {
    if (paymentType === 2 && partialPayment && partialAmount < totalWithVat * 0.6) {
      setPartialAmount(totalWithVat * 0.6);
      alert(`You must pay at least 60% (Tk  ${totalWithVat * 0.6}) of the total amount!`);
      return;
    }
    handlePlaceOrder(); // Call the actual place order function
  }}
  disabled={loading}
>
Place Order
</button>
{
  loading && <LoadingSpinner/> 
}
              </div>
            </Col>
            <div className="col-md-4">
              <div className="order-summary">
                <h5 className="ordersummeryHeaderTitle">Cart Summary</h5>
                
                {cartItems.length > 2 ? (
                      <Accordion className="mt-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Your Order Product</Accordion.Header>
                          <Accordion.Body>
                            <ul className="oderSummeryList mt-0">
                              {cartItems.map((item) => (
                                <li key={item.id}>
                                  <p>
                                    <img src={item.image} alt={item.name} />
                                    <span>{item.orderQuantity}</span>
                                  </p>
                                  <p>{item.name}</p>
                                  <p><span style={{color:'rgba(55,0,0,0.4)'}}>({item.orderQuantity} X {item.price.toFixed(2)})</span> Tk {item.price.toFixed(2) * item.orderQuantity} </p>
                                </li>
                              ))}
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ) : (
                      <ul className="oderSummeryList mt-0">
                        {cartItems.map((item) => (
                          <li key={item.id}>
                            <p>
                              <img src={item.image} alt={item.name} />
                              <span>{item.orderQuantity}</span>
                            </p>
                            <p>{item.name}</p>
                            <p><span style={{color:'rgba(55,0,0,0.4)'}}>(1 X {item.price.toFixed(2)})</span> Tk {item.price.toFixed(2) * item.orderQuantity} </p>
                          </li>
                        ))}
                      </ul>
                    )}
                
                <ul className="oderTotalPRicethings">
  <li>
    <p>Subtotal</p>
    <p>Tk {totalPrice.toFixed(2)}</p>
  </li>
  <li>
    <p>VAT</p>
    <p>Tk {vat.toFixed(2)}</p>
  </li>
  <li>
    <p>Total with VAT</p>
    <p>Tk {totalWithVat.toFixed(2)}</p>
  </li>
  <li>
    <p>Shipping Charge</p>
    <p>Tk {deleverCharge}</p>
  </li>
  {paymentType === 2 && partialPayment && (
    <>
      {/* <li>
        <p>Partial Payment</p>
        <p>Tk {partialAmount.toFixed(2)}</p>
      </li> */}
      {/* <li>
        <p>Due Payment</p>
        <p>Tk {(totalWithVat - partialAmount).toFixed(2)}</p>
      </li> */}
    </>
  )}
</ul>
<p className="grandTotal">
  <span>Total</span> <span>Tk {totalWithVat.toFixed(2)}</span>
</p>
{paymentType === 2 && partialPayment? (<>
  <p className="grandTotal" style={{background:"#e5e5e5",color:"#000000"}}>
  <span>Total Partial Pay</span> <span>Tk {partialAmount.toFixed(2)}</span>
</p>
</>) :(
  <>
  
  </>
)
   
}


                
                  {/* <p className="grandTotal">
                    <span>Total</span> <span>Tk {totalWithVat.toFixed(2)}</span>
                  </p> */}
              </div>
              <div className="weeAcceptCard mt-3">
                <h6 className="mb-2">We Accept Card: </h6>
              <ul>
                <li>
                  <a href="https://www.visa.com" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" style={{height:'15px'}} />
                  </a>
                </li>
                <li>
                  <a href="https://www.mastercard.com" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg" alt="MasterCard"  />
                  </a>
                </li>
                <li>
                  <a href="https://www.paypal.com" target="_blank">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg" alt="PayPal"  />
                  </a>
                </li>
                <li>
    <a href="https://www.americanexpress.com" target="_blank">
      <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg" alt="American Express" />
    </a>
  </li>
             </ul>

              </div>
          </div>

          
          </Row>  
        </Container> 
      </div>
      <br></br> <br></br> 
      <Footer />
    </>

  );
};

export default Checkout





