// utils/priceCalculation.js
export const calculateDiscountAndFinalPrice = (price, discount) => {

    const discountPercentage = discount ? parseFloat(discount.replace('%', '')) : 0;
    const discountAmount = (price * discountPercentage) / 100;
    const finalPrice = price - discountAmount;
    
    return {
      discountAmount,
      finalPrice,
    };
  };
  