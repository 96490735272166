import React from 'react';
import CartSidebar from './cart';
import WishlistSidebar from './wishlist';

const CartWish = () => {
  return (
    <div className="cartwish">
      <CartSidebar />
      <WishlistSidebar />
    </div>
  );
};

export default CartWish;
