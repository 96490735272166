
// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
// import axios from "axios";
// import Navigation from "../component/navigation";
// import Select from "react-select";
// import LoadingSpinner from "../component/loadingSpinner";
// import "../css/userAccount.css";
// import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
// import {
//   BsBox,
//   BsCheck2Square,
//   BsClockHistory,
//   BsCreditCard,
//   BsFillPlusCircleFill,
//   BsHouseDoor,
//   BsPatchQuestion,
//   BsPencilSquare,
//   BsPerson,
//   BsTrash,
//   BsXLg,
// } from "react-icons/bs";
// import { Link, useLocation } from "react-router-dom";
// import OrderHistory from "../component/orderHistory";
// import endpoints from "../api/endpoints";
// import ReviewHistory from "../component/reviewHistory";

// const UserAccount = () => {
//   const { accessToken } = useSelector((state) => state.auth);
//   const { customer, status, error } = useSelector(
//     (state) => state.customerDetails
//   );
//   console.log(customer);
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const [editMode, setEditMode] = useState({});
//   const [editedData, setEditedData] = useState({});
//   const [newAddress, setNewAddress] = useState(null);
//   const [selectedDelivery, setSelectedDelivery] = useState(null);
//   const [selectedBilling, setSelectedBilling] = useState(null);
//   const [saveNewAddress, setsaveNewAddress] = useState(null);

//   const [divisions, setDivisions] = useState([]);
//   const [districts, setDistricts] = useState([]);
//   const [upazilas, setUpazilas] = useState([]);
//   const [selectedDivision, setSelectedDivision] = useState(null);
//   const [selectedDistrict, setSelectedDistrict] = useState(null);
//   const [selectedUpazila, setSelectedUpazila] = useState(null);

//   const [editingAddressIndex, setEditingAddressIndex] = useState(null);


//   useEffect(() => {
//     if (
//       editingAddressIndex === null ||
//       !selectedDivision ||
//       !editedData.address[editingAddressIndex]
//     )
//       return;

//     // After districts are fetched for the division, set the district
//     const address = editedData.address[editingAddressIndex];
//     const district = districts.find((d) => d.value === address.dist);

//     if (district) {
//       setSelectedDistrict(district);
//     }
//   }, [districts, selectedDivision, editingAddressIndex]);

//   useEffect(() => {
//     if (
//       editingAddressIndex === null ||
//       !selectedDistrict ||
//       !editedData.address[editingAddressIndex]
//     )
//       return;

//     // After districts are set, initialize upazila
//     const address = editedData.address[editingAddressIndex];
//     const upazila = upazilas.find((u) => u.value === address.thana);

//     if (upazila) {
//       setSelectedUpazila(upazila);
//     }
//   }, [upazilas, selectedDistrict, editingAddressIndex]);

//   useEffect(() => {
//     const fetchDivisions = async () => {
//       try {
//         const response = await axios.get(
//           "https://bdapis.com/api/v1.2/divisions",
//           {
//             withCredentials: false, // Ensure credentials are not sent
//           }
//         );
//         const divisionOptions = response.data.data.map((division) => ({
//           value: division.division,
//           label: division.division,
//         }));
//         setDivisions(divisionOptions);
//       } catch (error) {
//         console.error("Error fetching divisions:", error);
//       }
//     };

//     fetchDivisions();
//   }, []);

//   // Fetch Districts when a Division is selected
//   useEffect(() => {
//     if (!selectedDivision) return;
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(
//           `https://bdapis.com/api/v1.2/division/${selectedDivision.value.toLowerCase()}`,
//           {
//             withCredentials: false, // Ensure credentials are not sent
//           }
//         );
//         const districtOptions = response.data.data.map((district) => ({
//           value: district.district,
//           label: district.district,
//           upazilas: district.upazilla,
//         }));
//         setDistricts(districtOptions);
//         setUpazilas([]); // Reset upazilas
//         setSelectedDistrict(null); // Reset selected district
//         setSelectedUpazila(null); // Reset selected upazila
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };

//     fetchDistricts();
//   }, [selectedDivision]);

//   // Update Upazilas when a District is selected
//   useEffect(() => {
//     if (!selectedDistrict) return;

//     const upazilaOptions = selectedDistrict.upazilas.map((upazila) => ({
//       value: upazila,
//       label: upazila,
//     }));
//     setUpazilas(upazilaOptions);
//     setSelectedUpazila(null); // Reset selected upazila
//   }, [selectedDistrict]);

//   useEffect(() => {
//     if (customer) {
//       setEditedData({
//         name: customer?.customer_details?.name || "",
//         email: customer?.customer_details?.email || "",
//         phone: customer?.user_id || "",
//         address: customer?.customer_address?.map((addr) => ({ ...addr })) || [],
//       });
//     }
//   }, [customer]);



  
//   const handleChange = (e, index = null, addressField = null) => {
//     const { name, value } = e.target;
//     if (addressField !== null) {
//       const updatedAddresses = editedData.address.map((addr, idx) =>
//         idx === index ? { ...addr, [addressField]: value } : addr
//       );
//       setEditedData({ ...editedData, address: updatedAddresses });
//     } else {
//       setEditedData({ ...editedData, [name]: value });
//     }
//   };

//   const handleSaveCustomer = async () => {
//     try {
//       await axios.put(endpoints.updateCustomerPersonalnfo, editedData, {
//         headers: { Authorization: `Bearer ${accessToken}` },
//       });
//       alert("Customer details updated successfully!");
//       dispatch(fetchCustomerDetails(accessToken));
//       setEditMode({});
//     } catch (error) {
//       alert("Failed to update customer details.");
//     }
//   };

//   const handleAddAddress = (type) => {
//     setNewAddress({
//       address_type_id: type === "Billing" ? 2 : 1,
//       address_type: type,
//       deliver_to: "",
//       street_no: "",
//       post_office: "",
//       division: divisions.length > 0 ? divisions[0].value : "",
//       thana: upazilas.length > 0 ? upazilas[0].value : "",
//       dist: districts.length > 0 ? districts[0].value : "",
//       state: "",
//       country: "",
//     });
//   };

//   const handleSaveAddress = async (index) => {
//     try {
//       const address = index === null ? newAddress : editedData.address[index];
//       const isNew = index === null;

//       const config = { headers: { Authorization: `Bearer ${accessToken}` } };
//       const endpoint = isNew
//         ? endpoints.addAddress
//         : `${endpoints.updateAddress}/${address.customer_address_id}`;

//       await axios[isNew ? "post" : "put"](endpoint, address, config);

//       alert("Address saved successfully!");
//       setNewAddress(null);
//       setEditMode({});
//       dispatch(fetchCustomerDetails(accessToken));
//     } catch (error) {
//       alert("Failed to save address.");
//     }
//   };

//   const handleDeleteAddress = async (index) => {
//     try {
//       const address = editedData.address[index];
//       await axios.post(
//         endpoints.deleteAddress,
//         { id: address.customer_address_id },
//         {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         }
//       );
//       alert("Address deleted successfully!");
//       dispatch(fetchCustomerDetails(accessToken));
//     } catch (error) {
//       alert("Failed to delete address.");
//     }
//   };

//   const handleSetDefaultAddress = async (index) => {
//     try {
//       const address = editedData.address[index];
//       await axios.post(
//         endpoints.setDefaultAddress,
//         { id: address.customer_address_id },
//         {
//           headers: { Authorization: `Bearer ${accessToken}` },
//         }
//       );
//       alert("Default address set successfully!");
//       dispatch(fetchCustomerDetails(accessToken));
//     } catch (error) {
//       alert("Failed to set default address.");
//     }
//   };

//   // const handleEdit = (index) => {
//   //   setEditMode({ ...editMode, [index]: !editMode[index] });
//   // };
//   const handleEdit = (index) => {
//     const isEditMode = !editMode[index];
//     setEditMode((prev) => ({ ...prev, [index]: isEditMode }));

//     if (isEditMode) {
//       const address = editedData.address[index];
//       setSelectedDivision(
//         divisions?.find((d) => d?.value === address?.division) || null
//       );
//       setSelectedDistrict(
//         districts.find((d) => d.value === address.dist) || null
//       );
//       setSelectedUpazila(
//         upazilas.find((u) => u.value === address.thana) || null
//       );
//       setEditingAddressIndex(index);
//     } else {
//       setSelectedDivision(null);
//       setSelectedDistrict(null);
//       setSelectedUpazila(null);
//     }
//   };

//   const [activeTab, setActiveTab] = useState("userAccountProfile");
//   useEffect(() => {
//     // Check if the route contains a state with `activeTab` and update it
//     if (location.state?.activeTab) {
//       setActiveTab(location.state.activeTab);
//     }
//   }, [location.state]);
//   const handleTabSelect = (key) => {
//     setActiveTab(key);
//   };

//   const deliveryOptions = editedData.address
//     ?.map((addr, originalIndex) => ({ addr, originalIndex }))
//     .filter(({ addr }) => addr.address_type === "Delivery")
//     .map(({ addr, originalIndex }) => ({
//       value: originalIndex,
//       label: `Delevery To ${addr.deliver_to} - [ ${addr.street_no}, ${addr.thana}, ${addr.dist},${addr.country} ]`,
//     }));

//   const billingOptions = editedData.address
//     ?.map((addr, originalIndex) => ({ addr, originalIndex }))
//     .filter(({ addr }) => addr.address_type === "Billing")
//     .map(({ addr, originalIndex }) => ({
//       value: originalIndex,
//       label: `Belling To ${addr.deliver_to} - [ ${addr.street_no}, ${addr.thana}, ${addr.dist},${addr.country} ]`,
//     }));

//   if (status === "loading") return <LoadingSpinner />;
//   if (status === "failed") return <p>Error loading customer data: {error}</p>;

//   return (
//     <>
//       <Navigation />

//       <section className="checkoutPageSection">
//         <Container>
//           <Tab.Container
//             activeKey={activeTab}
//             onSelect={handleTabSelect}
//             defaultActiveKey="userAccountProfile"
//           >
//             <Row>
//               <Col md={4}>
//                 <div className="profileuserr mt-4 ">
//                   <div className="userInformation">
//                     <div className="userImage">
//                       <img
//                         src="https://i.postimg.cc/8srbPVNG/icon.webp"
//                         border="0"
//                         alt="icon"
//                       />
//                     </div>
//                     <div className="useraddress">
//                       <p>{editedData.name}</p>
//                       <p>+88 {editedData.phone}</p>
//                       <p>Dhaka, Bangladesh</p>
//                     </div>
//                   </div>

//                   <div className="userHistory">
//                     <Nav variant="pills" className="flex-column">
//                       <Nav.Item>
//                         <Nav.Link eventKey="userAccountProfile">
//                           <BsPerson /> Profile
//                         </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="orderHistory">
//                           <BsClockHistory /> Order History
//                         </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="paymentHistory">
//                           <BsCreditCard /> Payment History
//                         </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="reviewHistory">
//                           <BsBox /> Your Review
//                         </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="qanda">
//                           <BsPatchQuestion /> Your Q & A
//                         </Nav.Link>
//                       </Nav.Item>
//                     </Nav>
//                   </div>
//                 </div>
//               </Col>
//               <Col md={8}>
//                 <Tab.Content>
//                   <Tab.Pane eventKey="userAccountProfile">
//                     {customer && (
//                       <div className="w-100 chekoutUserDetails userAccoundDetails">
//                         <div className="pesonalInformation bellingAddressAndDeleveryAddress">
//                           <div class="or addressAddTitle mb-3 mt-2">
//                             <span>Personal Information</span>
//                           </div>
//                           <Row>
//                             <Col md={6}>
//                               <div className="inputUser">
//                                 <label>Name:</label>
//                                 <div className="d-flex infutUserEditSave">
//                                   <input
//                                     className="form-control"
//                                     type="text"
//                                     name="name"
//                                     value={editedData.name}
//                                     readOnly={!editMode.name}
//                                     onChange={handleChange}
//                                   />
//                                   {!editMode.name ? (
//                                     <button onClick={() => handleEdit("name")}>
//                                       <BsPencilSquare />
//                                     </button>
//                                   ) : (
//                                     <button
//                                       className="saveProfileBtn"
//                                       onClick={handleSaveCustomer}
//                                     >
//                                       <BsCheck2Square />
//                                     </button>
//                                   )}
//                                 </div>
//                               </div>
//                             </Col>
//                             <Col md={6}>
//                               <div className="inputUser">
//                                 <label>Email:</label>
//                                 <div className="d-flex infutUserEditSave">
//                                   <input
//                                     className="form-control"
//                                     type="email"
//                                     name="email"
//                                     value={editedData.email}
//                                     readOnly={!editMode.email}
//                                     onChange={handleChange}
//                                   />
//                                   {!editMode.email ? (
//                                     <button onClick={() => handleEdit("email")}>
//                                       <BsPencilSquare />
//                                     </button>
//                                   ) : (
//                                     <button
//                                       className="saveProfileBtn"
//                                       onClick={handleSaveCustomer}
//                                     >
//                                       <BsCheck2Square />
//                                     </button>
//                                   )}
//                                 </div>
//                               </div>
//                             </Col>
//                           </Row>
//                           <Row>
//                             <Col md={12}>
//                               <div className="inputUser mt-3">
//                                 <label>Phone:</label>
//                                 <div className="d-flex infutUserEditSave">
//                                   <input
//                                    style={{border:'1px solid #e7e7e7 !important',borderRadius:'4px !important'}}
//                                     className="form-control"
//                                     type="text"
//                                     name="phone"
//                                     value={editedData.phone}
//                                     readOnly
//                                     onChange={handleChange}
//                                   />
//                                   {/* {!editMode.phone ? (
//                                     <button onClick={() => handleEdit("phone")}>
//                                       <BsPencilSquare />
//                                     </button>
//                                   ) : (
//                                     <button
//                                       className="saveProfileBtn"
//                                       onClick={handleSaveCustomer}
//                                     >
//                                       <BsCheck2Square />
//                                     </button>
//                                   )} */}
//                                 </div>
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                         <div
//                           className="bellingAddressAndDeleveryAddress inputUser newAddressModalContainer"
//                           style={{
//                             height: "auto",
//                             padding: "0px",
//                             overflowY: "unset",
//                           }}
//                         >
//                           <div className="delBelPersonal deleveryAddress">
//                             <div class="or addressAddTitle mb-3 mt-4">
//                               <span>Delevery Address</span>
//                             </div>
//                             <Row>
//                               <Col md={12}>
//                                 <div className="selectUser">
//                                   <Select
//                                     options={[
//                                       {
//                                         label: (
//                                           <button
//                                             style={{
//                                               all: "unset",
//                                               cursor: "pointer",
//                                               color: "rgb(246,140,30)",
//                                               fontWeight: "bold",
//                                             }}
//                                             onClick={() =>
//                                               handleAddAddress("Delivery")
//                                             }
//                                           >
//                                             <BsFillPlusCircleFill
//                                               style={{ marginRight: "5px" }}
//                                             />
//                                             Add New Delivery Address
//                                           </button>
//                                         ),
//                                         value: "addNew", // Ensure this value is unique
//                                         isDisabled: true, // Prevent this option from being selectable
//                                       },

//                                       ...(deliveryOptions || []), // Spread your existing delivery options here
//                                     ]}
//                                     value={deliveryOptions?.find(
//                                       (opt) => opt.value === selectedDelivery
//                                     )}
//                                     onChange={(e) =>
//                                       setSelectedDelivery(e.value)
//                                     }
//                                     placeholder="Select a delevery address"
//                                   />
//                                 </div>

//                                 <div className="selectUser">
//                                   {selectedDelivery !== null && (
//                                     <>
//                                       <Form>
//                                         <Form.Group controlId="formDeliveryTo">
//                                           <Form.Label>Delivery To:</Form.Label>
//                                           <Form.Control
//                                             type="text"
//                                             value={
//                                               editedData.address[
//                                                 selectedDelivery
//                                               ]?.deliver_to || ""
//                                             }
//                                             readOnly={
//                                               !editMode[selectedDelivery]
//                                             }
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 selectedDelivery,
//                                                 "deliver_to"
//                                               )
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formStreet">
//                                           <Form.Label>Street:</Form.Label>
//                                           <Form.Control
//                                             type="text"
//                                             value={
//                                               editedData.address[
//                                                 selectedDelivery
//                                               ]?.street_no || ""
//                                             }
//                                             readOnly={
//                                               !editMode[selectedDelivery]
//                                             }
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 selectedDelivery,
//                                                 "street_no"
//                                               )
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formPostOffice">
//                                           <Form.Label>Post Office:</Form.Label>
//                                           <Form.Control
//                                             type="text"
//                                             value={
//                                               editedData.address[
//                                                 selectedDelivery
//                                               ]?.post_office || ""
//                                             }
//                                             readOnly={
//                                               !editMode[selectedDelivery]
//                                             }
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 selectedDelivery,
//                                                 "post_office"
//                                               )
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formDivision">
//                                           <Form.Label>Division:</Form.Label>
//                                           <Select
//                                             options={divisions}
//                                             value={selectedDivision}
//                                             onChange={(selected) => {
//                                               setSelectedDivision(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "division",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "division"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formDistrict">
//                                           <Form.Label>District:</Form.Label>
//                                           <Select
//                                             options={districts}
//                                             value={selectedDistrict}
//                                             onChange={(selected) => {
//                                               setSelectedDistrict(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "district",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "dist"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formUpazila">
//                                           <Form.Label>
//                                             Upazila (Thana):
//                                           </Form.Label>
//                                           <Select
//                                             options={upazilas}
//                                             value={selectedUpazila}
//                                             onChange={(selected) => {
//                                               setSelectedUpazila(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "thana",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "thana"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formState">
//                                           <Form.Label>State:</Form.Label>
//                                           <Form.Control
//                                             type="text"
//                                             value={
//                                               editedData.address[
//                                                 selectedDelivery
//                                               ]?.state || ""
//                                             }
//                                             readOnly={
//                                               !editMode[selectedDelivery]
//                                             }
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 selectedDelivery,
//                                                 "state"
//                                               )
//                                             }
//                                           />
//                                         </Form.Group>

//                                         <Form.Group controlId="formCountry">
//                                           <Form.Label>Country:</Form.Label>
//                                           <Form.Control
//                                             type="text"
//                                             value={
//                                               editedData.address[
//                                                 selectedDelivery
//                                               ]?.country || ""
//                                             }
//                                             readOnly={
//                                               !editMode[selectedDelivery]
//                                             }
//                                             onChange={(e) =>
//                                               handleChange(
//                                                 e,
//                                                 selectedDelivery,
//                                                 "country"
//                                               )
//                                             }
//                                           />
//                                         </Form.Group>
//                                       </Form>

//                                       <div className="action-buttons">
//                                         <button
//                                           onClick={() =>
//                                             editMode[selectedDelivery]
//                                               ? handleSaveAddress(
//                                                   selectedDelivery
//                                                 )
//                                               : handleEdit(selectedDelivery)
//                                           }
//                                         >
//                                           {editMode[selectedDelivery]
//                                             ? "Save"
//                                             : "Edit"}
//                                         </button>
//                                         <button
//                                           onClick={() =>
//                                             handleSetDefaultAddress(
//                                               selectedDelivery
//                                             )
//                                           }
//                                         >
//                                           Make Default
//                                         </button>
//                                         {/* <button
//                                           onClick={() =>
//                                             handleDeleteAddress(
//                                               selectedDelivery
//                                             )
//                                           }
//                                         >
//                                           Delete
//                                         </button> */}
//                                       </div>
//                                     </>
//                                   )}
//                                 </div>
//                                 {/* <button onClick={() => handleAddAddress("Delivery")}>Add Delivery Address</button> */}
//                               </Col>
//                             </Row>
//                           </div>
//                           <div className="delBelPersonal bellingAddress">
//                             <div class="or addressAddTitle mb-3 mt-4">
//                               <span>Billing Address</span>
//                             </div>
//                             <Row>
//                               <Col md={12}>
//                                 {/* <Select
//                                   options={billingOptions}
//                                   value={billingOptions?.find((opt) => opt.value === selectedBilling)}
//                                   onChange={(e) => setSelectedBilling(e.value)}
//                                 /> */}
//                                 <div className="selectUser">
//                                   <Select
//                                     options={[
//                                       {
//                                         label: (
//                                           <button
//                                             style={{
//                                               all: "unset",
//                                               cursor: "pointer",
//                                               color: "rgb(246,140,30)",
//                                               fontWeight: "bold",
//                                             }}
//                                             onClick={() =>
//                                               handleAddAddress("Billing")
//                                             }
//                                           >
//                                             <BsFillPlusCircleFill
//                                               style={{ marginRight: "5px" }}
//                                             />
//                                             Add New Billing Address
//                                           </button>
//                                         ),
//                                         value: "addNew",
//                                         isDisabled: true,
//                                       },
//                                       {
//                                         label: (
//                                           <p
//                                             style={{
//                                               cursor: "pointer",
//                                               color: "rgb(246,140,30)",
//                                               fontWeight: "bold",
//                                             }}
//                                           >
//                                             Same as Delevery Address
//                                           </p>
//                                         ),
//                                         value: "same", // Value for "Same as Billing Address"
//                                       },
//                                       ...(billingOptions || []),
//                                     ]}
//                                     value={billingOptions?.find(
//                                       (opt) => opt.value === selectedBilling
//                                     )}
//                                     onChange={(e) =>
//                                       setSelectedBilling(
//                                         e.value === "same" ? "same" : e.value
//                                       )
//                                     }
//                                     placeholder="Select a billing address"
//                                   />
//                                 </div>
//                                 <br></br>
//                                 <div className="selectUser">
//                                   {selectedBilling !== "same" &&
//                                     selectedBilling !== null && (
//                                       <>
//                                         <Form>
//                                           <Form.Group controlId="formBillingTo">
//                                             <Form.Label>Billing To:</Form.Label>
//                                             <Form.Control
//                                               type="text"
//                                               value={
//                                                 editedData.address[
//                                                   selectedBilling
//                                                 ]?.deliver_to || ""
//                                               }
//                                               readOnly={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(e) =>
//                                                 handleChange(
//                                                   e,
//                                                   selectedBilling,
//                                                   "deliver_to"
//                                                 )
//                                               }
//                                             />
//                                           </Form.Group>
//                                           <Form.Group controlId="formStreet">
//                                             <Form.Label>Street:</Form.Label>
//                                             <Form.Control
//                                               type="text"
//                                               value={
//                                                 editedData.address[
//                                                   selectedBilling
//                                                 ]?.street_no || ""
//                                               }
//                                               readOnly={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(e) =>
//                                                 handleChange(
//                                                   e,
//                                                   selectedBilling,
//                                                   "street_no"
//                                                 )
//                                               }
//                                             />
//                                           </Form.Group>

//                                           <Form.Group controlId="formPostOffice">
//                                             <Form.Label>
//                                               Post Office:
//                                             </Form.Label>
//                                             <Form.Control
//                                               type="text"
//                                               value={
//                                                 editedData.address[
//                                                   selectedBilling
//                                                 ]?.post_office || ""
//                                               }
//                                               readOnly={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(e) =>
//                                                 handleChange(
//                                                   e,
//                                                   selectedBilling,
//                                                   "post_office"
//                                                 )
//                                               }
//                                             />
//                                           </Form.Group>

//                                           <Form.Group controlId="formDivision">
//                                             <Form.Label>Division:</Form.Label>
//                                             <Select
//                                             options={divisions}
//                                             value={selectedDivision}
//                                             onChange={(selected) => {
//                                               setSelectedDivision(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "division",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "division"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                             {/* <Select
//                                               options={divisions}
//                                               value={divisions.find(
//                                                 (opt) =>
//                                                   opt.value ===
//                                                   editedData.address[
//                                                     selectedBilling
//                                                   ]?.division
//                                               )}
//                                               isDisabled={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(selected) =>
//                                                 handleChange(
//                                                   {
//                                                     target: {
//                                                       name: "division",
//                                                       value: selected.value,
//                                                     },
//                                                   },
//                                                   selectedBilling,
//                                                   "division"
//                                                 )
//                                               }
//                                             /> */}
//                                           </Form.Group>

//                                           <Form.Group controlId="formDistrict">
//                                             <Form.Label>District:</Form.Label>
//                                             <Select
//                                             options={districts}
//                                             value={selectedDistrict}
//                                             onChange={(selected) => {
//                                               setSelectedDistrict(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "district",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "dist"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                           </Form.Group>

//                                           <Form.Group controlId="formUpazila">
//                                             <Form.Label>
//                                               Upazila (Thana):
//                                             </Form.Label>
//                                             <Select
//                                             options={upazilas}
//                                             value={selectedUpazila}
//                                             onChange={(selected) => {
//                                               setSelectedUpazila(selected);
//                                               handleChange(
//                                                 {
//                                                   target: {
//                                                     name: "thana",
//                                                     value:
//                                                       selected?.value || "",
//                                                   },
//                                                 },
//                                                 editingAddressIndex,
//                                                 "thana"
//                                               );
//                                             }}
//                                             isDisabled={
//                                               !editMode[editingAddressIndex]
//                                             }
//                                           />
//                                           </Form.Group>

//                                           <Form.Group controlId="formState">
//                                             <Form.Label>State:</Form.Label>
//                                             <Form.Control
//                                               type="text"
//                                               value={
//                                                 editedData.address[
//                                                   selectedBilling
//                                                 ]?.state || ""
//                                               }
//                                               readOnly={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(e) =>
//                                                 handleChange(
//                                                   e,
//                                                   selectedBilling,
//                                                   "state"
//                                                 )
//                                               }
//                                             />
//                                           </Form.Group>

//                                           <Form.Group controlId="formCountry">
//                                             <Form.Label>Country:</Form.Label>
//                                             <Form.Control
//                                               type="text"
//                                               value={
//                                                 editedData.address[
//                                                   selectedBilling
//                                                 ]?.country || ""
//                                               }
//                                               readOnly={
//                                                 !editMode[selectedBilling]
//                                               }
//                                               onChange={(e) =>
//                                                 handleChange(
//                                                   e,
//                                                   selectedBilling,
//                                                   "country"
//                                                 )
//                                               }
//                                             />
//                                           </Form.Group>
//                                         </Form>

//                                         <div className="action-buttons">
                                        
//                                           <button
                                          
//                                             onClick={() =>
//                                               editMode[selectedBilling]
//                                               ? handleSaveAddress(
//                                                 selectedBilling
//                                                 )
//                                               : handleEdit(selectedBilling)
//                                             }
//                                           >
//                                             {editMode[selectedBilling]
//                                               ? "Save"
//                                               : "Edit"}
//                                           </button>
//                                           <button
//                                             onClick={() =>
//                                               handleSetDefaultAddress(
//                                                 selectedBilling
//                                               )
//                                             }
//                                           >
//                                             Make Default
//                                           </button>
                                   
//                                         </div>
//                                       </>
//                                     )}
//                                 </div>
//                               </Col>
//                             </Row>
//                           </div>
//                         </div>

//                         {newAddress && (
//                           <div className="newAddressModal">
//                             <div className="addtingNewAddressHeader">
//                               <h6 className="addtingNewAddressTitle">
//                                 PLease Add {newAddress.address_type} Address
//                               </h6>
//                               <button
//                                 className="btn btn-primary"
//                                 onClick={() => setNewAddress(null)}
//                               >
//                                 <BsXLg />
//                               </button>
//                             </div>
//                             <div className="newAddressModalContainer">
//                               <Container fluid>
//                                 <div
//                                   className="mb-2 inputUser"
//                                   style={{ display: "none" }}
//                                 >
//                                   <label>Type:</label>
//                                   <input
//                                     className="form-control"
//                                     type="text"
//                                     value={newAddress.address_type}
//                                   />
//                                 </div>
//                                 <Row>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser  w-100">
//                                       <label>Delivery To :</label>
//                                       <input
//                                         placeholder="Enter your product receiver name.."
//                                         className="form-control"
//                                         type="text"
//                                         value={newAddress.deliver_to}
//                                         onChange={(e) =>
//                                           setNewAddress({
//                                             ...newAddress,
//                                             deliver_to: e.target.value,
//                                           })
//                                         }
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser w-100">
//                                       <label>Street:</label>
//                                       <input
//                                         className="form-control"
//                                         type="text"
//                                         placeholder="Enter your street name.."
//                                         value={newAddress.street_no}
//                                         onChange={(e) =>
//                                           setNewAddress({
//                                             ...newAddress,
//                                             street_no: e.target.value,
//                                           })
//                                         }
//                                       />
//                                     </div>
//                                   </Col>
//                                 </Row>
//                                 <Row>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser ">
//                                       <label>post office:</label>
//                                       <input
//                                         className="form-control"
//                                         type="text"
//                                         placeholder="Enter your post ofiice name.."
//                                         value={newAddress.post_office}
//                                         onChange={(e) =>
//                                           setNewAddress({
//                                             ...newAddress,
//                                             post_office: e.target.value,
//                                           })
//                                         }
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                       <label htmlFor="division">
//                                         Division:
//                                       </label>
//                                       <Select
//                                         id="division"
//                                         options={divisions}
//                                         value={selectedDivision}
//                                         onChange={setSelectedDivision}
//                                         placeholder="Select a Division"
//                                         maxMenuHeight={120}
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                       <label htmlFor="district">District</label>

//                                       <Select
//                                         id="district"
//                                         options={districts} // districts should be formatted as { value, label } objects
//                                         value={
//                                           districts.find(
//                                             (district) =>
//                                               district.value === newAddress.dist
//                                           ) || null
//                                         }
//                                         onChange={(selectedOption) => {
//                                           setNewAddress({
//                                             ...newAddress,
//                                             dist: selectedOption
//                                               ? selectedOption.value
//                                               : "",
//                                           });
//                                           setSelectedDistrict(
//                                             selectedOption || null
//                                           );
//                                         }}
//                                         placeholder="Select a District"
//                                         isDisabled={!selectedDivision} // Disables the dropdown if no division is selected
//                                         maxMenuHeight={200}
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="selectUser mb-2">
//                                       <label htmlFor="upazila">
//                                         Upazila (Thana)
//                                       </label>
//                                       <Select
//                                         id="upazila"
//                                         options={upazilas} // districts should be formatted as { value, label } objects
//                                         value={
//                                           upazilas.find(
//                                             (upazila) =>
//                                               upazila.value === newAddress.thana
//                                           ) || null
//                                         }
//                                         onChange={(selectedOption) => {
//                                           setNewAddress({
//                                             ...newAddress,
//                                             thana: selectedOption
//                                               ? selectedOption.value
//                                               : "",
//                                           });
//                                           setSelectedUpazila(
//                                             selectedOption || null
//                                           );
//                                         }}
//                                         placeholder="Select a upozila"
//                                         isDisabled={!selectedDistrict} // Disables the dropdown if no division is selected
//                                         maxMenuHeight={200}
//                                       />
//                                     </div>
//                                   </Col>
//                                 </Row>

//                                 <Row>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser">
//                                       <label>State:</label>
//                                       <input
//                                         className="form-control"
//                                         placeholder="Enter your state name.."
//                                         type="text"
//                                         value={newAddress.state}
//                                         onChange={(e) =>
//                                           setNewAddress({
//                                             ...newAddress,
//                                             state: e.target.value,
//                                           })
//                                         }
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col md={6}>
//                                     <div className="mb-2 inputUser">
//                                       <label>Country:</label>
//                                       <input
//                                         className="form-control"
//                                         type="text"
//                                         placeholder="Enter your country name.."
//                                         value={newAddress.country}
//                                         onChange={(e) =>
//                                           setNewAddress({
//                                             ...newAddress,
//                                             country: e.target.value,
//                                           })
//                                         }
//                                       />
//                                     </div>
//                                   </Col>
//                                 </Row>
//                               </Container>
//                             </div>
//                             <div className="addressbarFooter  d-flex align-items-center ">
//                               <button
//                                 className="btn btn-primary"
//                                 onClick={() => handleSaveAddress(null)}
//                               >
//                                 Add
//                               </button>
//                               <button
//                                 className="btn btn-primary d-flex align-items-center gap-1"
//                                 onClick={() => setsaveNewAddress(null)}
//                               >
//                                 {" "}
//                                 <Form.Check />
//                                 Save New Address
//                               </button>
//                               <button
//                                 className="btn btn-primary"
//                                 onClick={() => setNewAddress(null)}
//                               >
//                                 Cancel
//                               </button>
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                     )}
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="orderHistory">
//                     {activeTab === "orderHistory" && <OrderHistory />}
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="reviewHistory">
//                     {activeTab === "reviewHistory" && <ReviewHistory />}
//                   </Tab.Pane>
//                 </Tab.Content>
                
//               </Col>
//             </Row>
//           </Tab.Container>
//         </Container>
//       </section>
//     </>
//   );
// };

// export default UserAccount;






import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
import axios from "axios";
import Navigation from "../component/navigation";
import Select from "react-select";
import LoadingSpinner from "../component/loadingSpinner";
import "../css/userAccount.css";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import {
  BsBox,
  BsCheck2Square,
  BsClockHistory,
  BsCreditCard,
  BsFillPlusCircleFill,
  BsHouseDoor,
  BsPatchQuestion,
  BsPencilSquare,
  BsPerson,
  BsTrash,
  BsXLg,
} from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import OrderHistory from "../component/orderHistory";
import endpoints from "../api/endpoints";
import ReviewHistory from "../component/reviewHistory";
import Footer from "../component/footer";
import QnaHistory from "../component/qnaHistory";
import PaymentHistory from "../component/paymentHistory";

const UserAccount = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const { customer, status, error } = useSelector(
    (state) => state.customerDetails
  );
  console.log(customer);
  const location = useLocation();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newAddress, setNewAddress] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedBilling, setSelectedBilling] = useState(null);
  const [saveNewAddress, setsaveNewAddress] = useState(null);

  const [divisions, setDivisions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [upazilas, setUpazilas] = useState([]);




  useEffect(() => {
    if (customer) {
      setEditedData({
        name: customer?.customer_details?.name || "",
        email: customer?.customer_details?.email || "",
        phone: customer?.user_id || "",
        address: customer?.customer_address?.map((addr) => ({ ...addr })) || [],
      });
    }
  }, [customer]);



  
  const handleChange = (e, index = null, addressField = null) => {
    const { name, value } = e.target;
    if (addressField !== null) {
      const updatedAddresses = editedData.address.map((addr, idx) =>
        idx === index ? { ...addr, [addressField]: value } : addr
      );
      setEditedData({ ...editedData, address: updatedAddresses });
    } else {
      setEditedData({ ...editedData, [name]: value });
    }
  };

  const handleSaveCustomer = async () => {
    try {
      if (!customer?.customer_details?.name) { // Check if name doesn't exist
        // Create new customer details
        await axios.post(endpoints.addPersonalInfo, editedData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      } else {
        // Update existing customer details
        await axios.put(endpoints.updateCustomerPersonalnfo, editedData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      }
      
      alert("Customer details updated successfully!");
      dispatch(fetchCustomerDetails(accessToken));
      setEditMode({});
      
    } catch (error) {
      console.error("Update failed:", error.response?.data || error.message);
      alert(`Failed to update customer details: ${error.response?.data?.message || "Please check the input fields"}`);
    }
  };
  const handleAddAddress = (type) => {
    setNewAddress({
      address_type_id: type === "Billing" ? 2 : 1,
      address_type: type,
      deliver_to: "",
      street_no: "",
      zip_code: "",
      division: divisions.length > 0 ? divisions[0].value : "",
      thana: upazilas.length > 0 ? upazilas[0].value : "",
      dist: districts.length > 0 ? districts[0].value : "",
      state: "",
      country: "",
    });
  };

  const handleSaveAddress = async (index) => {
    try {
      const address = index === null ? newAddress : editedData.address[index];
      const isNew = index === null;

      const config = { headers: { Authorization: `Bearer ${accessToken}` } };
      const endpoint = isNew
        ? endpoints.addAddress
        : `${endpoints.updateAddress}/${address.customer_address_id}`;

      await axios[isNew ? "post" : "put"](endpoint, address, config);

      alert("Address saved successfully!");
      setNewAddress(null);
      setEditMode({});
      dispatch(fetchCustomerDetails(accessToken));
    } catch (error) {
      alert("Failed to save address.");
    }
  };

 

  const handleSetDefaultAddress = async (index) => {
    try {
      const address = editedData.address[index];
      await axios.put(
       endpoints.defaultAddress,
        {
          customer_address_id: address.customer_address_id,
          make_default: 1
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      alert("Default address set successfully!");
      dispatch(fetchCustomerDetails(accessToken));
      
      // Update UI immediately
      const updatedAddresses = editedData.address.map(addr => ({
        ...addr,
        is_default: addr.customer_address_id === address.customer_address_id ? 1 : 0
      }));
      setEditedData(prev => ({ ...prev, address: updatedAddresses }));
    } catch (error) {
      console.error("Error setting default address:", error);
      alert("Failed to set default address.");
    }
  };
  useEffect(() => {
    if (customer?.customer_address) {
      const deliveryIndex = customer.customer_address.findIndex(
        addr => addr.address_type === "Delivery" && addr.is_default === 1
      );
      if (deliveryIndex !== -1) setSelectedDelivery(deliveryIndex);
      
      const billingIndex = customer.customer_address.findIndex(
        addr => addr.address_type === "Billing" && addr.is_default === 1
      );
      if (billingIndex !== -1) setSelectedBilling(billingIndex);
    }
  }, [customer]);
  // const handleEdit = (index) => {
  //   setEditMode({ ...editMode, [index]: !editMode[index] });
  // };
  const handleEdit = (index) => {
    const isEditMode = !editMode[index];
    setEditMode((prev) => ({ ...prev, [index]: isEditMode }));


  };

  const [activeTab, setActiveTab] = useState("userAccountProfile");
  useEffect(() => {
    // Check if the route contains a state with `activeTab` and update it
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);
  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const deliveryOptions = editedData.address
  ?.map((addr, originalIndex) => ({ addr, originalIndex }))
  .filter(({ addr }) => addr.address_type === "Delivery")
  .map(({ addr, originalIndex }) => ({
    value: originalIndex,
    label: `${addr.is_default ? "[Default Address] " : ""}Delivery To ${addr.deliver_to} -  ${addr.street_no}, ${addr.zip_code}`,
  }));

const billingOptions = editedData.address
  ?.map((addr, originalIndex) => ({ addr, originalIndex }))
  .filter(({ addr }) => addr.address_type === "Billing")
  .map(({ addr, originalIndex }) => ({
    value: originalIndex,
    label: `${addr.is_default ? "[Default Address] " : ""}Billing To ${addr.deliver_to} -  ${addr.street_no}, ${addr.zip_code} `,
  }));

  if (status === "loading") return <LoadingSpinner />;
  if (status === "failed") return <p>Error loading customer data: {error}</p>;

  return (
    <>
      <Navigation />

      <section className="checkoutPageSection">
        <Container>
          <Tab.Container
            activeKey={activeTab}
            onSelect={handleTabSelect}
            defaultActiveKey="userAccountProfile"
          >
            <Row>
              <Col md={4}>
                <div className="profileuserr mt-4 ">
                  <div className="userInformation">
                    <div className="userImage">
                      <img
                        src="https://i.postimg.cc/8srbPVNG/icon.webp"
                        border="0"
                        alt="icon"
                      />
                    </div>
                    <div className="useraddress">
                      <p>{editedData.name}</p>
                      <p>+88 {editedData.phone}</p>
                      <p>Dhaka, Bangladesh</p>
                    </div>
                  </div>

                  <div className="userHistory">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="userAccountProfile">
                          <BsPerson /> Profile
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="orderHistory">
                          <BsClockHistory /> Order History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="paymentHistory">
                          <BsCreditCard /> Payment History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="reviewHistory">
                          <BsBox /> Your Review
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="qanda">
                          <BsPatchQuestion /> Your Q & A
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="userAccountProfile">
                    {customer && (
                      <div className="w-100 chekoutUserDetails userAccoundDetails">
                        <div className="pesonalInformation bellingAddressAndDeleveryAddress">
                          <div class="or addressAddTitle mb-3 mt-2">
                            <span>Personal Information</span>
                          </div>
                          <Row>
                            <Col md={6}>
                              <div className="inputUser">
                                <label>Name:</label>
                                <div className="d-flex infutUserEditSave">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={editedData.name}
                                    readOnly={!editMode.name}
                                    onChange={handleChange}
                                  />
                                  {!editMode.name ? (
                                    <button onClick={() => handleEdit("name")}>
                                      <BsPencilSquare />
                                    </button>
                                  ) : (
                                    <button
                                      className="saveProfileBtn"
                                      onClick={handleSaveCustomer}
                                    >
                                      <BsCheck2Square />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="inputUser">
                                <label>Email:</label>
                                <div className="d-flex infutUserEditSave">
                                  <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    value={editedData.email}
                                    readOnly={!editMode.email}
                                    onChange={handleChange}
                                  />
                                  {!editMode.email ? (
                                    <button onClick={() => handleEdit("email")}>
                                      <BsPencilSquare />
                                    </button>
                                  ) : (
                                    <button
                                      className="saveProfileBtn"
                                      onClick={handleSaveCustomer}
                                    >
                                      <BsCheck2Square />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <div className="inputUser mt-3">
                                <label>Phone:</label>
                                <div className="d-flex infutUserEditSave">
                                  <input
                                   style={{border:'1px solid #e7e7e7 !important',borderRadius:'4px !important'}}
                                    className="form-control"
                                    type="text"
                                    name="phone"
                                    value={editedData.phone}
                                    readOnly
                                    onChange={handleChange}
                                  />
                                 
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="bellingAddressAndDeleveryAddress inputUser newAddressModalContainer"
                          style={{
                            height: "auto",
                            padding: "0px",
                            overflowY: "unset",
                          }}
                        >
                          <div className="delBelPersonal deleveryAddress">
                            <div class="or addressAddTitle mb-3 mt-4">
                              <span>Delevery Address</span>
                            </div>
                            <Row>
                              <Col md={12}>
                                <div className="selectUser">
                                  <Select
                                    options={[
                                      {
                                        label: (
                                          <button
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleAddAddress("Delivery")
                                            }
                                          >
                                            <BsFillPlusCircleFill
                                              style={{ marginRight: "5px" }}
                                            />
                                            Add New Delivery Address
                                          </button>
                                        ),
                                        value: "addNew", // Ensure this value is unique
                                        isDisabled: true, // Prevent this option from being selectable
                                      },

                                      ...(deliveryOptions || []), // Spread your existing delivery options here
                                    ]}
                                    value={deliveryOptions?.find(
                                      (opt) => opt.value === selectedDelivery
                                    )}
                                    onChange={(e) =>
                                      setSelectedDelivery(e.value)
                                    }
                                    placeholder="Select a delevery address"
                                  />
                                </div>

                                <div className="selectUser">
                                  {selectedDelivery !== null && (
                                    <>
                                      <Form>
                                        <Form.Group controlId="formDeliveryTo">
                                          <Form.Label>Delivery To:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.deliver_to || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "deliver_to"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group controlId="formStreet">
                                          <Form.Label>Street:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.street_no || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "street_no"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                       
                                   
                                        <Form.Group controlId="formState">
                                          <Form.Label>State:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.state || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "state"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                        <Form.Group controlId="formCountry">
                                          <Form.Label>Country:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.country || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "country"
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group controlId="formZipCode">
                                          <Form.Label>Zip Code:</Form.Label>
                                          <Form.Control
                                            type="text"
                                            value={
                                              editedData.address[
                                                selectedDelivery
                                              ]?.zip_code || ""
                                            }
                                            readOnly={
                                              !editMode[selectedDelivery]
                                            }
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                selectedDelivery,
                                                "zip_code"
                                              )
                                            }
                                          />
                                        </Form.Group>

                                      </Form>

                                      <div className="action-buttons">
                                        <button
                                          onClick={() =>
                                            editMode[selectedDelivery]
                                              ? handleSaveAddress(
                                                  selectedDelivery
                                                )
                                              : handleEdit(selectedDelivery)
                                          }
                                        >
                                          {editMode[selectedDelivery]
                                            ? "Save"
                                            : "Edit"}
                                        </button>
                                        <button
                                          className={`ml-2 ${editedData.address[selectedDelivery]?.is_default ? "btn-success" : "btn-warning"}`}
                                          onClick={() => handleSetDefaultAddress(selectedDelivery)}
                                        >
                                          {editedData.address[selectedDelivery]?.is_default ? "Default Address" : "Make Default"}
                                        </button>
                                      
                                      </div>
                                    </>
                                  )}
                                </div>
                               
                              </Col>
                            </Row>
                          </div>
                          <div className="delBelPersonal bellingAddress">
                            <div class="or addressAddTitle mb-3 mt-4">
                              <span>Billing Address</span>
                            </div>
                            <Row>
                              <Col md={12}>
                               
                                <div className="selectUser">
                                  <Select
                                    options={[
                                      {
                                        label: (
                                          <button
                                            style={{
                                              all: "unset",
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleAddAddress("Billing")
                                            }
                                          >
                                            <BsFillPlusCircleFill
                                              style={{ marginRight: "5px" }}
                                            />
                                            Add New Billing Address
                                          </button>
                                        ),
                                        value: "addNew",
                                        isDisabled: true,
                                      },
                                      {
                                        label: (
                                          <p
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(246,140,30)",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Same as Delevery Address
                                          </p>
                                        ),
                                        value: "same", // Value for "Same as Billing Address"
                                      },
                                      ...(billingOptions || []),
                                    ]}
                                    value={billingOptions?.find(
                                      (opt) => opt.value === selectedBilling
                                    )}
                                    onChange={(e) =>
                                      setSelectedBilling(
                                        e.value === "same" ? "same" : e.value
                                      )
                                    }
                                    placeholder="Select a billing address"
                                  />
                                </div>
                                <br></br>
                                <div className="selectUser">
                                  {selectedBilling !== "same" &&
                                    selectedBilling !== null && (
                                      <>
                                        <Form>
                                          <Form.Group controlId="formBillingTo">
                                            <Form.Label>Billing To:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.deliver_to || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "deliver_to"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formStreet">
                                            <Form.Label>Street:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.street_no || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "street_no"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formState">
                                            <Form.Label>State:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.state || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "state"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        

                                         
                                          

                                          <Form.Group controlId="formCountry">
                                            <Form.Label>Country:</Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.country || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "country"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                          <Form.Group controlId="formPostOffice">
                                            <Form.Label>
                                              Zip Code:
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              value={
                                                editedData.address[
                                                  selectedBilling
                                                ]?.zip_code || ""
                                              }
                                              readOnly={
                                                !editMode[selectedBilling]
                                              }
                                              onChange={(e) =>
                                                handleChange(
                                                  e,
                                                  selectedBilling,
                                                  "zip_code"
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Form>

                                        <div className="action-buttons">
                                        
                                          <button
                                            onClick={() =>
                                              editMode[selectedBilling]
                                              ? handleSaveAddress(
                                                selectedBilling
                                                )
                                              : handleEdit(selectedBilling)
                                            }
                                          >
                                            {editMode[selectedBilling]
                                              ? "Save"
                                              : "Edit"}
                                          </button>
                                          <button
                                          className={`ml-2 ${editedData.address[selectedBilling]?.is_default ? "btn-success" : "btn-warning"}`}
                                          onClick={() => handleSetDefaultAddress(selectedBilling)}
                                        >
                                          {editedData.address[selectedBilling]?.is_default ? "Default Address" : "Make Default"}
                                        </button>
                                         
                                   
                                        </div>
                                      </>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        {newAddress && (
                          <div className="newAddressModal">
                            <div className="addtingNewAddressHeader">
                              <h6 className="addtingNewAddressTitle">
                                PLease Add {newAddress.address_type} Address
                              </h6>
                              <button
                                className="btn btn-primary"
                                onClick={() => setNewAddress(null)}
                              >
                                <BsXLg />
                              </button>
                            </div>
                            <div className="newAddressModalContainer">
                              <Container fluid>
                                <div
                                  className="mb-2 inputUser"
                                  style={{ display: "none" }}
                                >
                                  <label>Type:</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={newAddress.address_type}
                                  />
                                </div>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-2 inputUser  w-100">
                                      <label>Delivery To :</label>
                                      <input
                                        placeholder="Enter your product receiver name.."
                                        className="form-control"
                                        type="text"
                                        value={newAddress.deliver_to}
                                        onChange={(e) =>
                                          setNewAddress({
                                            ...newAddress,
                                            deliver_to: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-2 inputUser w-100">
                                      <label>Street:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter your street name.."
                                        value={newAddress.street_no}
                                        onChange={(e) =>
                                          setNewAddress({
                                            ...newAddress,
                                            street_no: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <div className="mb-2 inputUser ">
                                      <label>Zip Code:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter your post ofiice name.."
                                        value={newAddress.zip_code}
                                        onChange={(e) =>
                                          setNewAddress({
                                            ...newAddress,
                                            zip_code: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <div className="mb-2 inputUser">
                                      <label>State:</label>
                                      <input
                                        className="form-control"
                                        placeholder="Enter your state name.."
                                        type="text"
                                        value={newAddress.state}
                                        onChange={(e) =>
                                          setNewAddress({
                                            ...newAddress,
                                            state: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-2 inputUser">
                                      <label>Country:</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter your country name.."
                                        value={newAddress.country}
                                        onChange={(e) =>
                                          setNewAddress({
                                            ...newAddress,
                                            country: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                            <div className="addressbarFooter  d-flex align-items-center ">
                              <button
                                className="btn btn-primary"
                                onClick={() => handleSaveAddress(null)}
                              >
                                Add
                              </button>
                              <button
                                className="btn btn-primary d-flex align-items-center gap-1"
                                onClick={() => setsaveNewAddress(null)}
                              >
                                {" "}
                                <Form.Check />
                                Save New Address
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => setNewAddress(null)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="orderHistory">
                    {activeTab === "orderHistory" && <OrderHistory />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="paymentHistory">
                    {activeTab === "paymentHistory" && <PaymentHistory />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="reviewHistory">
                    {activeTab === "reviewHistory" && <ReviewHistory />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="qanda">
                    {activeTab === "qanda" && <QnaHistory />}
                  </Tab.Pane>
                </Tab.Content>
                
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
      <br></br><br></br>
      <Footer />
    </>
  );
};

export default UserAccount;