// import React, { useContext, useEffect, useReducer, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
// import Navigation from "../component/navigation";

// import { Accordion, Button, Card, Modal, Pagination, Table } from "react-bootstrap";
// import  "../css/orderHistory.css";
// import { BsBoxFill, BsBoxSeam, BsCarFrontFill, BsFillTelephoneFill, BsHandThumbsUp, BsHandThumbsUpFill, BsJournalText, BsTruck, BsWhatsapp, BsXLg } from "react-icons/bs";
// import LoadingSpinner from "./loadingSpinner";
// import { fetchReviewHistory } from "../redux/reviewHistorySlice";
// const ReviewHistory = () => {
//     const dispatch = useDispatch();
//     const { accessToken } = useSelector((state) => state.auth);
//     const { reviewshistory, status, error } = useSelector((state) => state.reviewHistory);
//   console.log(reviewshistory)



//     useEffect(() => {
//       if (status === 'idle' && accessToken)  {
//         dispatch(fetchReviewHistory(accessToken));
//       }
//     }, [status,accessToken,dispatch]);
  


  



//     if (status === 'loading') {
//       return <LoadingSpinner />;
//     }
  
//     if (status === 'failed') {
//       return <p> Error loading reviews: {error}</p>;
//     }
  
//   // console.log("Login Success:", loginSuccess);
//   return (
//     <>
//       {reviewshistory?.length === 0 ? (
//         <div className="alert alert-info">
//           You haven't submitted any reviews yet.
//         </div>
//       ) : (
//     <div className="mt-4">
    
//     {(reviewshistory || []).map((review) => (
//   <Card key={review.review_id} className="mb-3">
//     <Card.Body>
//       <div className="d-flex align-items-start">
//         <img 
//           src={review.image} 
//           alt={review.product_name}
//           style={{ width: '100px', marginRight: '15px' }}
//         />
//         <div>
//           <Card.Title>{review.product_name}</Card.Title>
//           <Card.Subtitle className="mb-2 text-muted">
//             Reviewed by: {review.name_published}
//           </Card.Subtitle>
//           <div className="mb-2">
//             Rating: {Array(review.rating).fill().map((_, i) => (
//               <span key={i} style={{ color: '#ffc107' }}>★</span>
//             ))}
//           </div>
//           <Card.Text>{review.review}</Card.Text>
//           <div className="text-muted small">
//             Price: ৳{review.price.toLocaleString()}
//           </div>
//         </div>
//       </div>
//     </Card.Body>
//   </Card>
// ))}

//     </div>
   
//   )}


//     </>
//   );
// };

// export default ReviewHistory;


import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from "react-bootstrap";
import LoadingSpinner from "./loadingSpinner";
import { fetchReviewHistory } from "../redux/reviewHistorySlice";
import '../css/reviewHistory.css'
import useProductActions from "../utils/productThanos";
import { useNavigate } from "react-router-dom";
import Paginationsorq from "./paginationsorq";

const ReviewHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { reviewshistory, status, error } = useSelector((state) => state.reviewHistory);
  const { currentPage, itemsPerPage } = useSelector((state) => state.pagination);
  const indexOfLastReview = currentPage * itemsPerPage;
  const indexOfFirstReview = indexOfLastReview - itemsPerPage;
  const currentReviews = reviewshistory.slice(indexOfFirstReview, indexOfLastReview);


  useEffect(() => {
    if (status === 'idle' && accessToken) {
      dispatch(fetchReviewHistory(accessToken));
    }
  }, [status, accessToken, dispatch]);

  if (status === 'loading') return <LoadingSpinner />;

  if (status === 'failed') {
    return <div className="alert alert-danger m-4">Error: {error}</div>;
  }

  return (
    <div className="mt-4 container personalreviewHistory">
      {currentReviews?.length === 0 ? (
        <div className="reviewqnaorderhistory">
        <img src="../imgs/noreview.gif" alt="No reviews available" />
        </div>
      ) : (
       <Row>
         <div className="card-columns" >
          {(currentReviews || []).map((review) => (
           
              
                  <Card className="w-100 mb-2 p-1" key={review.review_id}  onClick={() => navigate(`/product/${review.product_id}`)}
                  style={{ cursor: 'pointer' }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') navigate(`/product/${review.product_id}`)
                  }}
                >
                    <Card.Body>
                      <div className="">
                        <img 
                          src={review.image} 
                          alt={review.product_name}
                          style={{ width: '100px', marginRight: '15px' }}
                          onError={(e) => {
                            e.target.onerror = null; 
                            e.target.src = '/fallback-image.jpg';
                          }}
                        />
                        <div>
                          <Card.Title>{review.product_name}</Card.Title>
                          <div className="">
                            {Array(review.rating).fill().map((_, i) => (
                              <span key={i} style={{ color: '#ffc107' }}>★</span>
                            ))}
                          </div>
                           <Card.Text>{review.review}</Card.Text>
                          <Card.Subtitle className="text-muted reviewby">
                            Reviewed Name: {review.name_published}
                          </Card.Subtitle>
                          
                         
                          {/* <div className="text-muted small">
                            Price: Tk {review.price.toLocaleString('en-BD')}
                          </div> */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
           
           
          ))}
           </div>
           
         </Row>
      )}
      <Paginationsorq totalItems={reviewshistory.length} />
      <br></br>

    </div>
  );
};

export default ReviewHistory;