
// import { createSlice } from '@reduxjs/toolkit';

// // Initial state
// const initialState = {
//   additionalQuestions: {}, // { productId: [question1, question2, ...] }
// };

// // Create slice
// const QAndASlice = createSlice({
//   name: 'questions',
//   initialState,
//   reducers: {
//     addQuestion: (state, action) => {
//       const { productId, newQuestion } = action.payload;
//       if (!state.additionalQuestions[productId]) {
//         state.additionalQuestions[productId] = [];
//       }
//       state.additionalQuestions[productId].push(newQuestion);
      
//     },
//     initializeQuestions: (state, action) => {
//       const { productId, questions } = action.payload;
//       state.additionalQuestions[productId] = questions; // Initialize questions
//     },
//   },
// });

// // Action creators
// export const { addQuestion, initializeQuestions } = QAndASlice.actions;

// // Selector to get combined questions by product ID
// export const selectCombinedQuestionsByProductId = (state, productId) => {
//   const productQuestions = state.questions.additionalQuestions[productId] || [];
//   return productQuestions;
// };


// export default QAndASlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'; // Import createSelector from reselect

// Initial state
const initialState = {
  additionalQuestions: {}, // { productId: [question1, question2, ...] }
};

// Create slice
const QAndASlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    addQuestion: (state, action) => {
      const { productId, newQuestion } = action.payload;

      // Initialize the product's question list if it doesn't exist
      if (!state.additionalQuestions[productId]) {
        state.additionalQuestions[productId] = [];
      }

      // Add the new question to the product's question list
      state.additionalQuestions[productId].push(newQuestion);
    },

    initializeQuestions: (state, action) => {
      const { productId, questions } = action.payload;

      // Initialize or replace the questions for the specific product
      state.additionalQuestions[productId] = questions;
    },
  },
});

// Action creators
export const { addQuestion, initializeQuestions } = QAndASlice.actions;

// Memoized selector to get combined questions by product ID
export const selectCombinedQuestionsByProductId = createSelector(
  (state) => state.questions.additionalQuestions, // Input selector
  (_, productId) => productId,                     // Second input argument: productId
  (additionalQuestions, productId) => additionalQuestions[productId] || [] // Output selector
);

export default QAndASlice.reducer;
