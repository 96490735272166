import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, selectProducts } from "../redux/productSlice";
import Slider from "react-slick";
import LoadingSpinner from "../component/loadingSpinner";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import Navigation from "../component/navigation";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import {
  BsCart2,
  BsCartPlusFill,
  BsDashLg,
  BsFillTelephoneFill,
  BsHeart,
  BsHeartFill,
  BsPlusLg,
  BsRepeat,
  BsTruck,
  BsWallet,
  BsWhatsapp,
} from "react-icons/bs";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ReviewForm from "../component/rating";
import QandA from "../component/qAndA";
import ProductCard from "../component/productCard";
import Footer from "../component/footer";
import "../css/productDetails.css";
import StartRating from "../component/starRating";
import Compare from "../page/compare";
import useProductActions from "../utils/productThanos";

const ProductDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const [showModal, setShowModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const product = useMemo(() => {
    return products.find((item) => Number(item.id) === Number(id));
  }, [products, id]);

  const compareItems = useSelector(state => state.compare.compareItems);
  const {
    cartItem,
    isWishlisted,
   
    isComparelisted,
    combinedImages,
    finalPrice,
    regularPrice,
    showPrice,
    orderQuantity,
    averageRating,
    numberOfReviews,
    handleAddToCards,
    handleRemoveProductDetails,
    increaseLocalQuantity,
    decreaseLocalQuantity,
    handleAddToWishlist,
    handleCompareProduct,
  } = useProductActions(product);

  const relatedProducts = useMemo(() => {
    // Stable filtering, only changes when `products` or `product` changes
    if (product) {
      return products.filter(
        (item) => item.category === product.category && item.id !== product.id
      );
    }
    return [];
  }, [products, product?.category, product?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!products.length) {
      // Only fetch if products haven't already been loaded
      dispatch(fetchProducts());
    }
  }, [dispatch, products.length]);

  const settings = useMemo(
    () => ({
      customPaging: function (i) {
        return (
          <a key={i}>
            <img src={combinedImages[i]} alt={`Thumb ${i}`} />
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: combinedImages.length > 1 ? true : false,
      centerPadding: "0px",
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: combinedImages.length > 1 ? true : false,
      afterChange: (index) => setActiveSlide(index),
    }),
    [combinedImages]
  );

  const relatedProductSettings = useMemo(
    () => ({
      dots: false,
      infinite: relatedProducts.length >1? true : false,
      speed: 500,
      arrows: relatedProducts.length > 1 ? true : false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 2, slidesToScroll: 1 },
        },
        {
          breakpoint: 480,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ],
    }),
    []
  );


  const [showAskForPrice, setShowAskForPrice] = useState(false);

  const handleCloseAskForPrice = () => setShowAskForPrice(false);
  const handleShowAskForPrice = () => setShowAskForPrice(true);
  // Fetch combined reviews from reviewSlice
  // useEffect(() => {
  //     console.log("Current Product ID:", product?.id);
  // }, [product?.id]);

  if (!product) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Navigation />
      <div className="productdetails">
        <Container fluid>
          <Row className="productdetails-mainslider">
            <Col md={6}>
              <Slider {...settings}>
                {combinedImages.map((imageSrc, index) => (
                  <div key={index} onClick={() => setShowModal(true)}>
                    <img
                      src={imageSrc}
                      alt={`Slide ${index}`}
                      className="productdetails-mainslider-main-image"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="overview" title="Overview">
                      <div className="productINfoDetails">
                        <h2 className="productInfoName">
                          {product.name}
                          {/* {finalPrice.toFixed(2) ===
                                                product.price.toFixed(2) ? (
                                                  ''
                                                ):(
                                                   <span style={{color:'rgba(203, 28, 30, 1)'}}> &nbsp;( {product.discount} OFF)</span>
                                                )} */}
                        </h2>
                        <p className="productInfoRating d-flex align-items-center gap-1">
                          <ul
                            id="stars"
                            className="d-flex list-unstyled p-0 m-0"
                          >
                            <StartRating productRating={averageRating} />
                          </ul>
                          <span>{averageRating}</span>
                          <span>({numberOfReviews} Reviews)</span>
                        </p>
                        {finalPrice.toFixed(2) === product.price.toFixed(2) ? (
                          <p className="productInfoRegular d-flex flex-column">
                            {(() => {
                              if (showPrice == "Show") {
                                return <span>Regular Price:</span>;
                              }
                              return null;
                            })()}

                            {(() => {
                              if (showPrice == "Show") {
                                return (
                                  <span>TK {regularPrice.toFixed(2)}</span>
                                );
                              }
                              return null;
                            })()}
                          </p>
                        ) : (
                          <>
                            <p className="productInfoFinalPrice d-flex flex-column">
                              <span>Discount Price:</span>
                              <span>TK {finalPrice.toFixed(2)}</span>
                              <span>
                                {finalPrice.toFixed(2) ===
                                product.price.toFixed(2) ? (
                                  ""
                                ) : (
                                  <span
                                    style={{
                                      color: "rgba(203, 28, 30, 1)",
                                    }}
                                  >
                                    {" "}
                                    {product.discount} % OFF
                                  </span>
                                )}
                              </span>
                            </p>

                            <p className="productInfoRegular d-flex flex-column">
                              <span>Regular Price:</span>
                              <span>TK {regularPrice.toFixed(2)}</span>
                            </p>
                          </>
                        )}

                        <p className="productInfoDeleveryTime">
                          Delivery Time: <span>{product.deleveryTime}</span>
                          {product.deliveryTime}
                          <h2 className="productInfoDeleveryime"></h2>
                        </p>

                        <div className="quickOverView">
                          <h4>Quick Overview</h4>
                          <p style={{fontSize: '14px',lineHeight:'21px'}} className="mb-4">
                            {product.quickOverview ? (
                              
                               <span>
                                 {product.quickOverview}
                               </span>
                            ) : (
                              <span>No Quick Overview available</span>
                            )}
                          </p>
                        </div>

                        <div className="product-actions productInfoAddToCart d-flex gap-2">
                          <div
                            className="quantity-controls productInfoControls"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              className="minus"
                              onClick={decreaseLocalQuantity}
                              disabled={orderQuantity <= 1}
                            >
                              <BsDashLg />
                            </button>
                            <span className="input-box">{orderQuantity}</span>
                            <button
                              className="plus"
                              onClick={increaseLocalQuantity}
                            >
                              <BsPlusLg />
                            </button>
                          </div>

                          {(() => {
                            if (showPrice == "Show") {
                              return (
                                <button
                                  className={`add-to-cart-btn ${
                                    cartItem ? "added" : ""
                                  }`}
                                  onClick={
                                    cartItem
                                      ? handleRemoveProductDetails
                                      : handleAddToCards
                                  }
                                >
                                  {cartItem ? (
                                    <p>
                                      <BsCartPlusFill /> Remove From Cart
                                    </p>
                                  ) : (
                                    <p>
                                      <AiOutlineShoppingCart /> Add To Cart
                                    </p>
                                  )}
                                </button>
                              );
                            }
                            return null;
                          })()}

                          {(() => {
                            if (showPrice == "NotShow") {
                              return (
                                <>
                                 <button className={`add-to-cart-btn `} onClick={handleShowAskForPrice}>
                                  {<p> Ask For Price</p>}
                                </button>
                                <Modal show={showAskForPrice} onHide={handleCloseAskForPrice} className="callwppforprice">
                                    <Modal.Header closeButton>
                                      <Modal.Title>Connect For Price</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                       <p>
                                       <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
                                       <BsWhatsapp /> +88 012345789 Message on WhatsApp
                                      </a>
                                       </p>
                                       <p>
                                       <a href="tel:+1234567890">
                                       <BsFillTelephoneFill /> +88 012345789 Derect Call
                                      </a>
                                       </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                     
                                      
                                    </Modal.Footer>
                                  </Modal>
                                </>
                               
                              );
                            }
                            return null;
                          })()}

                          <button
                            className="compare-btn"
                            onClick={handleCompareProduct}
                          >
                            <BsRepeat /> Compare
                          </button>
                          <button
                            className={`wishlist-btn ${
                              isWishlisted ? "wishlisted" : ""
                            }`}
                            onClick={handleAddToWishlist}
                          >
                            {isWishlisted ? <BsHeartFill /> : <BsHeart />}{" "}
                            {isWishlisted ? "" : ""}
                          </button>
                        </div>
                        <div className="btn-group-sop d-flex gap-1">
                          <button className="btn btn-primary">
                            <BsTruck /> Shipping & Charge
                          </button>
                          <button className="btn btn-primary">
                            <BsCart2 /> Order Procedure
                          </button>
                          <button className="btn btn-primary">
                            <BsWallet /> Payment Method
                          </button>
                        </div>
                      </div>
                      <h6 className="mt-3 mb-0" style={{opacity:'1',fontSize:'13px',fontWeight:'500'}}>Tags  </h6>
                      <div className="alltagsofproduct">
                      
                         {product.tags && product.tags.map((tag, index) => (
  
                                <p>{tag}</p>
                            
                            ))}
                      </div>
                     
                    </Tab>
                    <Tab eventKey="home" title="Specifications">
                      <div className="product-tabs">
                        <div className="product-attribute">
                          {product.attributes &&
                          product.attributes.length > 0 ? (
                            <Table bordered responsive>
                              {/* <thead>
                                <tr>
                                  <th>Attribute Name</th>
                                  <th>Attribute Value</th>
                                </tr>
                              </thead> */}
                              <tbody>
                                {product.attributes.map((attribute, index) => (
                                  <tr key={index}>
                                    <td>{attribute.attributeName}</td>
                                    <td>{attribute.attributeValue}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          ) : (
                            <p></p>
                          )}
                        </div>
                         <div className="specifications-content mb-3">
                          {product.specifications ? (
                             <>
                             <h5 className="mb-2 sepecTitle">Technical Specification: </h5>
                             <div
                              className="specifications-html"
                              dangerouslySetInnerHTML={{
                                __html: product.specifications,
                              }}
                            ></div>
                             </>
                            
                          ) : (
                            <p></p>
                          )}
                        </div>
                        <div className="downloaded-documents mb-3">
                          {product.files && product.files.length > 0 ? (
                            <>
                              <h5 className="mb-2 sepecTitle">Documents: </h5>
                              <div className="document-cards">
                                {product.files.map((file, index) => (
                                  <div
                                    key={index}
                                    className="document-card "
                                  >
                                    <p>
                                      <strong style={{fontWeight:'600'}}>Title: </strong>
                                      {file.title}
                                    </p>
                                    {/* <p>
                                      <strong>File Name: </strong>
                                      {file.fileName}
                                    </p> */}
                                    <p>
                                      <a
                                        href={file.fileLink}
                                        download={file.fileName} // Forces file download
                                      >
                                        <button className="btn btn-primary">
                                          View
                                        </button>
                                      </a>
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                        </div>
                       
                      </div>
                    </Tab>
                    <Tab
                      eventKey="compare"
                      className="productComparenavbar"
                      title="Product Compare"
                    >
                       {/* {compareItems.length >= 2 && (
                        <div className="mt-3 showMoreCompare">
                          <Link to="/compare">Show More Compare</Link>
                        </div>
                      )} */}
                      <Compare compareLimitTabs={2} tablelenrgth={1}/>
                     
                      {/* {comparisonItems.length>=4 &&(
                                                <>
                                                 <Compare />
                                                 <Link to="/compare">Go to Compare Page</Link>
                                                </>
                                                
                                            )} */}
                    </Tab>
                    <Tab eventKey="contact" title="Q & A">
                      <QandA productId={product.id} />
                    </Tab>
                    <Tab eventKey="review" title="Review">
                      <ReviewForm productId={product?.id} />
                    </Tab>
                  </Tabs>
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <br />
              <br />
              <h3>Related Products</h3>
              <br />
              <Slider {...relatedProductSettings}>
                {relatedProducts.map((relatedProduct) => (
                  <ProductCard
                    key={relatedProduct.id}
                    product={relatedProduct}
                  />
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Product Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Popup Slick Slider */}
            <Row className="productdetails-mainslider">
              <Col md={6} className="productimageslick">
                <Slider {...settings} initialSlide={activeSlide}>
                  {combinedImages.map((image, index) => (
                    <div key={index}>
                      <div style={{ cursor: "pointer" }}>
                        <InnerImageZoom
                          src={image}
                          zoomType="hover" // Zoom on hover
                          zoomScale={1.5} // Scale of the zoom effect
                          zoomSrc={image} // High-quality zoom image source
                          className="product-zoom-image"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </Col>
              <Col md={6} className="showzomingimg">
              {product?.id===14 ? (<>
                <div>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/n0OBsP6Qb3A?si=-BA8GwlwaNgCPelA"
                    frameBorder="0"
                    allowFullScreen
                    title="Product Video"
                  ></iframe>
                </div>
              </>) : (<>
                {/* <div>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/ngElkyQ6Rhs"
                    frameBorder="0"
                    allowFullScreen
                    title="Product Video"
                  ></iframe>
                </div> */}
              </>) }
              {product?.id===22 ? (<>
                <div>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/uQIoGfvZlQU?si=ucHwox1SmXUl4Z8t"
                    frameBorder="0"
                    allowFullScreen
                    title="Product Video"
                  ></iframe>
                </div>
              </>) : (<>
                {/* <div>
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/ngElkyQ6Rhs"
                    frameBorder="0"
                    allowFullScreen
                    title="Product Video"
                  ></iframe>
                </div> */}
              </>) }
              </Col>
            </Row>
          </Modal.Body>

          <br />
          <br />
          <br />
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default React.memo(ProductDetail);
