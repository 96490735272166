import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./page/home";
import CategoryPage from "./page/category";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './redux/store';
import CartPage from "./component/cart";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from './component/loadingSpinner';
import AOS from 'aos';
import WishlistPage from "./component/wishlist";
import CartWish from './component/cartWishlistNavigate';
import ProductDetail from "./page/productDetail";
import AllProductsList from "./component/Allproductlist";
import WeekDeal from "./component/weekDeal";
import ControlledTabs from "./component/allProductsList";
import Compare from "./page/compare";
import ProductCategory from "./page/productCategory";
import TodaysDeals from './component/todaysDeals';
import TawkTo from "./component/TawkTo";  // TawkTo Chat Option
import Services from "./component/Services";  // TawkTo Chat Option

import AuthProvider from "./utils/authProvider";
import UserAccount from "./page/userAccount";
import PrivateRoute from "./utils/authUserAccount";
import ProtectedRoute from "./utils/protectedRouter";
import ViewCart from "./page/viewCart";

// import ForgotPassword from "./page/forgotPassword";
// import SignIn from "./page/signin";
// import Checkout from "./page/checkout--draft";
import Checkout from "./page/checkout";
import OrderSuccess from "./page/orderSuccess";
import SignIn from "./page/signin";
import { fetchCustomerDetails } from "./redux/customerDetailsSlice";








function App() {

  // const isLoading = useSelector((state) => state.loading.isLoading);
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, [])
  // useEffect(() => {
  //   document.title = "Your Store";
  // }, []);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.auth);


  // Fetch customer details if accessToken exists
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchCustomerDetails(accessToken));
    }
  }, [accessToken, dispatch]);

  
  return (

    <>
    
   
    <Router>
      
    {/* {isLoading && <LoadingSpinner />} */}
      <Routes>
    
     
        <Route path="*" element={<Homepage />} />
        <Route path="/service" element={<Services />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/category" element={<CategoryPage />} />
        <Route path="/compare" element={<Compare />} />
        <Route path="/productCategory" element={<ProductCategory />} />
        <Route path="/todays-deals"  element={<TodaysDeals />} />
        <Route path="/viewcart"  element={<ViewCart />} />
        
        
         <Route path="/checkout"  element={
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute> 
          } />
         <Route path="/orderSuccess"  element={<OrderSuccess />} />
       
        {/* <Route path="/signin"  element={<SignIn />} /> */}
        {/* <Route path="/forgotpassword"  element={<ForgotPassword />} /> */}
        
        <Route path="/signin"  element={
           <AuthProvider>
            <SignIn />
           </AuthProvider>
          } />
        <Route path="/signup"  element={
           <AuthProvider>
            <SignIn />
           </AuthProvider>
          } />
        {/* <Route path="/cart" element={<CartPage />} />
        <Route path="/wishlist" element={<WishlistPage />} /> */}
      <Route path="/account"  
           
           element={<PrivateRoute element={UserAccount} />
        
          } />
        {/* <Route path="/cart" element={<CartPage />} />
        <Route path="/wishlist" element={<WishlistPage />} /> */}
      </Routes>
      <CartWish />
      </Router>
    
     
      <ToastContainer />
      <TawkTo/>
    </>
  );
}

export default App;
