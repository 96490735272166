import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../api/endpoints";

export const fetchQnaHistory = createAsyncThunk(
  "qnaHistory/fetchQnaHistory",
  async (accessToken, { rejectWithValue }) => {
    try {
      //  axios.defaults.withCredentials = true
      const response = await axios.get(endpoints.customerQnaHistory, {
        headers: { Authorization: `Bearer ${accessToken}`},
       
      });
      console.log(response.data.data.customer_reviews)
      return response.data.data.customer_reviews;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch");
    }
  }
);

const qnaHistorySlice = createSlice({
  name: "qnaHistory",
  initialState: { 
    qnashistory: [], 
    status: "idle", 
    error: null
   },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQnaHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQnaHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qnashistory = action.payload || [];  // <-- Ensure it's always an array
       
      })
      .addCase(fetchQnaHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default qnaHistorySlice.reducer;
