import React, { useContext, useEffect, useReducer, useState } from "react";

import {
    
    BsFillPersonFill,
    BsPerson
   
  } from "react-icons/bs";
import Nav from "react-bootstrap/Nav";
import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { logout } from "../redux/authSlice";
import { Link } from "react-router-dom";
import LoadingSpinner from "./loadingSpinner";

const LogSign = () => {
    const dispatch = useDispatch();
  const { loginSuccess, user,accessToken, logoutLoading, logoutError, } = useSelector(
    (state) => state.auth
  );

const { customer, status, error } = useSelector((state) => state.customerDetails);


  const handleLogout = () => {
   
       dispatch(logout());
   
   
  };

  


  return (
    <>
     {loginSuccess ? (
        <Dropdown className="userdropdownSelection">
           {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
           { user}
          </Dropdown.Toggle> */}
           <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src='https://i.postimg.cc/8srbPVNG/icon.webp' border='0' alt='icon'/>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item dcUserItems"> 
                <div className="media d-flex gap-2">
                  <img src='https://i.postimg.cc/8srbPVNG/icon.webp' border='0' alt='icon'/>
                  <div className="media-body d-flex flex-column">
                    <h5 className="mt-0 mb-0">
                      {customer?.customer_details?.name===''? 
                      <span> Set Name</span>
                    : <span>{customer?.customer_details?.name}</span>  }</h5>
                     <p>+88 { user}</p>
                  </div>
                </div>
             </Link> 
            <Link className="dropdown-item" to='/account' state={{ activeTab: "userAccountProfile" }}> Account </Link> 
            <Link className="dropdown-item" to='/account' state={{ activeTab: "orderHistory" }}> Order List </Link> 
            <Link className="dropdown-item" to='/viewCart'> View Cart </Link> 
            <Link className="dropdown-item"  onClick={handleLogout}>Log Out</Link>
          </Dropdown.Menu>
        </Dropdown>

     ) : (

      <div className="login-signin-block d-flex align-items-center justify-content-center w500 ">
       
        <Link to='/signin'> <BsPerson /> Sign In</Link>
        
        
        {/* &nbsp;/&nbsp;
        <Nav.Link href='#'>Log In</Nav.Link> */}
      </div>
     )}
      
    </>
  );
};

export default LogSign;
