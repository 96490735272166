import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import wishReducer from './wishlistSlice';
import loadingReducer from './loadingSlice'; 
import sliderReducer from './sliderSlice';
import navigationReducer from './navigationSlice';
import productReducer from './productSlice';
import cartWishReducer from './cartWishSlice';
import compareReducer from './compareSlice'
import reviewReducer from './reviewSlice'
import qandaReducer from './qandaSlice'
import todayDealsReducer  from './todayDealsSlice'
import authlReducer from './authSlice'
import categoryReducer from "./categorySlice";
import customerReducer from "./customerDetailsSlice";
import topNavbarReducer from "./topNavbarSlice";
// import productTagsReducer from "./latestProduct";
import latestProductReducer from "./latestProductsSlice"
import orderHistoryReducer  from './orderHistorySlice';
import controlTabsProductReducer  from './controlTabsProductSlice';
import weekDealsReducer from './weekDealsSlice'
import reviewHistoryReducer from './reviewHistorySlice'
import qnaHistorySlice from './qnaHistorySlice'
import paginationReducer from './paginationSlice';
import paymentHistoryReducer from './paymentHistorySlice';
const store = configureStore({
  reducer: {
    cart: cartReducer,
    loading: loadingReducer,
    wishlist: wishReducer,
    slider: sliderReducer,
    navigation: navigationReducer,
    products: productReducer,
    cartWish:cartWishReducer,
    compare:compareReducer,
    reviews: reviewReducer,
    questions: qandaReducer,
    todayDeals: todayDealsReducer,
    auth: authlReducer,
    category: categoryReducer,
    customerDetails:customerReducer,
    topNavbar: topNavbarReducer,
    latestProducts:latestProductReducer,
    orderHistory: orderHistoryReducer,
    productsTabs:controlTabsProductReducer,
    weekDeals: weekDealsReducer,
    reviewHistory: reviewHistoryReducer,
    qnaHistory:qnaHistorySlice,
    pagination: paginationReducer,
    paymentHistory: paymentHistoryReducer,
  },
});

export default store;

