// SearchResults.js
import React from "react";
import { Card, Button } from "react-bootstrap";
import useProductActions from "../utils/productThanos";
import '../css/searchProduct.css'
const SearchResults = ({ product }) => {
    const { handleViewProduct } = useProductActions(product);
  return (
    <div className="search-results">
    
          <Card key={product.id} className="">
            <Card.Body onClick={handleViewProduct}>
              <p><img src={product.image} /></p>
              <Card.Title>{product.name}</Card.Title>
             
            </Card.Body>
          </Card>
    </div>
  );
};

export default SearchResults;
