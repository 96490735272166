

import "../css/checkout.css";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../component/navigation";
import Footer from "../component/footer";
import PageTitleArea from "../component/PageTitleArea";
import { BsDashLg, BsFillTrash3Fill, BsPlusLg, BsXCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import {
  increaseQuantity,
  decreaseQuantity,
  clearCart,
} from "../redux/cartSlice"; // Adjust as per your structure
import { handleRemoveProduct } from "../utils/productAction";
import useProductActions from "../utils/productThanos";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectProducts } from "../redux/productSlice";
import { closeCart } from "../redux/cartWishSlice";
import '../css/viewcart.css'
const ViewCart=()=>{
    const location = useLocation();
  const { cartItems,totalPrice ,handleClearCart,totalWithVat,vat} = useProductActions();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Close the cart sidebar when navigating to the Checkout page
    dispatch(closeCart());
  }, [dispatch]);

  const {loginSuccess,user } = useSelector((state) => state.auth);


  


  const handleCheckout = () => {
    if (!loginSuccess) {
      // Redirect to signin page with state
      navigate("/signin", {
        state: {
          from: "/checkout", 
         
        },
      });
    } 
    if (loginSuccess) {
      navigate("/checkout");
    }
  };
   return (

    <>
   <Navigation />

   {/* <PageTitleArea /> */}

     <div className="container-fluid p-3 mt-3">
        <div className="row">
            <div className="col-md-8">
                <div className="checkoutBox">
                    <div className="col-12 mb-1"><h4>View Cart</h4></div>
                    {cartItems.length>0 ?(
                      <>
                       <div className="table-responsive">
                        <table className="table table-border mt-1">
                            <thead>
                            <tr>
                                <th scope="col">SL</th>
                                <th scope="col">Product</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>
                                <th scope="col">Remove</th>
                                {/* <th scope="col">Vat</th> */}
                                {/* <th width="60px" scope="col"></th> */}
                            </tr>
                            </thead>
                            <tbody>
                            {cartItems.map((item, index) => (
                              <tr key={item.id} className="">
                                <th scope="row">{index + 1}</th>
                                <td>{item.name}</td>
                                <td>Tk. {item.orderPrice.toFixed(2)}</td>
                                <td >
                                  <div
                                    className="quantity_controls_viewcard quantity-controls productInfoControls d-flex justify-content-between align-items-center product-add-col"
                                    
                                  >
                                  
                                        <button   className="minus"
                                      onClick={() =>
                                        dispatch(decreaseQuantity({ id: item.id }))
                                      }
                                    >
                                      <BsDashLg />
                                    </button>
                                    <small className="input-box">
                                    {item.orderQuantity}
                                    </small>
                                    <button
                                      className="plus"
                                      onClick={() =>
                                        dispatch(increaseQuantity({ id: item.id }))
                                      }
                                    >
                                      <BsPlusLg />
                                    </button>
                                  </div>
                                </td>
                                <td>Tk. {(item.orderPrice * item.orderQuantity).toFixed(2)}</td>
                                <td>                <button
                                  className="removeProductCart cartItemRemove btn btn-danger btn-sm"
                                onClick={() => handleRemoveProduct(dispatch,  item.id, cartItems)}
                                >
                                  <BsXCircleFill />
                                </button></td>
                                {/* <td>{item.vatPercentage}%</td> */}
                              </tr>
                              ))}
                                    
                            </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                       
                          className="btn btn-sm  empty-cart"
                          onClick={()=>{
                            if (window.confirm("Are you sure you want to clear the cart?")){
                            handleClearCart()}
                            }
                          }
                        >
                          <BsFillTrash3Fill /> Empty Cart
                        </button>
                      </div>
                      </>
                     
                    ) : (
                     <div className="d-flex flex-column align-items-center justify-content-center">
                      <img src="../../imgs/Shopping_Cart.gif" />
                       <p style={{fontWeight:'500',marginLeft:'50px',textTransform:'uppercase',fontSize:'13px', color:'var(--GrayColor)'}}>Please ADD Product</p>
                      </div>
                    )}
                   

                    
                </div>
            </div>
            <div className="col-md-4">
                <div className="order-card">
                    <h5 className="">Order Summary</h5>
                    <div className="w-100 d-flex justify-content-between pb-1">
                        <span>Subtotal</span>
                        <span>Tk. {totalPrice.toFixed(2)}</span>
                    </div>
                    <div className="w-100 d-flex justify-content-between pb-1">
                        <span>VAT / TAX <strong>(5%)</strong></span>
                        <span> <span>Tk. {vat.toFixed(2)}</span> </span>
                    </div>
                    <div className="total-view mt-3 w-100 pt-2 d-flex justify-content-between">
                        <strong>Total</strong>
                        <span>Tk. {totalWithVat.toFixed(2)}</span>
                    </div>
                   
                        <button className="btn btn-m btn-dark w-100 mb-1"  onClick={handleCheckout}> Checkout</button>
                     
                        {/* <button className="btn btn-m btn-dark w-100 mb-3"><Link to='/signin'>Checkout</Link></button> */}
                   
                    <div className="text-end pt-2 moreontinueShopping"><h6><Link to='/home'>More Shopping</Link> </h6></div>
                </div>
            </div>
        </div>
    </div>
    
    </>
   )
}

export  default  ViewCart



// import "../css/checkout.css";
// import React from "react";
// import { useDispatch } from "react-redux";
// import Navigation from "../component/navigation";
// import Footer from "../component/footer";
// import PageTitleArea from "../component/PageTitleArea";
// import { BsDashLg, BsFillTrash3Fill, BsPlusLg, BsXCircleFill } from "react-icons/bs";
// import { increaseQuantity, decreaseQuantity } from "../redux/cartSlice";
// import { handleRemoveProduct } from "../utils/productAction";
// import useProductActions from "../utils/productThanos";

// const calculateItemTotal = (price, quantity, baseVatPercentage) => {
//   const dynamicVatPercentage = baseVatPercentage * quantity; // VAT increases with quantity
//   const subtotal = price * quantity;
//   const vat = (subtotal * dynamicVatPercentage) / 100;
//   return { subtotal, vat, total: subtotal + vat, dynamicVatPercentage };
// };

// const Checkout = () => {
//   const { cartItems, totalPrice, handleClearCart } = useProductActions();
//   const dispatch = useDispatch();

//   return (
//     <>
//       <Navigation />
//       <PageTitleArea />
//       <div className="container-fluid p-3 mt-3">
//         <div className="row">
//           <div className="col-md-8">
//             <div className="checkoutBox">
//               <div className="col-12 mb-1">
//                 <h4>View Cart</h4>
//               </div>
//               <div className="table-responsive">
//                 <table className="table table-border mt-1">
//                   <thead>
//                     <tr>
//                       <th scope="col">#</th>
//                       <th scope="col">Product</th>
//                       <th scope="col">Unit Price</th>
//                       <th scope="col">Quantity</th>
//                       <th scope="col">Price</th>
//                       <th scope="col">Remove</th>
//                       {/* <th scope="col">Discount(%)</th>
//                       <th scope="col">Discount Ammount</th> */}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {cartItems.map((item, index) => {
//                       const { subtotal, vat, total, dynamicVatPercentage } =
//                         calculateItemTotal(item.price, item.orderQuantity, item.vatPercentage);

//                       return (
//                         <tr key={item.id}>
//                           <td>{index + 1}</td>
//                           <td>{item.name}</td>
//                           <td>Tk. {item.price.toFixed(2)}</td>
//                           <td>
//                             <div className="quantity-controls d-flex justify-content-between">
//                               <button
//                                 className="minus"
//                                 onClick={() => dispatch(decreaseQuantity({ id: item.id }))}
//                               >
//                                 <BsDashLg />
//                               </button>
//                               <span className="input-box">{item.orderQuantity}</span>
//                               <button
//                                 className="plus"
//                                 onClick={() => dispatch(increaseQuantity({ id: item.id }))}
//                               >
//                                 <BsPlusLg />
//                               </button>
//                             </div>
//                           </td>
//                           <td>Tk. {subtotal.toFixed(2)}</td>
//                           <td>
//                             <button
//                               className="removeProductCart btn btn-danger btn-sm"
//                               onClick={() => handleRemoveProduct(dispatch, item.id, cartItems)}
//                             >
//                               <BsXCircleFill /> Remove
//                             </button>
//                           </td>
//                           {/* <td>
//                             {dynamicVatPercentage.toFixed(2)}% <br />
//                             (Tk. {vat.toFixed(2)})
//                           </td> */}
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                 </table>
//               </div>
//               <div className="d-flex justify-content-end">
//                 <button
//                   className="btn btn-sm btn-danger empty-cart"
//                   onClick={() => {
//                     if (window.confirm("Are you sure you want to clear the cart?")) {
//                       handleClearCart();
//                     }
//                   }}
//                 >
//                   <BsFillTrash3Fill /> Empty Cart
//                 </button>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="order-card">
//               <h5 className="text-center font-700">Order Summary</h5>
//               <div className="w-100 d-flex justify-content-between pb-1">
//                 <span>Subtotal</span>
//                 <span>Tk. {totalPrice.toFixed(2)}</span>
//               </div>
//               <div className="w-100 d-flex justify-content-between pb-1">
//                 <span>VAT / TAX</span>
//                 <span>
//                   Tk.{" "}
//                   {cartItems
//                     .reduce(
//                       (acc, item) =>
//                         acc +
//                         (item.price * item.orderQuantity * item.vatPercentage * item.orderQuantity) /
//                           100,
//                       0
//                     )
//                     .toFixed(2)}
//                 </span>
//               </div>
//               <div className="total-view mt-3 w-100 pt-2 d-flex justify-content-between">
//                 <strong>Total</strong>
//                 <span>Tk. {totalPrice.toFixed(2)}</span>
//               </div>
//               <button className="btn btn-m btn-dark w-100 mb-3">Regular Checkout</button>
//               <div className="text-end pt-3 ContinueShopping">
//                 <h6>
//                   <a href="#" className="text-danger">
//                     Continue Shopping
//                   </a>
//                 </h6>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Checkout;
