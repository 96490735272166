

import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductCard from "./productCard";
import "../css/weekdeal.css";
import CountdownTimer from "./sevenDayesTimer";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeekDeals, selectWeekDeals } from "../redux/weekDealsSlice"; // Adjust path as needed
import Slider from "react-slick";
import "../css/sliderCustom.css";

const WeekDeal = () => {
  const dispatch = useDispatch();
  const weekDeals = useSelector(selectWeekDeals);

  useEffect(() => {
    if (weekDeals.length === 0) {
      dispatch(fetchWeekDeals());
    }
  }, [dispatch, weekDeals.length]);

  const weeksdeal = useMemo(() => weekDeals, [weekDeals]);

  const settings = {
    dots: true,
    infinite: weeksdeal.length >1? true : false,
    speed: 500,
    arrows: weeksdeal.length > 1 ? true : false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="title-weekdeal title-row-field title-section mt-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="d-flex gap-3 flex-row align-items-center title-row-field-border">
                <h2>THIS WEEK'S DEALS</h2>
                <div>
                  <CountdownTimer />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-4 weekdeal slidericonRight">
        <Container className="mainProductCartRow">
          <Row>
            <Slider {...settings}>
              {weeksdeal.map((product, index) => (
                <ProductCard key={product.id || index} product={product} />
              ))}
            </Slider>
          </Row>
          {
              weeksdeal.length >6 ? (
                <>
                 <Row>
                  <Col md={12}>
                    <div className="d-flex justify-content-center mt-3">
                      <button className="btn btn-primary weekdealbuttonshow">See More</button>
                    </div>
                  </Col>
                </Row>
                </>
              ) : (
                <>
                
                </>
              )
            }
          
        </Container>
      </section>
    </>
  );
};

export default WeekDeal;
