import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
  clearCart,
} from "../redux/cartSlice";
import { handleRemoveProduct } from "../utils/productAction";
import { closeCart } from "../redux/cartWishSlice";
import { BsXLg, BsXCircleFill } from "react-icons/bs";
import useProductActions from '../utils/productThanos';
import { Link } from "react-router-dom";


const CartSidebar = ({ product }) => {
  const { cartItems,totalPrice ,isCartOpen,handleClearCart} = useProductActions();
  
  const dispatch = useDispatch();
  if (!isCartOpen) return null;

  return (
    // <div className="cart-page">
    <div className={`cart-sidebar ${isCartOpen ? "open" : ""}`}>
      <div className="cart-sidebar-header cartwishHeader">
        <h2>Your Cart</h2>
        <button onClick={() => dispatch(closeCart())}>
          <BsXLg />
        </button>
      </div>
      {cartItems.length === 0 ? (
        <div className="emptyCart">
          <img src="../../imgs/Shopping_Cart.gif" />
          <p>Please ADD Product</p>
        </div>
      ) : (
        <>
          
          <ul className="p-0 productListCart scrollbar">
            {cartItems.map((item) => (
              <li
                key={item.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  position: "relative",
                }}
              >
                <div className="productImgSectionCart">
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <button
                  className="removeProductCart cartItemRemove btn btn-danger btn-sm d-flex align-items-center justify-content-center"
                 onClick={() => handleRemoveProduct(dispatch,  item.id, cartItems)}
                >
                  <BsXCircleFill /> 
                </button>
                <div className="productCartInfo">
                  <p className="productNameCart">{item.name}</p>
                  <p className="productPriceCart"> Tk {item.orderPrice.toFixed(2)}</p>
                  <div
                    className="quantity-controls"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      onClick={() =>
                        dispatch(decreaseQuantity({ id: item.id }))
                      }
                    >
                      -
                    </button>
                    <span>{item.orderQuantity}</span>
                    <button
                      onClick={() =>
                        dispatch(increaseQuantity({ id: item.id }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="productCartButton">
            <div className="total-price w-100">Total Price: &nbsp; <strong>Tk {totalPrice.toFixed(2)}</strong></div>
           <div className="viewcartAndEmptyCart">
              <div className="checkoutCart w-100"><p><Link to="/viewcart">View Cart</Link> </p></div>
           
              
           
            <button className="btn btn-danger btn-sm w-100" onClick={handleClearCart}>Empty Cart</button>
            </div>
          </div>
          
        </>
      )}
    </div>
  );
};

export default CartSidebar;
