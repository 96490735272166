// src/components/TopNavbar.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopNavbarData } from "../redux/topNavbarSlice";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "../css/topNavbar.css";
import LoadingSpinner from "./loadingSpinner";
import { BsFillEnvelopeFill, BsFillTelephoneForwardFill } from "react-icons/bs";

const TopNavbar = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.topNavbar);

  useEffect(() => {
    dispatch(fetchTopNavbarData());
  }, [dispatch]);
  
if(loading || error){
  return  <LoadingSpinner />
}


  return (
    <>
      <section className="topNavbar">
        <Container fluid>
          <div className="topheader-content d-flex align-items-center justify-content-between">
            <div className="welcomeTitle">
              <p style={{fontWeight:'600'}}>{data?.welcomeTitle}</p>
            </div>
            <div className="d-flex">
              <div className="needHelp d-flex">
                <p>{data?.needHelpTitle} &nbsp;</p>
                <p>
                  <span className=""><BsFillTelephoneForwardFill /></span> <strong><a style={{color:'#333333'}} href={`tel:${data?.supportNumber}`}>  {data?.supportNumber} </a> </ strong>
                </p>
              </div>
              <div className="emailContact"><p style={{fontWeight:'600'}}><span>&nbsp; OR &nbsp;</span><span  className=""> <BsFillEnvelopeFill /> </span> {data?.email}</p>
              </div>
              {/* <div className="contactUs">
                <p>
                  <Link to="/contact">{data?.contactUs}</Link>
                </p>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TopNavbar;
