import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching navigation data
export const fetchNavigationData = createAsyncThunk(
  'navigation/fetchNavigationData',
  async () => {
    const response = await fetch("../api/navigationData.json");
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  }
);

// Create a slice for the navigation
const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    logo: "",
    menu: [],
    signMenu: { name: "", linkmenus: "" },
    logMenu: { name: "", linkmenus: "" },
    loading: true,
    error: null,
  },
  reducers: {
    // You can add additional synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNavigationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNavigationData.fulfilled, (state, action) => {
        state.logo = action.payload.logo;
        state.menu = action.payload.menu;
        state.signMenu = action.payload.signMenu;
        state.logMenu = action.payload.logMenu;
        state.loading = false;
      })
      .addCase(fetchNavigationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default navigationSlice.reducer;
