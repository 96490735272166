
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Modal, Button, Pagination, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestion, selectCombinedQuestionsByProductId } from '../redux/qandaSlice'; // Adjust the path as needed
import { selectProducts } from '../redux/productSlice'; // Import selectProducts to access static data
import { toast } from "react-toastify";
import '../css/qanda.css'; 
import { BsArrowDownUp, BsFunnel } from 'react-icons/bs';
import axios from 'axios';
import { fetchQnaHistory } from '../redux/qnaHistorySlice';

const FAQItem = ({ question, answer, name, eventKey }) => {
  return (
    <div className='faqeslist'>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Header>
            <p>{question}</p>  
            <p>{name && <span>{name}</span>}</p>
          </Accordion.Header>
          <Accordion.Body>
            {answer ? answer : 'No answer yet.'}
          </Accordion.Body>
        </Accordion.Item>
    </div>
  );
};

const AskQuestionForm = ({ addQuestion,productId }) => {
   const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
 const { loginSuccess,accessToken } = useSelector(
    (state) => state.auth
  );

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!question.trim()) {
      setErrorMessage("Please write your question.");
      setShowErrorModal(true);
      return;
    }

    if (!isAnonymous && !name.trim()) {
      setErrorMessage("Please provide your name or check 'Post Anonymously'.");
      setShowErrorModal(true);
      return;
    }

    const questionObject = {
      name: isAnonymous ? 'Anonymous' : name,
      question: question,
      answer: null, 
    };

 
    try {
      console.log(questionObject)
      // Replace this with your POST request URL
      const response = await axios.post('http://store-admin.scldev.com:8084/api/v1/product-qas', {
        is_anonymous: isAnonymous ? 1 : 0,
        product_id: productId, // Replace with the actual product ID dynamically if needed
        customer_name:questionObject.name,
        question: questionObject.question,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    

      if (response.status === 200 || response.status === 201) {
        addQuestion(questionObject); // Add to the local state if needed
        toast.success("Question added successfully!");
        setName('');
        setQuestion('');
        setIsAnonymous(false);
      dispatch(fetchQnaHistory(accessToken));
      }
      else {
              toast.error('Failed to submit review. Please try again.');
            }
   
    } catch (error) {
      toast.error("There was an error submitting your question.");
      console.error(error);
    }
    // addQuestion(questionObject);
    // toast.success("Questions added successfully!");
    // setName('');
    // setQuestion('');
    // setIsAnonymous(false);
  };

  return (
    <>
      <h6 className='mb-3'>Ask a Question</h6>
      <div className="ask-question-form">
        <form onSubmit={handleSubmit}>
          <div className="form-check">
            <input
              type="checkbox"
              checked={isAnonymous}
              onChange={() => setIsAnonymous(!isAnonymous)}
              className="form-check-input"
              id="anonymousCheck"
            />
            <label className="form-check-label" htmlFor="anonymousCheck">
              Post Anonymously
            </label>
          </div>
          {!isAnonymous && (
            <div className="mb-2">
              <label className='m-0'>Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
                className="form-control"
              />
            </div>
          )}
          <div className="mb-3">
            <label className='m-0'>Question</label>
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Write your question"
              rows="3"
              className="form-control"
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit Question
          </button>
        </form>

        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Submission Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const QandA = ({ productId }) => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const product = products.find((prod) => prod.id === productId);
  const staticQandA = product.qAndA || [];
  const questions = useSelector((state) => selectCombinedQuestionsByProductId(state, productId));
  const combinedQandA = [...staticQandA, ...questions];

  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('recentToOld'); // Add a state for sorting order
  const [questionsPerPage, setQuestionsPerPage] = useState(5); // State for questions per page
  const totalQuestions = combinedQandA.length;
  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  const addQuestionHandler = (newQuestion) => {
    dispatch(addQuestion({ productId, newQuestion }));
  };

  // Sort questions based on the sortOrder state
  const sortedQandA = sortOrder === 'recentToOld' 
    ? [...combinedQandA].reverse()  // Sort from most recent to oldest
    : [...combinedQandA];            // Sort from oldest to most recent

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = sortedQandA.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-100 my-1">
      <h6 className="mb-3">Questions about this product ({combinedQandA.length})</h6>
      <AskQuestionForm addQuestion={addQuestionHandler}  productId={productId}/>
      <div className='d-flex justify-content-between align-items-center product-Review-rating'>
        <h6>Product Question</h6>
        <div className='d-flex gap-2 align-items-center flex-row'>
          <div className="sort-dropdown ">
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="sort-dropdown">
        <BsArrowDownUp /> Sort Questions: {sortOrder === 'recentToOld' ? 'Recent to Old' : 'Old to Recent'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item active={sortOrder === 'recentToOld'} onClick={() => setSortOrder('recentToOld')}>
            Recent to Old
          </Dropdown.Item>
          <Dropdown.Item active={sortOrder === 'oldToRecent'} onClick={() => setSortOrder('oldToRecent')}>
            Old to Recent
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
</div>
<div className="filter-dropdown ">
      <Dropdown >
  <Dropdown.Toggle variant="primary" id="items-dropdown">
  <BsFunnel /> Items per Page: {questionsPerPage}
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item active={questionsPerPage === 5} onClick={() => { setQuestionsPerPage(5); setCurrentPage(1); }}>5 items per page</Dropdown.Item>
    <Dropdown.Item active={questionsPerPage === 10} onClick={() => { setQuestionsPerPage(10); setCurrentPage(1); }}>10 items per page</Dropdown.Item>
    <Dropdown.Item active={questionsPerPage === 15} onClick={() => { setQuestionsPerPage(15); setCurrentPage(1); }}>15 items per page</Dropdown.Item>
    <Dropdown.Item active={questionsPerPage === 20} onClick={() => { setQuestionsPerPage(20); setCurrentPage(1); }}>20 items per page</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
</div>
</div>
</div>
      {combinedQandA.length > 0 ? (
        <Accordion defaultActiveKey="0" className="faq-list mt-4">
          {currentQuestions.map((faq, index) => (
            <FAQItem key={index + indexOfFirstQuestion} eventKey={index.toString()} question={faq.question} answer={faq.answer} name={faq.name} />
          ))}
        </Accordion>
      ) : (
        <p className='mt-3 mb-3'><img src='../imgs/noquestion.gif' alt="No questions yet." /></p>
      )}

      {/* Pagination */}
      <div className='custom-pagination-rq'>
        {totalQuestions > questionsPerPage && (
          <Pagination className="mt-3">
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(totalPages).keys()].map((number) => {
              if (number < 3) {
                return (
                  <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </Pagination.Item>
                );
              }
              if (number === currentPage - 1 || number === currentPage || number === currentPage + 1) {
                return (
                  <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </Pagination.Item>
                );
              }
              if (number === 3 && currentPage > 4) {
                return <Pagination.Ellipsis key="ellipsis1" />;
              }
              if (number >= totalPages - 2) {
                return (
                  <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                    {number + 1}
                  </Pagination.Item>
                );
              }
              return null;
            })}
            <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default QandA
