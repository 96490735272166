
// import React, { useState, useEffect, useMemo } from "react";
// import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
// import ProductCard from "./productCard";
// import "../css/allProductList.css";
// import Slider from "react-slick";
// import '../css/sliderCustom.css';
// import useProductActions from '../utils/productThanos';
// import LoadingSpinner from "./loadingSpinner";
// const ControlledTabs = () => {
//   const {    key,setKey, latestProducts,topRatingProducts, bestSellingProducts,featureProducts,  loading}=useProductActions()

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     responsive: [
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true
//         }
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2
//         }
//       },
//       {
//         breakpoint: 376,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };

//   return (
//     <>
//       <br />
//       <section className="title-weekdeal title-row-field title-section mt-5" >
//         <Container>
//           <Row>
//             <Col md={12}>
//               <div className=" d-flex gap-3  flex-row align-items-center title-row-field-border" style={{paddingBottom:'11px'}}>
//               <h2>TOP PRODUCTS</h2>
//                 <div>
                  
//                 </div>
//                 {/* <p>
//                   <FcPaid />
//                 </p> */}
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
     
//       <section className="mt-4 allProductList slidericonRight slidericonRightTop ">
//         <Container className="mainProductCartRowAll">
//           <Row>
//             <Col md={12}>
//               <Tabs
//                 id="controlled-tab-example"
//                 activeKey={key}
//                 onSelect={(k) => setKey(k)}
//                 className="mb-3"
//               >
//                 <Tab eventKey="latestProduct" title="Latest Product">
//                   {loading ? (
//                     <LoadingSpinner /> // Show loader while products are loading
//                   ) : (
//                     <div className="mt-4 allProductListproductCard">
//                       <Row>
//                         <Slider {...settings}>
//                           {latestProducts.map((product) => (
//                             <ProductCard key={product.id} product={product} />
//                           ))}
//                         </Slider>
//                       </Row>
//                     </div>
//                   )}
//                 </Tab>
//                 <Tab eventKey="topRating" title="Top Rating">
//                   {loading ? (
//                     <LoadingSpinner />
//                   ) : (
//                     <div className="mt-4 allProductListproductCard">
//                       <Row>
//                         <Slider {...settings}>
//                           {topRatingProducts.map((product) => (
//                             <ProductCard key={product.id} product={product} />
//                           ))}
//                         </Slider>
//                       </Row>
//                     </div>
//                   )}
//                 </Tab>
//                 <Tab eventKey="bestSelling" title="Best Selling">
//                   {loading ? (
//                     <LoadingSpinner />
//                   ) : (
//                     <div className="mt-4 allProductListproductCard">
//                       <Row>
//                         <Slider {...settings}>
//                           {bestSellingProducts.map((product) => (
//                             <ProductCard key={product.id} product={product} />
//                           ))}
//                         </Slider>
//                       </Row>
//                     </div>
//                   )}
//                 </Tab>
//                 <Tab eventKey="feature" title="Feature">
//                   {loading ? (
//                     <LoadingSpinner />
//                   ) : (
//                     <div className="mt-4 allProductListproductCard">
//                       <Row>
//                         <Slider {...settings}>
//                           {featureProducts.map((product) => (
//                             <ProductCard key={product.id} product={product} />
//                           ))}
//                         </Slider>
//                       </Row>
//                     </div>
//                   )}
//                 </Tab>
//               </Tabs>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// };

// export default ControlledTabs;




import React, { useState, useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ProductCard from "./productCard";
import "../css/allProductList.css";
import Slider from "react-slick";
import "../css/sliderCustom.css";
import useProductActions from "../utils/productThanos";
import LoadingSpinner from "./loadingSpinner";
import axios from "axios";
import endpoints from "../api/endpoints";

const ControlledTabs = () => {
  const { key, setKey } = useProductActions();

  const [latestProducts, setLatestProducts] = useState([]);
  const [topRatingProducts, setTopRatingProducts] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadedTabs, setLoadedTabs] = useState({
    latestProduct: false,
    topRating: false,
    bestSelling: false,
    feature: false,
  });

  const fetchProducts = async (url, setter, tabKey) => {
    try {
      setLoading(true);
      const response = await axios.get(url);
      setter(response.data.data.products);
      setLoadedTabs((prev) => ({ ...prev, [tabKey]: true }));
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching ${tabKey} products:`, error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initially load latest products
    if (!loadedTabs.latestProduct) {
      fetchProducts(
        endpoints.latestProduct,
        setLatestProducts,
        "latestProduct"
      );
    }
  }, [loadedTabs]);

  const handleTabSelect = (tabKey) => {
    setKey(tabKey);

    // Fetch data only if the tab hasn't been loaded yet
    if (!loadedTabs[tabKey]) {
      switch (tabKey) {
        case "latestProduct":
          fetchProducts(
            endpoints.latestProduct,
            setLatestProducts,
            "latestProduct"
          );
          break;
        case "topRating":
          fetchProducts(
            endpoints.topRating,
            setTopRatingProducts,
            "topRating"
          );
          break;
        case "bestSelling":
          fetchProducts(
            endpoints.bestSelling,
            setBestSellingProducts,
            "bestSelling"
          );
          break;
        case "feature":
          fetchProducts(
            endpoints.feature,
            setFeaturedProducts,
            "feature"
          );
          break;
        default:
          break;
      }
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <br />
      <section className="title-weekdeal title-row-field title-section mt-5">
        <Container>
          <Row>
            <Col md={12}>
              <div
                className="d-flex gap-3 flex-row align-items-center title-row-field-border"
                style={{ paddingBottom: "11px" }}
              >
                <h2>TOP PRODUCTS</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-4 allProductList slidericonRight slidericonRightTop">
        <Container className="mainProductCartRowAll">
          <Row>
            <Col md={12}>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={handleTabSelect}
                className="mb-3"
              >
                <Tab eventKey="latestProduct" title="Latest Product">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="mt-4 allProductListproductCard">
                      <Row>
                        <Slider {...settings}>
                          {latestProducts.map((product) => (
                            <ProductCard key={product.id} product={product} />
                          ))}
                        </Slider>
                      </Row>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="topRating" title="Top Rating">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="mt-4 allProductListproductCard">
                      <Row>
                        <Slider {...settings}>
                          {topRatingProducts.map((product) => (
                            <ProductCard key={product.id} product={product} />
                          ))}
                        </Slider>
                      </Row>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="bestSelling" title="Best Selling">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="mt-4 allProductListproductCard">
                      <Row>
                        <Slider {...settings}>
                          {bestSellingProducts.map((product) => (
                            <ProductCard key={product.id} product={product} />
                          ))}
                        </Slider>
                      </Row>
                    </div>
                  )}
                </Tab>
                <Tab eventKey="feature" title="Feature">
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="mt-4 allProductListproductCard">
                      <Row>
                        <Slider {...settings}>
                          {featuredProducts.map((product) => (
                            <ProductCard key={product.id} product={product} />
                          ))}
                        </Slider>
                      </Row>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ControlledTabs;

