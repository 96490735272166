// src/redux/wishlistSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wishlistItems: JSON.parse(localStorage.getItem('wishlistItems')) || [],
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const itemExists = state.wishlistItems.find((item) => item.id === action.payload.id);
      if (!itemExists) {
        state.wishlistItems.push(action.payload);
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
      }
    },
    removeFromWishlist: (state, action) => {
      state.wishlistItems = state.wishlistItems.filter((item) => item.id !== action.payload.id);
      localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
    },
    clearWishlist: (state) => {
      // state.wishlistItems = state.wishlistItems.filter((item) => item.id !== action.payload.id);
      // localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
      state.wishlistItems = [];
      localStorage.setItem('wishlistItems', JSON.stringify([]));
    },
  },
});

export const { addToWishlist, removeFromWishlist,clearWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
