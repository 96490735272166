// redux/categorySlice.js
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   selectedCategory: "All", // Default category
// };

// const categorySlice = createSlice({
//   name: "category",
//   initialState,
//   reducers: {
//     setSelectedCategory: (state, action) => {
//       state.selectedCategory = action.payload;
//     },
//   },
// });

// export const { setSelectedCategory } = categorySlice.actions;
// export const selectCategory = (state) => state.category.selectedCategory;
// export default categorySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "All",
  subCategory: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.category = action.payload.category;
      state.subCategory = action.payload.subCategory || null;
    },
    resetCategory(state) {
      state.category = "All";
      state.subCategory = null;
    },
  },
});

export const { setSelectedCategory, resetCategory } = categorySlice.actions;
export const selectCategory = (state) => state.category;
export default categorySlice.reducer;
