

import React, { useState, useEffect } from "react";
import { Button, Col, Container, Dropdown, Form, Row, Pagination } from "react-bootstrap";
import ProductCard from "../component/productCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, selectProducts, selectSearchResults, setFilteredProducts, setSearchQuery } from "../redux/productSlice";
import "../css/allProductList.css";
import "../css/productCategory.css";
import LoadingSpinner from "../component/loadingSpinner";
import Navigation from "../component/navigation";
import ReactSlider from "react-slider";
import StartRating from "../component/starRating";
import { FaList, FaRegStar, FaStar, FaTh } from "react-icons/fa";
import useProductActions from "../utils/productThanos";
import { initializeReviews, selectReviews } from "../redux/reviewSlice";
import { selectAverageRating, selectCombinedReviews } from "../utils/reviewSelector";
import TodaysDeals from "../component/todaysDeals";
import AllCatagory from "../component/allCatagory";
import { selectCategory, setSelectedCategory } from "../redux/categorySlice";
import { useLocation } from "react-router-dom";

const ProductCategory = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);

  const reviews = useSelector(selectReviews);
  const searchResults = useSelector(selectSearchResults);
  const location = useLocation();
  // const [priceRange, setPriceRange] = useState([0, 5000000]);
  const [sortOrder, setSortOrder] = useState("recentToOld");
  const [category, setCategory] = useState("All");
  const [selectedBrands, setSelectedBrands] = useState([]); 
  const [minRatingFilter, setMinRatingFilter] = useState(0);
  const [discountFilter, setDiscountFilter] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(6); 
  const [showTodayDeals, setShowTodayDeals] = useState(false);
  const [showWeekDeals, setShowWeekDeals] = useState(false);
  const [showblackFridayDeals, setShowblackFridayDeals] = useState(false);
  const [showTopsell, setshowTopsell] = useState(false);
  const selectedCategory = useSelector(selectCategory);
  const maxPrice = products && products.length > 0
    ? Math.max(...products.map((product) => product.price || 0))
    : 0;

  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  useEffect(() => {
    if (location.state?.showblackFridayDeals) {
      setShowblackFridayDeals(true);
    }
  }, [location.state]);
  useEffect(() => {
    // Update max price dynamically if products change
    if (products && products.length > 0) {
      const updatedMaxPrice = Math.max(...products.map((product) => product.price || 0));
      setPriceRange([0, updatedMaxPrice]);
    }
  }, [products]);



  useEffect(() => {
    setLoading(true); // Set loading to true when fetching products
    dispatch(fetchProducts())
      .then(() => setLoading(false))  // On success, set loading to false
      .catch((err) => {
        setLoading(false);
        setError(err.message);  // Set error state if an error occurs
      });
  }, [dispatch]);



  useEffect(() => {
    products.forEach((product) => {
    
      if (!reviews[product.id]) {
        
        dispatch(initializeReviews({ productId: product.id, existingReviews: product.reviewsList }));
      }
    });
  }, [products, reviews, dispatch]);




  useEffect(() => {
    setCurrentPage(1);  // Reset to the first page on any filter change
  }, [priceRange, sortOrder, category, selectedBrands, minRatingFilter, discountFilter,itemsPerPage,showTodayDeals,showWeekDeals,showblackFridayDeals, selectedCategory,showTopsell]);


  useEffect(() => {
    // Filter brands based on selected category and subcategory
    if (selectedCategory.category === "All") {
      // If "All" is selected, show all unique brands
      setFilteredBrands([...new Set(products?.map((product) => product?.brand))]);
      
    } else if (selectedCategory?.subCategory) {
      // Filter brands for both selected category and subcategory
      const brandsForSubcategory = [
        ...new Set(
          products
            .filter(
              (product) =>
                product.category === selectedCategory.category &&
                product.subCategory === selectedCategory.subCategory
                
            )
            .map((product) => product.brand)
        ),
      ];
      setFilteredBrands(brandsForSubcategory);
    } else {
      // Filter brands for the selected category only
      const brandsForCategory = [
        ...new Set(
          products
            .filter((product) => product.category === selectedCategory.category)
            .map((product) => product.brand)
        ),
      ];
      setFilteredBrands(brandsForCategory);
    }
  }, [selectedCategory, products]);

  const categories = [...new Set(products.map((product) => product.category))]
  .map((category) => ({
    name: category,
    subcategories: [...new Set(
      products
        .filter((product) => product.category === category)
        .map((product) => product.subCategory)
        .filter(Boolean)
    )],
  }));


  // const isDealExpired = (dealEndDate) => {
  //   const currentDate = new Date();
  //   const dealEnd = new Date(dealEndDate);
  //   return dealEnd < currentDate; // Expired if end date is before current date
  // };


  const calculateAverageRating = (productId) => {
    const productReviews = reviews[productId]?.reviewsList || [];
    if (productReviews.length === 0) return 0;
    const totalRating = productReviews.reduce((sum, review) => sum + review.PersonRating, 0);
    return totalRating / productReviews.length;
  };

  const filteredProducts = (searchResults.length > 0 ? searchResults : products).filter((product) => {
    const hasDiscount = discountFilter ? product.discount>0 && product.discount !== "" : true;
    const isTodayDeal = showTodayDeals ? product.tags.includes("Today's Deals") : true;
    const isWeekDeal = showWeekDeals ? product.tags.includes("Weeks Deal") : true;
    const isBlackFridayDeal = showblackFridayDeals ? product.tags.includes("Black Friday") : true;
    const isShowTopSell = showTopsell ? product.tags.includes("Best Selling") : true;
    return (
      product.price >= priceRange[0] &&
      product.price <= priceRange[1] &&
      // (category === "All" || product.category === category) 
      (( selectedCategory.category === "All" || product.category === selectedCategory.category) &&
      ( !selectedCategory.subCategory || product.subCategory === selectedCategory.subCategory))
      &&
      (selectedBrands.length === 0 || selectedBrands.includes(product.brand)) &&
      calculateAverageRating(product.id) >= minRatingFilter &&
      hasDiscount && isTodayDeal && isWeekDeal && isBlackFridayDeal && isShowTopSell
    );
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    switch (sortOrder) {
      case "priceLowToHigh":
        return a.price - b.price;
      case "priceHighToLow":
        return b.price - a.price;
      case "recentToOld":
        return b.id - a.id;
      case "oldToRecent":
        return a.id - b.id;
      default:
        return 0;
    }
  });

  const displayedProducts = sortedProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const brands = [...new Set(products.map((product) => product.brand))];

  const handleBrandChange = (brand) => {
    setSelectedBrands((prevBrands) =>
      prevBrands.includes(brand)
        ? prevBrands.filter((b) => b !== brand)
        : [...prevBrands, brand]
    );
  };

  const toggleDiscountFilter = () => {
    setDiscountFilter((prev) => !prev);
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => (prevMode === 'grid' ? 'list' : 'grid'));
  };

  const handleRatingFilter = (rating) => {
    setMinRatingFilter((prev) => (prev === rating ? 0 : rating));
  };

  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const generatePaginationItems = () => {
    const paginationItems = [];
    // const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
  
    // Show first page, last page, current page, previous and next of current, and ellipsis as needed
    if (totalPages <= 5) {
      // If total pages are 5 or less, show all page numbers
      for (let number = 1; number <= totalPages; number++) {
        paginationItems.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    } else {
      // Show more complex pagination if there are more than 5 pages
      paginationItems.push(
        <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
  
      if (currentPage > 3) {
        paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" disabled />);
      }
  
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
  
      for (let number = startPage; number <= endPage; number++) {
        paginationItems.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
  
      if (currentPage < totalPages - 2) {
        paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" disabled />);
      }
  
      paginationItems.push(
        <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }
  
    return paginationItems;
  };
  const handleShowTodayDeals = () => {
    setShowTodayDeals((prev) => !prev); // Toggle TodayDeals view
  };
  const handelarweekDeal = () => {
    setShowWeekDeals((prev) => !prev); // Toggle TodayDeals view
  };
  const handelarBlackFridayDeal = () => {
    setShowblackFridayDeals((prev) => !prev); // Toggle TodayDeals view
  };
  

  const handleCategorySelection = (category) => {
    dispatch(setSelectedCategory(category));
  };

 const handleShowTopSell = () => {
  setshowTopsell((prev) => !prev); // Toggle TodayDeals view
};


  const handleSubcategorySelect = (subcategoryName) => {
    dispatch(setSelectedCategory({ ...selectedCategory, subCategory: subcategoryName }));
  };

  const resetFilters = () => {
    // Reset all the filters to their default states
    setSortOrder("recentToOld");
    setCategory("All");
    setSelectedBrands([]);
    setMinRatingFilter(0);
    setDiscountFilter(false);
    setPriceRange([0, maxPrice]);
    setShowTodayDeals(false);
    setShowWeekDeals(false);
    setshowTopsell(false);
    setShowblackFridayDeals(false)
    setCurrentPage(1);
    setItemsPerPage(6); // Reset items per page to default
    dispatch(setSelectedCategory({ category: "All", subCategory: null }));
    dispatch(setFilteredProducts(products)); // Reset to the original list of products
    dispatch(setSearchQuery("")); // Reset the search query
  };

  return (
    <>
      <Navigation />
      <section className="mt-31 filterWrapper">
        <div className="leftSidebarMenuFilter">
          <div className="productAllFilterOption">
            <div className=" mb-0">
                <div className="catagoryFilter">
                  <h4 className="mb-1">CATEGORY</h4>
                  <AllCatagory
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onSelectCategory={handleCategorySelection}
                    notClose={true}
                    setSelectedBrands={setSelectedBrands}
                  />
                </div>
               
                <div className="priceDivFilter mt-2">
                    <h4>Price</h4>
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="slider-thumb"
                      trackClassName="slider-track"
                      min={0}
                      step={8000}
                      max={maxPrice}
                      value={priceRange}
                      onChange={(range) => setPriceRange(range)}
                      // renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    />
                    <p className="priceshowDiv">Tk<span> {priceRange[0]}</span> - Tk<span> {priceRange[1]}</span> </p>

                    <div className="disToTop mt-3">
                        <div className="">
                            <Form.Check
                              type="checkbox"
                               label="Top sell"
                               onChange={handleShowTopSell}
                               checked={showTopsell}
                            />
                      </div>
                      <div className="todaydealsFilterOptions">
                          <Form.Check
                            type="checkbox"
                            checked={showTodayDeals}
                            onChange={handleShowTodayDeals}
                            label=  "Show Today's Deals"
                          />
                          
                 
                      </div>
                      <div className="discount-filter mb-0">
                        <Form.Check
                          type="checkbox"
                          label="Discounted Products"
                          checked={discountFilter}
                          onChange={toggleDiscountFilter}
                        />
                      </div>
                      <div className="discount-filter mb-0">
                        <Form.Check
                          type="checkbox"
                          label="Week Deals Products"
                          checked={showWeekDeals}
                          onChange={handelarweekDeal}
                        />
                      </div>
                      <div className="discount-filter mb-0">
                        <Form.Check
                          type="checkbox"
                          label="Black Friday Products"
                          checked={showblackFridayDeals}
                          onChange={handelarBlackFridayDeal}
                        />
                      </div>
                    </div>
                </div>
               
                <div className="brandingFilter mt-2">
                      <h4 className="mb-2">Brand</h4>
                     {filteredBrands
  .filter((brand) => brand !== null && brand !== undefined && brand !== "")
  .map((brand) => (
    <Form.Check
      key={brand}
      type="checkbox"
      label={brand}
      checked={selectedBrands.includes(brand)}
      onChange={() => handleBrandChange(brand)}
    />
  ))}
                </div>
                <div className="ratingFilter mt-2">
                    <h4  className="mb-2">Rating</h4>
                    <div className="d-flex">
                      <div style={{ cursor: "pointer" }}>
                      {[...Array(5)].map((_, i) => (
                        <span key={i} onClick={() => handleRatingFilter(i + 1)}>
                          {i < minRatingFilter ? <FaStar style={{ color: 'var(--ratingStar)' }} /> : <FaRegStar style={{ color: 'var(--ratingColorNone)' }} />}
                        </span>
                      ))}
                    </div>
                    {/* <p>Showing products with {minRatingFilter} star and up</p> */}
                    <p>&nbsp; & up</p>
                    </div>
                </div>

            </div>  
            
          </div>
        </div>
        <div className="rightBarProductShowFilter">
          <Container fluid>
           
              <Row>
                 <Col>
                   <div className="topbarFilterOptions d-flex align-items-center  w-100">
                    <div className="d-flex droptopbarokli gap-2">
                        <Dropdown>
                          <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {sortOrder === "priceLowToHigh"
                              ? "Price Low to High"
                              : sortOrder === "priceHighToLow"
                              ? "Price High to Low"
                              : sortOrder === "recentToOld"
                              ? "Recent to Old"
                              : "Old to Recent"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setSortOrder("priceLowToHigh")}>Price Low to High</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSortOrder("priceHighToLow")}>Price High to Low</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSortOrder("recentToOld")}>Recent to Old</Dropdown.Item>
                            <Dropdown.Item onClick={() => setSortOrder("oldToRecent")}>Old to Recent</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                          <Dropdown.Toggle variant="secondary" id="dropdown-items-per-page">
                            {itemsPerPage} items per page
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setItemsPerPage(6)}>6 items per page</Dropdown.Item>
                            <Dropdown.Item onClick={() => setItemsPerPage(12)}>12 items per page</Dropdown.Item>
                            <Dropdown.Item onClick={() => setItemsPerPage(18)}>18 items per page</Dropdown.Item>
                            <Dropdown.Item onClick={() => setItemsPerPage(24)}>24 items per page</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> 
                        <div className="resetfilter">
                            <button className="btn btn-primary" onClick={resetFilters}>Reset Filter</button>
                        </div>
                    </div>
                    <div className="gridclasicalView">
                       <Button onClick={toggleViewMode} variant="outline-secondary">
                        {viewMode === 'grid' ? <FaList /> : <FaTh />}
                      </Button>
                    </div>
                   
                 </div>
                 </Col>
              </Row>
             
            
            <div className="allProductShowing">
              <Row>
             
                
                {displayedProducts.length > 0 ? (
                  displayedProducts.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      viewMode={viewMode}
                      axliox={viewMode === "grid" ? 4 : 12}
                    />
                  ))
                ) : (
                    <p className="text-muted d-flex align-items-center justify-content-center" style={{ fontSize: "1.5rem", marginTop: "2rem" }}>
                      <img style={{width:"270px",height:'270px'}} src='https://i.postimg.cc/4dTS9n31/no-results-found.png' border='0' alt='no-results-found'/>
                      
                    </p>
                )}
                
              </Row>
            </div>
            <div className="displayedProductspagination">
              {displayedProducts.length >0 && (

              <Pagination className="justify-content-start mt-4">
              <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
              {generatePaginationItems()}
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
            )}

            </div>

          </Container>
        </div>
      </section>
    </>
  );
};

export default ProductCategory;
