import React, { createContext, useState, useEffect, useContext } from 'react';

// Create the context
const ScreenSizeContext = createContext();

// Provider component
const ScreenSizeProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider value={isMobile}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

// Custom hook to use the ScreenSizeContext
const useScreenSize = () => {
  const context = useContext(ScreenSizeContext);
  if (context === undefined) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider');
  }
  return context;
};

// Export both the context and provider
export { ScreenSizeProvider, useScreenSize, ScreenSizeContext };
