import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching slider data
export const fetchSliderData = createAsyncThunk(
  'slider/fetchSliderData',
  async () => {
    const response = await fetch("../api/heroSlider.json");
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  }
);

// Create a slice for the slider
const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    sliderData: [],
    loading: true,
    error: null,
  },
  reducers: {
    // You can add additional synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSliderData.fulfilled, (state, action) => {
        state.sliderData = action.payload;
        state.loading = false;
      })
      .addCase(fetchSliderData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sliderSlice.reducer;
