
import React, {useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { useScreenSize, ScreenSizeContext } from "../context/ScreenSizeContext";
import { fetchProducts, setSearchQuery, selectFilteredProducts, selectSearchResults } from "../redux/productSlice";
import LogSign from "./logSign";
import CartMenu from "./cartsMenu";
import HelplineLanguage from "./helplineLanguage";
import LoadingSpinner from "../component/loadingSpinner";
import { fetchNavigationData } from "../redux/navigationSlice"; // Import the action
import { fetchCustomerDetails } from "../redux/customerDetailsSlice";
import {
  BsSearch,
  BsUiRadiosGrid,
  BsHouseDoor,
  BsNut,
  BsNewspaper,
  BsListNested,
  BsPatchQuestion,
  BsTelephonePlus,
  BsChevronDown,
  BsGear,
} from "react-icons/bs";

import "../css/nav.css";
import NavMenu from "./navMenu";
import { Card } from "react-bootstrap";
import useProductActions from "../utils/productThanos";
import SearchResults from "./searchProduct";
import { useNavigate } from "react-router-dom";

// Icon mapping for dynamic icons
const iconMapping = {
  BsHouseDoor: <BsHouseDoor />,
  BsNut: <BsNut />,
  BsNewspaper: <BsNewspaper />,
  BsListNested: <BsListNested />,
  BsPatchQuestion: <BsPatchQuestion />,
  BsTelephonePlus: <BsTelephonePlus />,
  BsGear: <BsGear />,
};

const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useContext(ScreenSizeContext);
  const searchResults = useSelector(selectSearchResults);
  const filteredProducts = useSelector(selectFilteredProducts);
  const [searchInput, setSearchInput] = useState("");

  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchResultsRef = useRef(null); // Reference to search result div
  // Access the navigation state from Redux


  
  const { logo, menu, loading, error } = useSelector((state) => state.navigation);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
        setShowSearchResults(false); // Hide search results if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchNavigationData()); // Dispatch the async action to fetch navigation data
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    dispatch(setSearchQuery(e.target.value)); // Update search query in Redux
    setShowSearchResults(true); 
  
  };


  const handleSearchSubmit = () => {
    navigate("/productCategory"); // Navigate to Product Category page
  };


  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary searchbar-navbar" sticky="top">
        <Container fluid className="enterSearchBlockMobile">
          <div className="d-flex justify-content-between align-items-center w-100 gap-0 gap-md-3 gap-xl-4">
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo" />
            </Navbar.Brand>
              
            <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                  <Nav className="main-navbar-menu gap-1">
                      <NavMenu />
                  </Nav>
              </Navbar.Collapse>
            <Form className="d-flex w-100 search-block position-relative">
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchInput}
              onChange={handleSearchChange}
            />
                <Button variant="outline-success" className="searchbtnclick"  onClick={handleSearchSubmit}>
                  <BsSearch />
                </Button>
                {showSearchResults && (
                <div className="searchinputresult" ref={searchResultsRef}>
                  {searchResults.map(product => (
                      <SearchResults 
                        key={product.id} 
                        product={product} 
                      
                        
                      />
                    ))}
                  </div>
                   )}
              </Form>
            <div className="login-signin-cart d-flex align-items-center justify-content-center gap-4 gap-xl-4">
              {!isMobile && (
                <>
                 <CartMenu/>
                  <LogSign />
                </>
              )}
            </div>
          </div>
        </Container>
       
        
    
      </Navbar>
      {isMobile && 
      <Navbar expand="sm" className="bg-body-tertiary main-navbar-menu main-navbar-menu-all" fixed={isMobile ? "bottom" : "none"} >
        <Container fluid="md" className="enterSearchBlockMobile">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="d-flex align-items-center justify-content-center gap-2 w-100"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
             <NavMenu />
            {/* <HelplineLanguage /> */}
            </Nav>
          </Navbar.Collapse>
          
            <div className="login-signin-cart d-flex align-items-center justify-content-center gap-3 gap-md-5">
             <CartMenu/>
              <LogSign />
            </div>
         
        </Container>
      </Navbar>
}
    </>
  );
};

export default Navigation;


// const App = () => {
//   return (
//     <div>
//       <TawkTo />
//       {/* Other components */}
//     </div>
//   );
// };

// export default App;
