
const brlLink = "http://store-admin.scldev.com:8084";
// const brlLink = "http://127.0.0.1:8000";
export default {
    products:`${brlLink}/api/v1/products`,
    confirmOrder: `${brlLink}/api/v1/confirm-order`,
    confirmOrderOnline: `${brlLink}/api/v1/pay-ssl`,
    addAddress:`${brlLink}/api/v1/customer-profile/add-address`,
    logout:`${brlLink}/api/v1/customer-logout`,
    customersDetails:`${brlLink}/api/v1/customer-details`,
    // customersBaseurl:`http://store-admin.scldev.com:8084`,
    // customersBaseurl:`http://127.0.0.1:8000`,
    orderHistoryDetails:`${brlLink}/api/v1/customer-orders`,
    sslPayment:`${brlLink}/api/v1/pay-ssl`,
    verifiToken:`${brlLink}/api/v1/verify-token`,
    genarateOtp:`${brlLink}/api/v1/generate-otp`,
    setDefaultAddress:`${brlLink}/api/v1/set-default-address`,
    updateCustomer:`${brlLink}/api/v1/update-customer`,
    deleteAddress:`${brlLink}/api/v1/delete-address`,
    verifyOtp:`${brlLink}/api/v1/verify-otp`,
    weekdeals:`${brlLink}/api/v1/products/filter?tag=Weeks Deal`,
    latestProduct:`${brlLink}/api/v1/products/filter?tag=Latest Product`,
    topRating:`${brlLink}/api/v1/products/filter?tag=Top Rating`,
    bestSelling:`${brlLink}/api/v1/products/filter?tag=Best Selling`,
    feature:`${brlLink}/api/v1/products/filter?tag=Featured Product`,
    customerReviews:`${brlLink}/api/v1/customer-reviews`,
    updateCustomerPersonalnfo:`${brlLink}/api/v1/customer-profile/update-personal-info`,
    updateAddress:`${brlLink}/api/v1/customer-profile/edit-address`,
    defaultAddress:`${brlLink}/api/v1/customer-profile/address/set-default`,
    addPersonalInfo:`${brlLink}/api/v1/customer-profile/add-personal-info`,
    customerQnaHistory:`${brlLink}/api/v1/customer-qas`,
    customerPaymentsHistory:`${brlLink}/api/v1/customer/payments`
}; 
