



import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../component/navigation";
import { setLoginSuccess } from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../component/footer";
import '../css/signup.css'
import { Col, Container, Row } from "react-bootstrap";
import AllCatagory from "../component/allCatagory";
import { fetchProducts, selectProducts } from "../redux/productSlice";
import { selectCategory, setCategory, setSelectedCategory } from "../redux/categorySlice";
import PageTitleArea from "../component/PageTitleArea";

import { BsArrowRepeat } from "react-icons/bs";
import endpoints from "../api/endpoints";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector(selectProducts);
   const location = useLocation();
  const [input, setInput] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [showOtpField, setShowOtpField] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [counter, setCounter] = useState(120);
  const [show, setShow] = useState(true);
  const selectedCategory = useSelector(selectCategory);
  const [activeBox, setActiveBox] = useState('mobile');
 



  const [redirectPath, setRedirectPath] = useState("/account"); // Default to /account if no from state
  useEffect(() => {
    // Check if location.state is available and contains a from path
    if (location.state?.from) {
      setRedirectPath(location.state.from);
    }
   
  }, [location]);
  




  

    const [isSignUp, setIsSignUp] = useState(true);

  useEffect(() => {
    setIsSignUp(location.pathname === "/signup");
  }, [location.pathname]);

  const toggleMode = () => {
    const newPath = isSignUp ? "/signin" : "/signup";
    window.history.replaceState(null, "", newPath);
    navigate(newPath);
    setIsSignUp(!isSignUp); // Update the state immediately
  };






  const handleSwitchToEmail = () => setActiveBox('email');
  const handleSwitchToMobile = () => setActiveBox('mobile');
  

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching products
    dispatch(fetchProducts())
      .then(() => setLoading(false))  // On success, set loading to false
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);



  

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setErrors({});
  };

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  useEffect(() => {
    let timer;
    if (showOtpField && counter > 0) {
      timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [showOtpField, counter]);

  const validateInput = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^01[3-9]\d{8}$/;

    if (!input.trim()) {
      setErrors({ input: "This field is required" });
      return false;
    }

    if (!emailRegex.test(input) && !phoneRegex.test(input)) {
      setErrors({ input: "Please enter a valid email or phone number" });
      return false;
    }

    return true;
  };

  const sendOtp = async () => {
    if (!validateInput()) return;

    setLoading(true);
    try {
      const authMethod = input.includes("@") ? "email" : "mobile";
      const response = await axios.post(
        endpoints.genarateOtp,
        {
          user_id: input,
          auth_method: authMethod,
        }
      );

      console.log(response.data);
      setShowOtpField(true);
      setCounter(60);
    } catch (error) {
      console.error(error);
      setErrors({ input: "Validation failed, please try again." });
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!agreed) {
      setErrors({ agreed: "You must agree to the terms and conditions" });
      return;
    }

    const otpCode = otp.join("");
    if (otpCode.length !== 4) {
      setErrors({ otp: "Please enter the full 4-digit OTP" });
      return;
    }

    setLoading(true);
    // const fetchCSRFToken = async () => {
    //   try {
    //     await axios.get('http://store-admin.scldev.com:8084/sanctum/csrf-cookie',{ withCredentials: true });
    //   } catch (error) {
    //     console.error("Error fetching CSRF cookie:", error);
    //   }
    // };
    try {

    // await axios.get('/sanctum/csrf-cookie');
    // console.log("CSRF cookie set successfully");
    
    // await fetchCSRFToken();

      const response = await axios.post(
        endpoints.verifyOtp,
        {
          user_id: input,
          otp_code: otpCode,
        },
        { withCredentials: true }
      );

      const { access_token, customer } = response.data.data;
      if (access_token) {
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("user", customer.user_id);
        dispatch(
          setLoginSuccess({ accessToken: access_token, user: customer.user_id })
        );
        // navigate("/account");
        navigate(redirectPath);
      }
    } catch (error) {
      console.error(error);
      setErrors({ otp: "Invalid OTP, please try again." });
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    dispatch(setSelectedCategory(category)); // Set category in Redux
    navigate("/productCategory"); // Navigate to ProductCategory page
  };


  // Determine if the route is `/signup`
 


  return (
    <>
      <Navigation />
      <PageTitleArea />

    <div className="signSectionBlock">
      <Container className="h-100">
         <Row className="justify-content-center asdfdsf align-items-center h-100 rowesSign">
            <Col md={8}>
               <Row className="nextRow">
                   {/* <Col md={4}>
                     <div className="signupCatagory">
                       
                        
                            <AllCatagory
                              categories={categories}
                              selectedCategory={selectedCategory}
                              onSelectCategory={handleCategoryClick}
                              show={show}
                            />
                      </div>
                   </Col> */}
                   {/* <Col  md={8} >
                      <div className="signMidellContainer">
                        <h2>Sign Up</h2>
                          <form onSubmit={handleSignup}>
                            {!showOtpField && (
                              <div>
                                <label>Email Or Phone Number</label>
                                <div className="d-flex gap-2 inputPhpneForSignLog align-items-center">
                                  <input className="byDefaultCode"  value={'BD +88'} readOnly style={{width:'80px'}}/>
                                  <input
                                  type="text"
                                  value={input}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", padding: "8px" }}
                                  placeholder="01- - - - - - - - -"/>
                                </div>
                                
                                {errors.input && <p style={{ color: "red" }}>{errors.input}</p>}
                                <button
                                  type="button"
                                  onClick={sendOtp}
                                  className="singingButton btn btn-block"
                                >
                                  {loading ? "Sending OTP..." : "Send OTP"}
                                </button>

                                <p className="alreadyHAveAnAccount">Already have an account? <a href="/signup"> Log in Now</a></p>
                              </div>
                            )}

                            {showOtpField && (
                              <div className="otp-container">
                                {otp.map((digit, index) => (
                                  <input
                                    key={index}
                                    id={`otp-${index}`}
                                    type="text"
                                    value={digit}
                                    maxLength="1"
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    style={{ width: "40px", padding: "8px", margin: "5px" }}
                                  />
                                ))}
                                {errors.otp && <p style={{ color: "red" }}>{errors.otp}</p>}
                                <p>Time left: {counter} seconds</p>
                              </div>
                            )}

                            {showOtpField && (
                              <div style={{ margin: "10px 0" }}>
                                <input
                                  type="checkbox"
                                  checked={agreed}
                                  onChange={() => setAgreed(!agreed)}
                                  style={{ marginRight: "5px" }}
                                />
                                <label>
                                  I agree to the{" "}
                                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                                    privacy terms
                                  </a>
                                </label>
                                {errors.agreed && <p style={{ color: "red" }}>{errors.agreed}</p>}
                              </div>
                            )}

                            {showOtpField && (
                              <button
                                type="submit"
                                className="singingButton"
                                disabled={loading}
                              >
                                {loading ? "Signing up..." : "Verify OTP"}
                              </button>
                            )}
                          </form>
                    </div>
                   </Col> */}
                    <Col  md={12} >
                      <div className="signMidellContainer h-100">
                         <div className={`boxCenter ${activeBox === 'mobile' ? 'slide-in-left' : 'slide-out-right'}`}>
                        <h2>{isSignUp ? "Sign Up" : "Sign In"} with Mobile number</h2>
                          <form onSubmit={handleSignup}>
                            {!showOtpField && (
                              <div>
                                <label>{isSignUp ? "Sign Up" : "Sign In"} with your mobile number</label>
                                <div className="form-group d-flex gap-15 inputPhpneForSignLog align-items-center">
                                  <input className="byDefaultCode"  value={'BD +88'} readOnly style={{width:'80px'}}/>
                                  <input
                                  type="text"
                                  value={input}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", padding: "8px" }}
                                  placeholder="Phone Number"/>
                                </div>
                                
                                {errors.input && <p style={{ color: "red" }}>{errors.input}</p>}
                                <button
                                  type="button"
                                  onClick={sendOtp}
                                  className="singingButton btn btn-block"
                                >
                                  {loading ? "Sending OTP..." : "Send verification code"}
                                </button>
                                <div className="or"><span>OR</span></div>
                                <button className="singingButton btn btn-block mb-0" onClick={handleSwitchToEmail}>{isSignUp ? "Sign Up" : "Sign In"} With Email</button>
                              </div>
                            )}

                            {showOtpField && (
                              <div className="otp-container text-center">
                                <div className="otp-wrap flex-nowrap d-flex justify-content-center">
                                  {otp.map((digit, index) => (
                                    <input className="form-control"
                                      key={index}
                                      id={`otp-${index}`}
                                      type="text"
                                      value={digit}
                                      maxLength="1"
                                      onChange={(e) => handleOtpChange(index, e.target.value)}
                                    />
                                  ))}
                                </div>
                                {errors.otp && <p style={{ color: "red" }}>{errors.otp}</p>}
                                <p>Time left: {counter} seconds</p>
                              </div>
                            )}

                            {showOtpField && (
                              <div className="checkbox-container d-flex justify-content-center" style={{ margin: "10px 0" }}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={agreed}
                                    onChange={() => setAgreed(!agreed)}
                                    style={{ marginRight: "5px" }}
                                  />
                                  I agree to the{" "}
                                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                                    privacy terms
                                  </a>
                                </label>
                                {errors.agreed && <p style={{ color: "red" }}>{errors.agreed}</p>}
                              </div>
                            )}

                            {showOtpField && (
                              <button
                                type="submit"
                                className="singingButton"
                                disabled={loading}
                              >
                                {loading ? "Signing up..." : "Verify OTP"}
                              </button>
                            )}

                            {/* <div className="again-send-otp text-center"><a href="#">Send OTP Again</a></div> */}

                          </form>
                        </div>

                        <div className={`boxCenter ${activeBox === 'email' ? 'slide-in-right' : 'slide-out-left'}`}>
                          <h2>{isSignUp ? "Sign Up" : "Sign In"} with email</h2>
                          <form>
                            <label>{isSignUp ? "Sign Up" : "Sign In"} with your Email</label>
                              <div class="inputPhpneForSignLog form-group d-flex flex-wrap clockwise-wrap">
                                <div className="col-12"><input className="form-control" type="email" placeholder="Your Email" name=""/></div>
                              </div>
                              <div class="inputPhpneForSignLog form-group d-flex flex-wrap clockwise-wrap">
                                <div className="col-12"><input className="form-control" type="password" placeholder="Password" name=""/></div>
                              </div>
                              <div class="inputPhpneForSignLog form-group d-flex flex-nowrap clockwise-wrap mb-0">
                                  <div className="col-6"><input className="form-control" type="text" placeholder="captcha" name=""/></div>
                                  <div className="col-4"><input readOnly className="form-control" type="text" placeholder="5858A" name=""/></div>
                                  <div className="col-2 ">
                                    <BsArrowRepeat />
                                  </div>
                              </div>
                              <button className="singingButton btn btn-block">{isSignUp ? "Sign Up" : "Sign In"}</button>
                              <div className="or"><span>OR</span></div>
                              <button type="button" className="singingButton btn btn-block mb-0" onClick={handleSwitchToMobile}>{isSignUp ? "Sign Up" : "Sign In"} with mobile</button>
                              {isSignUp ? "" : <p className="alreadyHAveAnAccount mt-2"><a href="/forgotpassword "> Forgot Password </a></p>} 
                          </form>
                        </div>
                         {showOtpField ? (<><p></p></>): (<>
                          <p className="alreadyHAveAnAccount mt-3">
                              {isSignUp ? "Already have an account?" : "No Account?"}{" "}
                              <button
                                type="button"
                                className="toggle-button"
                                onClick={toggleMode}
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "blue",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                {isSignUp ? "Sign In" : "Sign Up"} now
                              </button>
                            </p>
                         </>)  }   
                    </div>
                   </Col>
               </Row>
            </Col>
        </Row>
      </Container>
    </div>
    
    <br></br> <br></br> 
      <Footer />
    </>
  );
};

export default SignIn;

