import React, { useEffect } from 'react';
import { Col, Container, Row ,Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const OfferCard = () => {
  

  return (
    <>
     <section className='offercard'>
     <br></br><br></br>
        <Container>
            <Row>
                <Col md={12}>
                   <div className='offercard-block'>
                        <Link to='/product/32'><Image  src='../imgs/offer/ad_banner.jpg' fluid  /></Link>
                    </div> 
                </Col>
            </Row>
        </Container>
       
     </section>
      
    </>
  )
};

export default OfferCard;