// // AddressSelect.js
// import React from 'react';
// import Select from 'react-select';
// import { BsFillPlusCircleFill } from 'react-icons/bs';

// const AddressSelect = ({ 
//   addresses, 
//   type, 
//   onAddNew, 
//   onSelect, 
//   selectedValue,
//   isBilling = false
// }) => {
//   const options = [
//     {
//       label: (
//         <button
//           style={{
//             all: "unset",
//             cursor: "pointer",
//             color: "rgb(246,140,30)",
//             fontWeight: "bold"
//           }}
//           onClick={onAddNew}
//         >
//           <BsFillPlusCircleFill style={{ marginRight: "5px" }} />
//           {`Add New ${type} Address`}
//         </button>
//       ),
//       value: "addNew",
//       isDisabled: true,
//     },
//     ...(isBilling ? [{
//       label: (
//         <p
//           style={{
//             cursor: "pointer",
//             color: "rgb(246,140,30)",
//             fontWeight: "bold"
//           }}
//         >
//           Same as Delivery Address
//         </p>
//       ),
//       value: "same",
//     }] : []),
//     ...addresses.map((addr, index) => ({
//       value: index,
//       label: `${addr.is_default ? "[Default] " : ""}${type} To ${addr.deliver_to} - ${addr.street_no}, ${addr.zip_code}`,
//     })),
//   ];

//   return (
//     <Select
//       options={options}
//       value={options.find(opt => opt.value === selectedValue)}
//       onChange={onSelect}
//       placeholder={`Select a ${type.toLowerCase()} address`}
//     />
//   );
// };

// export default AddressSelect;





import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from "react-bootstrap";
import LoadingSpinner from "./loadingSpinner";
import { fetchReviewHistory } from "../redux/reviewHistorySlice";
import '../css/reviewHistory.css'
import useProductActions from "../utils/productThanos";
import { useNavigate } from "react-router-dom";
import { fetchQnaHistory } from "../redux/qnaHistorySlice";
import Paginationsorq from "./paginationsorq";

const QnaHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { qnashistory, status, error } = useSelector((state) => state.qnaHistory);
  const { currentPage, itemsPerPage } = useSelector((state) => state.pagination);
  const indexOfLastQna = currentPage * itemsPerPage;
  const indexOfFirstQna = indexOfLastQna - itemsPerPage;
  const currentqnashistory = qnashistory.slice(indexOfFirstQna, indexOfLastQna);
  useEffect(() => {
    if (status === 'idle' && accessToken) {
      dispatch(fetchQnaHistory(accessToken));
    }
  }, [status, accessToken, dispatch]);

  if (status === 'loading') return <LoadingSpinner />;

  if (status === 'failed') {
    return <div className="alert alert-danger m-4">Error: {error}</div>;
  }

  return (
    <div className="mt-4 container personalreviewHistory">
      {currentqnashistory?.length === 0 ? (
        <div className="reviewqnaorderhistory">
        <img src="../imgs/noquestion.gif" alt="No questions yet." />
       </div>
      ) : (
       <Row>
         <div className="card-columns" >
          {(currentqnashistory || []).map((qnas) => (
           
              
                  <Card className="w-100 mb-2 p-1" key={qnas.qa_id}  onClick={() => navigate(`/product/${qnas.product_id}`)}
                  style={{ cursor: 'pointer' }}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') navigate(`/product/${qnas.product_id}`)
                  }}
                >
                    <Card.Body>
                      <div className="">
                        <img 
                          src={qnas.image} 
                          alt={qnas.product_name}
                          style={{ width: '100px', marginRight: '15px' }}
                          onError={(e) => {
                            e.target.onerror = null; 
                            e.target.src = '/fallback-image.jpg';
                          }}
                        />
                        <div>
                          <Card.Title>{qnas.product_name}</Card.Title>
                          
                           <Card.Text style={{marginBottom:'0px',marginTop:'5px'}} ><span style={{fontWeight:'600',opacity:'1',color:'#000'}}>Your Question:</span> {qnas.question}</Card.Text>

                           <p style={{ wordBreak: 'break-all', margin: '0', fontWeight: '500', marginBottom: '10px', fontSize: '13px', opacity: '0.9' }}>
  <span style={{ fontWeight: '600', opacity: '1', color: '#000' }}>Answer: </span> 
  {qnas.answer === null ? <span style={{color:'#f07777'}}>No Ans Yet</span> : <span>{qnas.answer}</span>}
</p>

                          <Card.Subtitle className="text-muted reviewby">
                            Asking Name: {qnas.name_published}
                          </Card.Subtitle>
                          
                         
                          {/* <div className="text-muted small">
                            Price: Tk {review.price.toLocaleString('en-BD')}
                          </div> */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
           
           
          ))}
           </div>
           
         </Row>
      )}
       <Paginationsorq totalItems={qnashistory.length} />
      <br></br>
    </div>
  );
};

export default QnaHistory;