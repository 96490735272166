
import { useDispatch } from 'react-redux';
import { Card, Button, Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { closeWishlist } from '../redux/cartWishSlice';
import { BsHeart, BsHeartFill, BsXCircleFill, BsXLg } from "react-icons/bs";
import useProductActions from '../utils/productThanos';

const WishlistSidebar = () => {
  const dispatch = useDispatch();
  const {wishlistItems,isAddedW,isWishlistOpen, handleAddToCartFromWishlist,handleClearWish,handleRemoveWishlist } = useProductActions(); 

  if (!isWishlistOpen) return null;

  return (
    <div className={`wishlist-sidebar ${isWishlistOpen ? 'open' : ''}`}>
      <div className="wishlist-sidebar-header cartwishHeader">
        <h2>Your Wishlist</h2>
        <button onClick={() => dispatch(closeWishlist())}><BsXLg /></button>
      </div>
      {wishlistItems.length === 0 ? (
        <div className='emptyCart'>
         <img src='../../imgs/Shopping_Cart.gif' alt="Empty Wishlist" />
         <p>Your wishlist is empty</p>
       </div>
      ) : (
        <Row className='scrollbar scrollbarWishlist'>
          
          {wishlistItems.map((product) => (
            <Col md={12} xs={12} key={product.id} className="mb-12">
              <Card className="productCard d-flex productCardItem">

                <Card.Img variant="top" src={product.image} />

                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>Tk {product.orderPrice.toFixed(2)}</Card.Text>
                  <div className='d-flex flex-nowrap btn-gaps mt-2'>
                    <Button className='btn btn-primary btn-sm wishListProductCartbtn'
                      variant="primary"
                      onClick={() => handleAddToCartFromWishlist(product)}
                    >
                      Add to Cart
                    </Button>
                  
                    <button 
                    className={`wishlist-btn btn btn-danger btn-sm ${isAddedW ? 'wishlisted' : 'removeFromWish'}`} 
                    onClick={()=>handleRemoveWishlist(product)}
                  >
                    {isAddedW ? <BsHeartFill /> : <BsHeart />}  <BsXCircleFill />  {isAddedW ? 'Wishlisted' : ''}
                  </button>
                </div>
                </Card.Body>

              </Card>
            </Col>
          ))}
          <div className='productCartButton'>
            <button className='btn btn-danger btn-sm w-100' onClick={handleClearWish}> Clear Your Wish</button>
          </div>
        </Row>
      )}
    </div>
  );
};

export default WishlistSidebar;
