

// import React from "react";
// import { Dropdown } from "react-bootstrap";

// const AllCatagory = ({ categories, selectedCategory, onSelectCategory ,show,notClose}) => {
//   const getDropdownLabel = () => {
//     if (selectedCategory.category === "All") return "All Categories";
//     if (selectedCategory.subcategory) return selectedCategory.subcategory;
//     return selectedCategory.category;
//   };
//   return (
//     <Dropdown show={show}>
//        {show==true? 
//            (<>
//            <p></p>
//           </>):
//           (
//             <>
//             <Dropdown.Toggle variant="primary" id="category-dropdown">
//         {/* {selectedCategory.category === "All" ? "All Categories" : selectedCategory.category} */}
//         {getDropdownLabel()}
//       </Dropdown.Toggle>
//             </>
//           )
//         }
//       <Dropdown.Menu data-bs-auto-close={notClose==true? 'inside' :"" } >
//         <Dropdown.Item onClick={() => onSelectCategory({ category: "All" })}>
//           All Categories
//         </Dropdown.Item>
//         {categories.map((cat) => (
//           <Dropdown.Item key={cat.name}>
//             <span onClick={() => onSelectCategory({ category: cat.name })}>
//               {cat.name}
//             </span>
//             {cat.subcategories.length > 0 && (
//               <Dropdown.Menu show>
//                 {cat.subcategories.map((subcat) => (
//                   <Dropdown.Item
//                     key={subcat}
//                     onClick={() => onSelectCategory({ category: cat.name, subcategory: subcat })}
//                   >
//                     {subcat}
//                   </Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             )}
//           </Dropdown.Item>
//         ))}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default AllCatagory;



// import React, { useState } from "react";
// import { Dropdown } from "react-bootstrap";

// const AllCatagory = ({ categories, selectedCategory, onSelectCategory }) => {
//   const [showDropdown, setShowDropdown] = useState(false);

//   const handleToggle = () => {
//     setShowDropdown((prevState) => !prevState);
//   };

//   const handleItemClick = (category) => {
//     onSelectCategory(category); // Perform the category selection
//   };

//   const getDropdownLabel = () => {
//     if (selectedCategory.category === "All") return "All Categories";
//     if (selectedCategory.subcategory) return selectedCategory.subcategory;
//     return selectedCategory.category;
//   };

//   return (
//     <Dropdown show={showDropdown}>
//       <Dropdown.Toggle
//         variant="primary"
//         id="category-dropdown"
//         onClick={handleToggle}
//       >
//         {getDropdownLabel()}
//       </Dropdown.Toggle>
//       <Dropdown.Menu>
//         <Dropdown.Item onClick={() => handleItemClick({ category: "All" })}>
//           All Categories
//         </Dropdown.Item>
//         {categories.map((cat) => (
//           <Dropdown.Item key={cat.name}>
//             <span onClick={() => handleItemClick({ category: cat.name })}>
//               {cat.name}
//             </span>
//             {cat.subcategories.length > 0 && (
//               <Dropdown.Menu show>
//                 {cat.subcategories.map((subcat) => (
//                   <Dropdown.Item
//                     key={subcat}
//                     onClick={() =>
//                       handleItemClick({ category: cat.name, subcategory: subcat })
//                     }
//                   >
//                     {subcat}
//                   </Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             )}
//           </Dropdown.Item>
//         ))}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default AllCatagory;


// import React, { useState } from "react";
// import { Dropdown } from "react-bootstrap";
// import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Icon imports

// const AllCatagory = ({ categories, selectedCategory, onSelectCategory, notClose }) => {
//   const [openSubcategories, setOpenSubcategories] = useState({});
//   const [showDropdown, setShowDropdown] = useState(true);
//   const [selectedSubcategory, setSelectedSubcategory] = useState(null); // State for selected subcategory

//   const handleCategoryClick = (categoryName) => {
//     setOpenSubcategories((prevState) => ({
//       ...prevState,
//       [categoryName]: !prevState[categoryName],
//     }));
//   };

//   const handleItemClick = (category) => {
//     setSelectedSubcategory(category.subcategory || category.category); // Track selected item
//     onSelectCategory(category); // Perform the category selection
//   };

//   const handleToggleDropdown = () => {
//     setShowDropdown(!showDropdown); // Toggle the main dropdown
//   };

//   const getDropdownLabel = () => {
//     if (selectedCategory.category === "All") return "All Categories";
//     if (selectedCategory.subcategory) return selectedCategory.subcategory;
//     return selectedCategory.category;
//   };

//   return (
//     <Dropdown show={showDropdown} autoClose={notClose === true ? "outside" : ""}>
//       <Dropdown.Toggle
//         variant="primary"
//         id="category-dropdown"
//         onClick={handleToggleDropdown}
//       >
//         {/* {getDropdownLabel()} */}
//         <Dropdown.Item onClick={() => handleItemClick({ category: "All" })} className={showDropdown ? "allcatagrousf" : "allcatagrousfnone"}>
//           All Categories
//         </Dropdown.Item>
//         <span>
//           {showDropdown ? <FaChevronUp /> : <FaChevronDown />}
//         </span>
//       </Dropdown.Toggle>
//       <Dropdown.Menu>
//         {categories.map((cat) => (
//           <Dropdown.Item key={cat.name}>
//             <span
//               onClick={() => handleCategoryClick(cat.name)}
//               style={{ display: "flex", alignItems: "center" }}
//             >
//               {cat.name}
//               {cat.subcategories.length > 0 && (
//                 <span
//                   style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}
//                 >
//                   {openSubcategories[cat.name] ? (
//                     <FaChevronUp />
//                   ) : (
//                     <FaChevronDown />
//                   )}
//                 </span>
//               )}
//             </span>
//             {cat.subcategories.length > 0 && openSubcategories[cat.name] && (
//               <Dropdown.Menu show className="dropParentstems">
//                 {cat.subcategories.map((subcat) => (
//                   <Dropdown.Item
//                     key={subcat}
//                     onClick={() =>
//                       handleItemClick({
//                         category: cat.name,
//                         subcategory: subcat,
//                       })
//                     }
//                     className={`dropChildItems ${
//                       selectedSubcategory === subcat ? "selected" : ""
//                     }`} // Add a 'selected' class conditionally
//                   >
//                     {subcat}
//                   </Dropdown.Item>
//                 ))}
//               </Dropdown.Menu>
//             )}
//           </Dropdown.Item>
//         ))}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default AllCatagory;



import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const AllCatagory = ({ categories, selectedCategory, onSelectCategory, notClose,setSelectedBrands }) => {
  const [openSubcategories, setOpenSubcategories] = useState({});
  const [showDropdown, setShowDropdown] = useState(true);
const [selectedSubcategory, setSelectedSubcategory] = useState(null)
  const handleCategoryClick = (categoryName) => {
    setOpenSubcategories((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
   
  };

  const handleItemClick = (category) => {
    setSelectedSubcategory(category.subCategory || category.category);
    onSelectCategory(category); // Trigger selection
     setSelectedBrands([]);
  };

  const getDropdownLabel = () => {
    if (selectedCategory.category === "All") return "All Product";
    if (selectedCategory.subCategory) return selectedCategory.subCategory;
    return selectedCategory.category;
  };

  return (
    <Dropdown show={showDropdown} autoClose={notClose === true ? "outside" : ""}>
      <Dropdown.Toggle
        variant="primary"
        id="category-dropdown"
        onClick={() => setShowDropdown(!showDropdown)}
      >
       
        <Dropdown.Item onClick={() => handleItemClick({ category: "All" })} className={showDropdown ? "allcatagrousf" : "allcatagrousfnone"}>
          {getDropdownLabel()} 
        </Dropdown.Item>
        <span>{showDropdown ? <FaChevronUp /> : <FaChevronDown />}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
    
        {categories.map((cat) => (
          <div key={cat.name}>
            <Dropdown.Item
              onClick={() => handleCategoryClick(cat.name)}
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between",    padding: '5px 0px' }}
            >
              {cat.name}
              {cat.subcategories.length > 0 && (
                <span>{openSubcategories[cat.name] ? <FaChevronUp /> : <FaChevronDown />}</span>
              )}
            </Dropdown.Item>
            {cat.subcategories.length > 0 && openSubcategories[cat.name] && (
              <Dropdown.Menu  className="dropParentstems">
                {cat.subcategories.map((subcat) => (
                  <Dropdown.Item
                    key={subcat}
                    onClick={() =>
                      handleItemClick({
                        category: cat.name,
                        subCategory: subcat,
                      })
                    }
                    className={`dropChildItems ${
                      selectedSubcategory === subcat ? "selected" : ""
                    }`} // Add a 'selected' class conditionally
                  >
                    {subcat}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AllCatagory;
