import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../api/endpoints";

export const fetchReviewHistory = createAsyncThunk(
  "reviewHistory/fetchReviewHistory",
  async (accessToken, { rejectWithValue }) => {
    try {
      //  axios.defaults.withCredentials = true
      const response = await axios.get(endpoints.customerReviews, {
        headers: { Authorization: `Bearer ${accessToken}`},
       
      });
      return response.data.data.customer_reviews;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch");
    }
  }
);

const reviewHistorySlice = createSlice({
  name: "reviewHistory",
  initialState: { 
    reviewshistory: [], 
    status: "idle", 
    error: null
   },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReviewHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.reviewshistory = action.payload || [];  // <-- Ensure it's always an array
       
      })
      .addCase(fetchReviewHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default reviewHistorySlice.reducer;
