import React, { useEffect, useState } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import { BsHandThumbsUp,BsCashCoin,BsCoin,BsTruck, BsRocket, BsHeadset   } from "react-icons/bs";


import "../css/facilities.css";

const Facilities = () => {
  return (
    <>
      <section className="mt-5 facilities">
        <Container>
          <div className="facilities_block">
            <Row className="d-flex align-items-center">
              <Col lg={3} sm={6}>
                <div className="facilitiesCard">
                  <Card>
                    <div className="facilitiesImg">
                        <BsHandThumbsUp />
                    </div>
                    <div className="facilitiesCardDes">
                      <h3>100%<br></br>Money Guarantee</h3>
                    <p>20 days money back</p>
                    </div>
                  </Card>
                  
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="facilitiesCard">
                  <Card >
                    <div className="facilitiesImg" style={{color:'rgb(250, 152, 81)'}}>
                    
                    <BsRocket />
                    </div>
                    <div className="facilitiesCardDes">
                    <h3>Fast & Free <br></br>Shipping</h3>
                    <p>on all orders $99</p>
                    </div>
                  </Card>
                  
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="facilitiesCard">
                  <Card>
                    <div className="facilitiesImg" style={{color:'rgb(86, 162, 59)'}}>
                        <BsTruck  />
                    </div>
                    <div className="facilitiesCardDes" >
                    <h3>Safe <br></br>Shopping</h3>
                    <p>Safe Shopping Guarantee</p>
                    </div>
                  </Card>
                  
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="facilitiesCard">
                  <Card className="borderleft">
                    <div className="facilitiesImg" >
                    <BsHeadset />
                    </div>
                    <div className="facilitiesCardDes">
                    <h3>Online <br></br>Support</h3>
                    <p>24/7 on Week</p>
                    </div>
                  </Card>
                  
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};
export default Facilities;
