import React, { useContext, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Navigation from "../component/navigation";
import { fetchOrderHistory } from '../redux/orderHistorySlice';
import { Accordion, Button, Modal, Pagination, Row, Table } from "react-bootstrap";
import  "../css/orderHistory.css";
import { BsBoxFill, BsBoxSeam, BsCarFrontFill, BsFillTelephoneFill, BsHandThumbsUp, BsHandThumbsUpFill, BsJournalText, BsTruck, BsWhatsapp, BsXLg } from "react-icons/bs";
import LoadingSpinner from "./loadingSpinner";
import Paginationsorq from "./paginationsorq";
const OrderHistory = () => {
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.auth);
    const { orders, status, error } = useSelector((state) => state.orderHistory);
    const { currentPage, itemsPerPage } = useSelector((state) => state.pagination);


    useEffect(() => {
      if (status === 'idle' && accessToken)  {
        dispatch(fetchOrderHistory(accessToken));
      }
    }, [status,accessToken,dispatch]);
  

    const [showCencel, setCencelShow] = useState(false);

    const handleCencelClose = () => setCencelShow(false);
    const handleCencelShow = () => setCencelShow(true);



    const ordersPerPage = 5;

    const indexOfLastOrder = currentPage * itemsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

   



    if (status === 'loading') {
      return <LoadingSpinner />;
    }
  
    if (status === 'failed') {
      return <p>Error: {error}</p>;
    }
  
  // console.log("Login Success:", loginSuccess);
  return (
    <>

    <div className="mt-2">
    {currentOrders?.length === 0 ? (
         <div className="reviewqnaorderhistory">
         <img src="../imgs/noorder.gif" alt="No reviews available" />
         </div>
      ) : (
      <Row>
      
      {(currentOrders || []).map((order) => (
        <div key={order.order_id} className="orderhistory">
          <div className="order_id_status d-flex justify-content-between align-items-center">
             <h5>Order ID: {order.order_id}</h5>
             <p className={order.order_status === "Cancel"  ? "confirmed" : "allsda"}>
              {
                 order.order_status === "Confirmed" && (
                  <p><BsJournalText /></p>
                 )
              }
              {
                 order.order_status === "Shipping" && (
                  <p><BsCarFrontFill /></p>
                 )
              }
               {
                 order.order_status === "Approved" && (
                  <p> <BsBoxFill /></p>
                 )
              }
        
                {
                 order.order_status === "Delivered" && (
                  <p><BsHandThumbsUpFill /></p>
                 )
              }
              {
                 order.order_status === "Cancel" && (
                  <p><BsXLg />   </p>  
                 )
              }
             {/* <span style={{fontWeight:'400'}}>Status:</span> */}
               {order.order_status}
               <br></br>
               {
                order.order_status === "Cancel" ||  order.order_status === "Shipping" ||  order.order_status === "Delivered"?
                  '' : (
                    <span className="order-cencel-btn"><button className="btn btn-primary " onClick={handleCencelShow}>Cencel Order ?</button></span>
                 )
               }
              
             </p>
          </div>
          
          <p className="ordertypeall"><span> Order Date:</span> {order.order_date}</p>
          
          <p className="ordertypeall"><span> Grand Total:</span> Tk {order.grand_total}</p>
          <p className="ordertypeall"><span> Payment Type:</span> {order.payment_type}</p>
          {/* {
            order.payment_type === "Online Payment" &&( */}
                <p className="ordertypeall" style={{color:'rgb(106, 177, 112)'}}><span style={{color:'rgb(106, 177, 112)'}}>Total Paid:</span> Tk {order.grand_total===order.payment_info.total_due ? "0 Tk" : order.payment_info.total_paid }</p>
                {!(order.payment_info.total_discount ==null || order.payment_info.total_discount ===0)  && (
                  <p className="ordertypeall" style={{color:'rgb(106, 177, 112)'}}><span style={{color:'rgb(106, 177, 112)'}}>Total Discount:</span> Tk {order.payment_info.total_discount} </p>
                
               )}
                <p className="ordertypeall">{order.payment_info.is_full_paid === true ? (
                  <>
                  <p style={{color:'rgb(106, 177, 112)'}}><span style={{color:'rgb(106, 177, 112)'}}>Total Due:</span> { 'You Have No Due'}</p>
                  </>
           
                ) :(
                  <>
                  <p style={{color:'var(--CommonColor)'}}><span style={{color:'var(--CommonColor)'}}>Total Due:</span> {`Tk ${order.payment_info.total_due}` }</p>
                  </>
                  
                )  } </p>
            
               
             
            {/* )
          } */}
          
          <Accordion defaultActiveKey="0" className="mt-2 yourOrderProdect">
              <Accordion.Item >
                <Accordion.Header>Your Orderd Product:</Accordion.Header>
                <Accordion.Body>
                   <Table responsive>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th style={{textAlign:'center'}}>Product Code</th>
                          <th style={{textAlign:'center'}}>Quantity </th>
                           <th style={{textAlign:'end'}}>Unit Price (Tk)</th>
                          <th style={{textAlign:'end'}}>Line Total  (Tk)</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                      {order.order_lines.map((line) => (
                      <tr key={line.product_id}>
                          <td>{line.product_name} </td>
                          <td style={{textAlign:'center'}}>{line.product_code}</td>
                          <td style={{textAlign:'center'}}>{line.quantity} </td>
                          <td style={{textAlign:'end'}}>{line.unit_price} </td>
                          <td style={{textAlign:'end'}}>{line.unit_price * line.quantity} </td>
                          
                      </tr>
                    ))}    
                      </tbody>
                    </Table>
                   
                </Accordion.Body>
              </Accordion.Item>
           </Accordion>
          

        </div>
      ))}
 </Row>
      )}
<Paginationsorq totalItems={orders.length} />
    </div>
   
<br></br>

<Modal show={showCencel} onHide={handleCencelClose} className="callwppforprice">
      <Modal.Header closeButton>
          <Modal.Title>Connect For Cencel Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
            <a href="https://wa.me/1234567890" target="_blank" rel="noopener noreferrer">
            <BsWhatsapp /> +88 012345789 Message on WhatsApp
          </a>
            </p>
            <p>
            <a href="tel:(+880) 2-48113179">
            <BsFillTelephoneFill />  (+880) 2-48113179 Derect Call
          </a>
            </p>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default OrderHistory;
