// redux/customerDetailsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../api/endpoints";

// Thunk for fetching customer details
export const fetchCustomerDetails = createAsyncThunk(
  "customerDetails/fetchCustomerDetails",
  async (accessToken, { rejectWithValue }) => {
    // const fetchCSRFToken = async () => {
    //   try {
    //     await axios.get('http://store-admin.scldev.com:8084/sanctum/csrf-cookie', { withCredentials: true });
    //   } catch (error) {
    //     console.error("Error fetching CSRF cookie:", error);
    //   }
    // };
    try {
      // await fetchCSRFToken();

      const response = await axios.get(endpoints.customersDetails, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      return response.data.data.customer; // Adjust based on live server's response format
    } catch (error) {
      console.error("Error fetching customer details:", error);
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);


const customerDetailsSlice = createSlice({
  name: "customerDetails",
  initialState: {
    customer: [],
    status: "idle", // idle, loading, succeeded, failed
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customer = action.payload;
      })
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default customerDetailsSlice.reducer;
