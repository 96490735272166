import React, { useEffect, useState } from "react";

import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { BsFacebook, BsInstagram, BsYoutube, BsLinkedin } from "react-icons/bs";
import { openCart, openWishlist } from '../redux/cartWishSlice';
import "../css/footer.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const { isCartOpen, isWishlistOpen } = useSelector((state) => state.cartWish);
   const dispatch = useDispatch();
  const handleOpenCart = () => {
    if (!isCartOpen) {
      dispatch(openCart());
    }
  };

  const handleOpenWishlist = () => {
    if (!isWishlistOpen) {
      dispatch(openWishlist());
    }
  };

  return (
    <>
      <section className="mt-5 footer">
        <Container>
          <Row>
            <Col md={4}>
              <div className="footersub1">
                <h4>CONTACT INFOMATION</h4>
                <p>Call Us 24/7 </p>
                
                <p><a  href='tel:(+880) 2-48113179'>(+880) 2-48113179 </a></p>
                <p>info@saffroncorporation.com.bd</p>
                <p>Rupayan Shelford Tower, Floor-11, F-10, Plot- 23/6, Shyamoli,
                Dhaka - 1207, Bangladesh</p>
              </div>
            </Col>
            <Col md={2}>
              <div className="footersub2 footersuball">
                <h4>COMPANY</h4>
                <p>
                  <Link to='/about'>About Us</Link>
                </p>
                <p>
                  <Link to='/productCategory'>Shop Products</Link> 
                </p>
                <p onClick={handleOpenCart}>
                  <span>My Cart</span>
                </p>
               
                <p>
                  <a href="">Contact Us</a>
                </p>
               
              </div>
            </Col>
            <Col md={2}>
              <div className="footersub3 footersuball">
                <h4>EXPLORE</h4>
                
                
                <p>
                  <a href="">F.A.Q.’s</a>
                </p>
                <p>
                  <a href="">Privacy Policy</a>
                </p>
                <p>
                  <a href="">Store Location</a>
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="footersub4 footersuball">
                <h4 className="text-start">OUR LOCATION</h4>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.256159968574!2d90.36366777402756!3d23.773890887876618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0afe5d038f5%3A0xcb48f980676a73ee!2sRupayan%20Shelford%2C%20Plot%20No%20%23%2023%2F6%2C%20Block%20%23B%2C%20Mirpur%20Rd%2C%20Dhaka%201207!5e0!3m2!1sen!2sbd!4v1740638855368!5m2!1sen!2sbd" style={{width:"100%",border:"0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="paymentoptionList">
        <Container>
          <Row>
            <Col md={12}>
              <Card>
                <div className="payulske">
                  Copyright © 2025 Saffron. All rights reserved.
                </div>
                <div className="allpaymentCard">
                  <ul className="p-0 m-0">
                    <li>
                       <a href=""><img src="../imgs/footer/SSLCOMMERZ.png" /></a>
                    </li>
                    
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Footer;
