
// import { createSlice } from '@reduxjs/toolkit';
// import { createSelector } from 'reselect';

// // Initial state
// const initialState = {
//   additionalReviews: {}, // { productId: [review1, review2, ...] }
// };

// // Create slice
// const reviewSlice = createSlice({
//   name: 'reviews',
//   initialState,
//   reducers: {
//     addReview: (state, action) => {
//       const { productId, newReview } = action.payload;
//       if (!state.additionalReviews[productId]) {
//         state.additionalReviews[productId] = [];
//       }
//       state.additionalReviews[productId].push(newReview);
//     },
//     updateProductReviews: (state, action) => {
//       const { productId, newReview } = action.payload;
//       const productReviews = state.additionalReviews[productId] || [];
//       const isDuplicate = productReviews.some(
//         (review) => review.review === newReview.review && review.PersonRating === newReview.PersonRating
//       );
//       if (!isDuplicate) {
//         productReviews.push(newReview);
//         state.additionalReviews[productId] = productReviews; // This maintains the reference to avoid unnecessary re-renders
//       }
//     },
//   },
// });

// // Action creators
// export const { addReview, updateProductReviews } = reviewSlice.actions;

// // Memoized selector to get combined reviews by product ID
// export const selectCombinedReviewsByProductId = createSelector(
//   (state, productId) => state.products.products.find((prod) => prod.id === productId)?.reviewsList || [],
//   (state, productId) => state.reviews.additionalReviews[productId] || [],
//   (productReviews, additionalReviews) => {
//     // Return early if both are empty arrays to avoid unnecessary re-renders
//     if (productReviews.length === 0 && additionalReviews.length === 0) return [];
    
//     // Check if productReviews and additionalReviews are already the same arrays as before
//     if (productReviews.length === 0) return additionalReviews;
//     if (additionalReviews.length === 0) return productReviews;
    
//     // Combine productReviews and additionalReviews only if they have changed
//     return [...productReviews, ...additionalReviews];
//   }
// );
// // Memoized selector to get average rating
// export const selectAverageRating = createSelector(
//   (state, productId) => selectCombinedReviewsByProductId(state, productId),
//   (combinedReviews) => {
//     if (combinedReviews.length === 0) return 0;
//     const totalRating = combinedReviews.reduce((acc, review) => acc + review.PersonRating, 0);
//     return totalRating / combinedReviews.length;
//   }
// );

// export const selectNumberOfReviews = createSelector(
//   (state, productId) => selectCombinedReviewsByProductId(state, productId),
//   (combinedReviews) => combinedReviews.length
// );

// // Reducer
// export default reviewSlice.reducer;
// reviewSlice.js

// reviewSlice.js
// reviewSlice.js


// redux/reviewSlice.js


// import { createSlice } from '@reduxjs/toolkit';

// const reviewSlice = createSlice({
//   name: 'reviews',
//   initialState: {
//     products: [], // This will be updated by the productSlice
//   },
//   reducers: {
//     addReview: (state, action) => {
//       const { productId, newReview } = action.payload;
//       const product = state.products.find((p) => p.id === productId);
//       if (product) {
//         product.reviewsList.push(newReview);
//       }
//     },
//   },
// });

// export const { addReview } = reviewSlice.actions;
// export default reviewSlice.reducer;



// reviewSlice.js
// reviewSlice.js







// import { createSlice } from '@reduxjs/toolkit';

// const reviewSlice = createSlice({
//   name: 'reviews',
//   initialState: {
//     reviews: {},
//   },
//   reducers: {
//     addReview: (state, action) => {
//       const { productId, newReview } = action.payload;
//       console.log('Adding review for product:', productId);
//       console.log('New Review:', newReview);
      
//       // Initialize the product if it doesn't exist
//       if (!state.reviews[productId]) {
//         state.reviews[productId] = { reviewsList: [] };
//       }
    
//       // Add the new review to the reviewsList
//       state.reviews[productId].reviewsList.push(newReview);
//       console.log('Updated Reviews:', JSON.stringify(state.reviews[productId].reviewsList, null, 2));
//     },
    
//   },
// });

// export const { addReview } = reviewSlice.actions;
// export default reviewSlice.reducer;






import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    initializeReviews: (state, action) => {
      const { productId, existingReviews } = action.payload;
      // Check if the productId already exists; if not, initialize it
      if (!state[productId]) {
        state[productId] = { reviewsList: existingReviews || [] };
      }
    },
    addReview: (state, action) => {
      const { productId, newReview } = action.payload;
      // Ensure there's an entry for the productId
      if (!state[productId]) {
        state[productId] = { reviewsList: [] };
      }
      // Add the new review
      
      state[productId].reviewsList.push(newReview);
      // console.log("revieew",state[productId].reviewsList.length)
    },
  },
});

export const { addReview, initializeReviews } = reviewSlice.actions;

export const selectReviews = (state) => state.reviews;

export default reviewSlice.reducer;
