
// import React, { useMemo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Row, Button } from 'react-bootstrap';
// import Navigation from "../component/navigation";
// import CompareProduct from '../component/compareProduct'; // Import the new component
// import useProductActions from '../utils/productThanos';

// const Compare = ({ compareLimit }) => {    

// const {compareItems, handleClearCompare } = useProductActions();
// //  const compareItems = useSelector(state => state.compare.compareItems);
// // const compareItems = useSelector(state => state.compare.compareItems);

//   return (
//     <>
//       <Navigation />
//       <h3>Compare Products</h3>
//       {(compareItems?.length || 0) === 0 ? (
//         <p>No products to compare.</p>
//       ) : (
//         <>
//           <Button onClick={handleClearCompare}>Clear All</Button>
//           <Row>
//             {compareItems.slice(0, compareLimit).map(product => (
//               <CompareProduct 
//                 key={product.id} 
//                 product={product} 
//                 // onRemove={() => handleRemoveCompare(product.id)}
//               />
//             ))}
//           </Row>
//         </>
//       )}
//     </>
//   );
// };

// export default Compare;







import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Button, Table, Container, Col } from 'react-bootstrap';
import Navigation from "../component/navigation";
import CompareProduct from '../component/compareProduct'; // Import the CompareProduct component
import useProductActions from '../utils/productThanos';
import '../css/compare.css'
import { Link } from 'react-router-dom';

const Compare = ({ compareLimitTabs ,tablelenrgth}) => { // Set default limit to 4
  const { compareItems, handleClearCompare } = useProductActions();

  // Extract unique attributes across all products
  const combinedAttributes = useMemo(() => {
    const attributeMap = {};
    compareItems?.forEach(product => {
      product.attributes?.forEach(attr => {
        if (!attributeMap[attr.attributeName]) {
          attributeMap[attr.attributeName] = {};
        }
        attributeMap[attr.attributeName][product.id] = attr.attributeValue;
      });
    });
    return attributeMap;
  }, [compareItems]);


  const getTableWidth = (length) => {
    switch (length) {
      case 1:
        return "425px";
      case 2:
        return "652px";
      case 3:
        return "875px";
      case 4:
        return "1100px";
      default:
        return "auto"; // Default for unexpected cases
    }
  };



  return (
    <>
      <Navigation />
     <section className='productComare'>

      {(compareItems?.length || 0) === 0 ? (
        <div className='noProductCompare'>
          <img src='https://res.cloudinary.com/dufdk4uq7/image/upload/v1736937624/3526554_tzab9z.png'/>
          <p>No Compared Product <span><Link to='/productCategory'>Add Product</Link></span></p>
        </div>
      ) : (
        <>
          
          
           <div className='compairContainer'>
             <div className='compairConProduct'>
                <div className='compareCencelButton'>
                  <h3>Product Comparison</h3>
                  <p>Find and select products to see the differences and similarities between them</p>
                  {compareLimitTabs===2 ? (<>
                      <p style={{height:'9px'}}>&nbsp;</p>
                      <Link to="/compare" className='showMoreCompare'>Show More Compare</Link> 
                  </>) : (
                    <>
                     <Button onClick={handleClearCompare}>Clear All</Button>
                    </>
                  )}
                 
                </div>
                <Row>
                {compareItems.slice(0, compareLimitTabs).map(product => (
                    <CompareProduct 
                      key={product.id} 
                      product={product} 
                      gridTabs={compareLimitTabs+1}
                      // onRemove={() => handleRemoveCompare(product.id)}
                    />
                  ))}
                  {/* {compareItems.map(product => (
                    <CompareProduct key={product.id} product={product} />
                  ))} */}
                </Row>
             </div>
               
                  
          
           
            
            <Table bordered className={compareItems?.length==1 ? 'compareTabsFor' : ''} style={{
        width: getTableWidth(compareItems?.length),
       
      }}>
              <thead>
                {/* <tr>
                  <th>Attribute</th>
                  {compareItems.slice(0, compareLimitTabs).map(product => (
                    <th key={product.id}>{product.name}</th>
                  ))}
                </tr> */}
              </thead>
              <tbody>
                {Object.keys(combinedAttributes).map(attributeName => (
                  <tr key={attributeName}>
                    <td>{attributeName}</td>
                    {compareItems.slice(0, compareLimitTabs).map(product => (
                      <td key={product.id}>
                        {combinedAttributes[attributeName][product.id] || 'N/A'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
           </div>
          
        </>
      )}
      </section>
    </>
  );
};

export default Compare;
