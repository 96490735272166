import React, { useEffect, useState } from "react";

import Navigation from "../component/navigation";
import HeroSlider from "../component/heroSlider";
import Categories from "../component/categories";
import WeekDeal from "../component/weekDeal";
import OfferProduct from "../component/offerProduct";
import ControlledTabs from "../component/allProductsList";
import Brands from "../component/brands";
import SpecialOffer from "../component/specialOffer";
import Facilities from "../component/facilities";
import NewsLatter from "../component/newsLatter";
import Footer from "../component/footer";
import TopNavbar from "../component/topNavbar";
import { ScreenSizeProvider } from "../context/ScreenSizeContext";
// import { ProductProvider } from "../context/ProductContext";

import OfferCard from "../component/offerCard";

const Homepage = () => {
  // const dispatch = useDispatch();
  //  useEffect(() => {
  //   dispatch(fetchProducts());
  // }, [dispatch]);
  return (
    <>
      <ScreenSizeProvider>
        <TopNavbar />
     
        <Navigation />
      </ScreenSizeProvider>
       <HeroSlider />
       <Facilities />
     
      <OfferProduct /> 
      {/* <TodaysDeals/> */}
       <WeekDeal />
       <OfferCard />
       <ControlledTabs />
      
       
      {/* <Categories /> */}
      <Brands />
      {/* <SpecialOffer /> */}
      
      <NewsLatter />
      <Footer />
    </>
  );
};
export default Homepage;
