// src/redux/uiSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCartOpen: false,
  isWishlistOpen: false,
};

const carWishSlice = createSlice({
  name: 'cartWish',
  initialState,
  reducers: {
    openCart: (state) => {
        state.isCartOpen = true;
        state.isWishlistOpen = false;
    },
    closeCart: (state) => {
        state.isCartOpen = false;
    },
    openWishlist: (state) => {
        state.isWishlistOpen = true;
        state.isCartOpen = false; // Automatically close cart
    },
    closeWishlist: (state) => {
        state.isWishlistOpen = false;
    },
    
  },
});

export const { openCart, closeCart, openWishlist, closeWishlist } = carWishSlice.actions;
export default carWishSlice.reducer;
