import React, { useState } from 'react';
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import endpoints from '../api/endpoints';
// import "./serviceDetails.css";

const Services = () => {

    const [service, setService] = useState({});
    const {id} = '675812dc72ebbab3b376bfcf';  //1;  //useParams();

    const {
      register,
      handleSubmit,
      watch,
      formState: {errors},
    } = useForm();

    
    const onSubmit = (data) => {

      // console.log(data);
      data.productId = '675812dc72ebbab3b376bfcf';  // id;

      fetch(endpoints.sslPayment,{
      // fetch("http://localhost:8000/pay-via-ajax",{
        method: "POST",
        mode:'cors',
        headers: {"content-type":"application/json", "X-CSRF-Token": "yvthwsztyeQkAPzeQ5gHgTvlyxHfsAfE",},
        body: JSON.stringify(data)        
      })
      .then((res) => res.json())
      .then((result) => {
          window.location.replace(result.original);
          console.log(result.original);
      });
      
    };


    // useEffect(() => {
    //   // fetch('http://localhost:5000/singleServices/${id}')
    //   fetch('http://localhost:6001/singleServices/${id}')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     setService(data);
    //   });
    // }, [id]);



    // const onSubmit = (data) => {

    //   // console.log(data);
    //   data.productId = '675812dc72ebbab3b376bfcf';  // id;

    //   fetch("http://localhost:6001/order",{
    //   // fetch("http://localhost:8000/pay-via-ajax",{
    //     method: "POST",
    //     headers: {"content-type":"application/json"},
    //     body: JSON.stringify(data)        
    //   })
    //   .then((res) => res.json())
    //   .then((result) => {
    //       window.location.replace(result.url);
    //       console.log(result);
    //   });
      
    // };



    return (
      <div>
        <div className="details-container row d-flex align-items-center">
            <div className = "col-md-6 text-center">
              <img className="w-50" src={service.image} alt="" />
              <h2>price: ${service?.price}</h2>          
              <p>${service?.description}</p>
            </div>
            <div className="col-md-6">
              <div className="post-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <input className="items" placeholder="Name" {...register("name")} />
                    <br />
                    
                    <select className="text-input w-50" {...register("currency")}>
                    <option value="BDT">BDT</option>
                    <option value="USD">USD</option>
                    <option value="Euro">Euro</option>
                    </select>
                
                    <input className="items" placeholder="post code" {...register("postCode")} />
                    <br />
                
                    <input className="items" placeholder="address" {...register("address")} />
                    <br />
                
                    <input className="items" placeholder="Phone Number" {...register("phone", {required: true})} />
                    <br />
                    {errors.exampleRequired && <span>This field is required</span>}
                
                    <input className="btn btn-danger mt-2 pe-5 ps-5" type="submit" value="Pay" />
                </form>
              </div>
            </div>
        </div>
      </div>          
    );
  
};

export default Services;