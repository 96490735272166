

import React from 'react';
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { openCart, openWishlist } from '../redux/cartWishSlice';
import { BsRepeat, BsShuffle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import useProductActions from '../utils/productThanos';
const CartMenu = () => {
  const {compareItems,cartItems,wishlistItems  } = useProductActions();



  const dispatch = useDispatch();
const { isCartOpen, isWishlistOpen } = useSelector((state) => state.cartWish);

  const handleOpenCart = () => {
    if (!isCartOpen) {
      dispatch(openCart());
    }
  };

  const handleOpenWishlist = () => {
    if (!isWishlistOpen) {
      dispatch(openWishlist());
    }
  };
  return (
    <div className="cart-block d-flex align-items-center justify-content-center gap-3 gap-md-4">
        <p><Link to="/compare" style={{ cursor: 'pointer',color:'#5d5d5d' }}>
        <BsShuffle />
        <span>{compareItems ? compareItems.length : 0}</span>
      </Link></p>
      <p onClick={handleOpenWishlist} style={{ cursor: 'pointer' }}>
        <AiOutlineHeart />
        <span>{wishlistItems.length}</span>
      </p>
      <p onClick={handleOpenCart} style={{ cursor: 'pointer' }}>
        <AiOutlineShoppingCart />
        <span>{cartItems.length}</span>
      </p>
    </div>
  );
};

export default CartMenu;
