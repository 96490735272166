import { FaRegStar, FaStar } from "react-icons/fa"

const StartRating=({productRating})=>{
    return (
        <>
         {[...Array(5)].map((_, i) => (
            <li key={i} className="star">
            {i < Math.floor(productRating) ? (
                <FaStar style={{ color: 'var(--ratingStar)' }} />
            ) : (
                <FaRegStar style={{ color: 'var(--ratingColorNone)' }} />
            )}
            </li>
        ))}
        </>
    )
}
export default StartRating