// src/hooks/useProductActions.js
// src/hooks/useProductActions.js
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, selectProducts } from "../redux/productSlice"; // Adjust the path as needed
import {addToCompare, clearCompare, removeFromCompare } from '../redux/compareSlice';
import { handleAddToCart, handleRemoveProduct, toggleWishlist } from './productAction';// Adjust according to your structure
import { calculateDiscountAndFinalPrice } from './priceCalculation'; // Adjust according to your structure
import { removeFromWishlist, clearWishlist } from '../redux/wishlistSlice';
import { useNavigate } from 'react-router-dom';
import {
  increaseQuantity,
  decreaseQuantity,
  addToCart,
  clearCart,
} from "../redux/cartSlice";
import { selectCombinedReviews, selectAverageRating, selectNumberOfReviews } from '../utils/reviewSelector';
import { toast } from 'react-toastify';

const useProductActions = (product) => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const navigate = useNavigate(); // Add useNavigate here
  const [isAddedW, setIsAddedW] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [orderQuantity, setQuantity] = useState(1); 

  const cartItems = useSelector(state => state.cart.cartItems);
  const isCartOpen = useSelector((state) => state.cartWish.isCartOpen);
  

  const wishlistItems = useSelector(state => state.wishlist.wishlistItems);
  const isWishlistOpen = useSelector((state) => state.cartWish.isWishlistOpen);

 const compareItems = useSelector(state => state.compare?.compareItems || []);


const cartItem = useMemo(() => {
  return cartItems.find((item) => item.id === product?.id);
}, [cartItems, product]);
  
  const isWishlisted = useMemo(() => {
    return wishlistItems.some((item) => item.id === product?.id);
}, [wishlistItems, product]);


const isComparelisted = useMemo(() => {
  return compareItems.some(item => item.id === product?.id);
}, [compareItems, product]);



  const regularPrice = useMemo(() => product?.price || 0, [product?.price]);
  const { finalPrice } = useMemo(() => {
    return product ? calculateDiscountAndFinalPrice(product.price, product.discount) : { finalPrice: 0 };
}, [product?.price, product?.discount]);



// cart items
useEffect(() => {
  if (cartItem) {
      setQuantity(cartItem.orderQuantity); // Sync local quantity with cart if product is in the cart
  }
}, [cartItem]);

useEffect(() => {
  if (cartItem) {
      // If the product is already in the cart, use its quantity.
      setQuantity(cartItem.orderQuantity);
  } else {
      // If it's a new product, reset the quantity to 1.
      setQuantity(1);
  }
}, [cartItem, product?.id]); 
const handleAddToCards = useCallback(() => {
  const updatedProduct = { ...product, orderQuantity };
  handleAddToCart(dispatch, updatedProduct, cartItems, setIsAdded);
}, [dispatch, product, orderQuantity, cartItems]);

const handleRemoveProductDetails = useCallback(() => {
  if (product && product.id) {
      handleRemoveProduct(dispatch, product.id, cartItems);
  }
}, [dispatch, product, cartItems]);

const handleClearCart = useCallback(() => {
  dispatch(clearCart());
  toast.warning(`Clear Cart Items`);
});

const increaseLocalQuantity = useCallback(() => {
  if (cartItem) {
      // If product is in the cart, dispatch the increaseQuantity action
      dispatch(increaseQuantity({ id: product.id }));
  } else {
      // If not in the cart, update the local quantity
      setQuantity((prevQuantity) => prevQuantity + 1);
  }
}, [dispatch, cartItem, product]);

const decreaseLocalQuantity = useCallback(() => {
  if (cartItem) {
      // If product is in the cart, dispatch the decreaseQuantity action
      dispatch(decreaseQuantity({ id: product.id }));
  } else {
      // If not in the cart, update the local quantity
      setQuantity((prevQuantity) =>
          prevQuantity > 1 ? prevQuantity - 1 : 1
      );
  }
}, [dispatch, cartItem, product]);

const totalPrice = cartItems.reduce(
  (total, item) => total + item.orderPrice * item.orderQuantity,
  0
);
const vat_percentage=5
const vat = totalPrice * 0.05;
const totalWithVat = totalPrice + vat;
const deleverCharge=65;
const showPrice = useMemo(() => product?.showPrice || 0, [product?.showPrice]);




// wishlist items
const handleAddToWishlist = useCallback(() => {
  toggleWishlist( dispatch,product,wishlistItems,isWishlisted,setIsAddedW
  );
}, [dispatch, product, wishlistItems, isWishlisted]);

const handleRemoveWishlist = useCallback((product) => {
  const isWishlisted = wishlistItems.some((item) => item.id === product.id);
  toggleWishlist(dispatch, product, wishlistItems, isWishlisted, setIsAddedW);
});

const handleClearWish =useCallback(() => {
  dispatch(clearWishlist());
  toast.warning(`Clear WishList`);
});


const handleAddToCartFromWishlist =useCallback((product) => {
//   const updatedProduct = { ...product, orderQuantity };
//   handleAddToCart(dispatch, updatedProduct, cartItems, setIsAdded);
// }, [dispatch, product, orderQuantity, cartItems]);

  const isProductInCart = cartItems.some((item) => item.id === product.id);
  if (!isProductInCart) {
    dispatch(addToCart(product));
    toast.success(`${product.name} added to cart from wishlist!`);
  } else {
    console.log("Product is already in the cart:", product);
    toast.warning(`${product.name} is already in the cart!`);
  }
});







// compare items
const handleCompareProduct = useCallback(() => { 
  dispatch(addToCompare(product));
}, [dispatch, product]);

const handleClearCompare = useCallback(() => {
  dispatch(clearCompare());
}, [dispatch]);

const handleRemoveCompare = useCallback((productId) => {
  dispatch(removeFromCompare(productId));
}, [dispatch]);






// view items
const handleViewProduct = useCallback(() => {
    if (product && product?.id) {
      window.scrollTo(0, 0);
      navigate(`/product/${product.id}`);
    } else {
      console.error("Product or product ID is undefined");
    }
  }, [product, navigate]);


//   const combinedImages = useMemo(() => {
//     return product ? [...(product.images || []), product.image] : [];
// }, [product]);

const combinedImages = useMemo(() => {
  if (product) {
      // Use images if they exist; otherwise, fallback to the single product.image
      const imageArray = product.image?.length > 0 ? [...(product.images), product.image] : [product.images,product.image=[]];
      // Ensure no duplicate values
      return [...new Set(imageArray)];
  }
  return [];
}, [product]);








// controls or top productlist items
const [key, setKey] = useState("latestProduct");
// const latestProducts = useMemo(
//   () =>
//     products.filter((product) =>
//       product.tags.includes("Latest Product")
//     ),
//   [products]
// );
const latestProducts = useMemo(() => products.slice(-6), [products]);
const topRatingProducts = useMemo(() => 
  [...products].sort((a, b) => b.rating - a.rating).slice(0, 6), 
  [products]
);
const bestSellingProducts = useMemo(() => 
  [...products].sort((a, b) => b.reviews - a.reviews).slice(0, 6), 
  [products]
);
const featureProducts = useMemo(() => 
  products.filter(product => product.isUpcoming).slice(0, 6), 
  [products]
);


const [loading, setLoading] = useState(true);
useEffect(() => {
  // Update loading state based on products length
  if (products.length > 0) {
    setLoading(false);
  }
}, [products]); // Dependency on products








// review Items
const combinedReviewsList = useSelector(selectCombinedReviews(product?.id));
const averageRating = selectAverageRating(combinedReviewsList);
const numberOfReviews = selectNumberOfReviews(combinedReviewsList);


  return {
    cartItem,
    cartItems,
    orderQuantity,
    isCartOpen,
    totalPrice,
    vat_percentage,
    vat,
    totalWithVat,
    deleverCharge,
    showPrice,



    compareItems,
    isComparelisted,

    wishlistItems,
    isWishlisted,
    isWishlistOpen,
    isAddedW,
    
    finalPrice,
    regularPrice,

   
    combinedImages,
    combinedReviewsList,
    averageRating,
    numberOfReviews,

    handleViewProduct,
    handleCompareProduct,
    handleClearCompare,
    handleRemoveCompare,

    handleAddToWishlist,
    handleRemoveWishlist,
    handleAddToCartFromWishlist,
    handleClearWish,
    

    handleAddToCards,
    handleClearCart,
    handleRemoveProductDetails,
    increaseLocalQuantity,
    decreaseLocalQuantity,


    key,
    setKey, 
    latestProducts,
    topRatingProducts,
    bestSellingProducts,
    featureProducts,
    loading,
    setLoading
  };
};

export default useProductActions;

