// src/utils/reviewSelectors.js

import { createSelector } from 'reselect';
import { selectProducts } from '../redux/productSlice';
import { selectReviews } from '../redux/reviewSlice';

export const selectCombinedReviews = (productId) => createSelector(
  [selectProducts, selectReviews],
  (products, reviews) => {
    const product = products.find(p => p.id === productId);
    const staticReviews = product?.reviewsList || [];
    const dynamicReviews = reviews[productId]?.reviewsList || [];

    // Combine static and dynamic reviews without duplicates
    const combinedReviewsList = [
      ...staticReviews,
      ...dynamicReviews.filter(dynamicReview =>
        !staticReviews.some(staticReview =>
          staticReview.name === dynamicReview.name &&
          staticReview.PersonRating === dynamicReview.PersonRating &&
          staticReview.review === dynamicReview.review
        )
      )
    ];

    return combinedReviewsList;
  }
);
export const selectAverageRating = (combinedReviewsList) => {
  if (combinedReviewsList.length === 0) return 0; // Handle case with no reviews
  const totalRating = combinedReviewsList.reduce((sum, review) => sum + review.PersonRating, 0);
  return (totalRating / combinedReviewsList.length).toFixed(1); // Return average with one decimal place
};

export const selectNumberOfReviews = (combinedReviewsList) => {
  return combinedReviewsList.length;
};

