
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
 
    addToCart: (state, action) => {
      const { id, orderQuantity } = action.payload;
      const itemExists = state.cartItems.find((item) => item.id === id);
      if (itemExists) {
        // Update the quantity of the existing item
        itemExists.orderQuantity = orderQuantity;
      } else {
        // Add new item with specified quantity
        // state.cartItems.push({ ...action.payload, quantity });
        state.cartItems.push({ ...action.payload, orderQuantity: orderQuantity || 1 });
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
   
    increaseQuantity: (state, action) => {
      const item = state.cartItems.find((item) => item.id === action.payload.id);
      if (item) {
        item.orderQuantity++;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      }
    },
    decreaseQuantity: (state, action) => {
      const item = state.cartItems.find((item) => item.id === action.payload.id);
      if (item && item.orderQuantity > 1) {
        item.orderQuantity--;
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      }
    },
    removeFromCart: (state, action) => {
    
      state.cartItems = state.cartItems.filter((item) => item.id !== action.payload.id);
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    clearCart: (state) => {
      state.cartItems = [];
      localStorage.setItem('cartItems', JSON.stringify([]));
    },
  },
});

export const { addToCart, increaseQuantity, decreaseQuantity, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;

