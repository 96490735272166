import React, { useEffect } from "react";
const TawkTo = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/65ae3ab70ff6374032c33d30/1hko9anpl";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null; // No UI elements are rendered
};
export default TawkTo;