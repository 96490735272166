


import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import '../css/sliderCustom.css';
import '../css/heroSlider.css';

import LoadingSpinner from "../component/loadingSpinner";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { fetchSliderData } from "../redux/sliderSlice";  // Adjust the import path as needed
import { BsCart4 } from "react-icons/bs";

const HeroSlider = () => {
  const dispatch = useDispatch();
  const { sliderData, loading, error } = useSelector((state) => state.slider);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplaySpeed: 8000,
    draggable: true,
  };
    
  useEffect(() => {
    dispatch(fetchSliderData());
  }, [dispatch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return  <LoadingSpinner />;
  }

  return (
    <section className="hero">
      <Row>
        <Col md={12}>
          <div className="slider-container">
            <Slider {...settings}>
              {sliderData.map((slide, index) => (
                <div className="heroSlide" key={index}>
                  <img src={slide.image} alt="hero" />
                  <Container>
                    <Row>
                      <Col md={12}> 
                        <Card className="heroCard">
                          <div className="lineAnimated">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                          </div>
                          <h1 className="heroTitle">
                            <p className="slideContent">{slide.title}</p> 
                            <p className="slideContent">{slide.subtitle}</p>
                          </h1>
                          <h5 className="sologan">
                            <p className="slideContent">{slide.description}</p>
                          </h5>
                          
                          <div className="d-flex gap-3 align-items-center mt-4 exploreshopBtn">
                            <Link to={slide.buttonLink} className="itemsBtn btn btn-primary slideContent">
                              <p className="p-0"><BsCart4 /></p>{slide.buttonText}
                            </Link>
                            {/* <p className="freshProduct slideContent">{slide.freshProductText}</p> */}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default HeroSlider;
