// src/components/LoadingSpinner.js
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import '../css/loadingSpinner.css'; // Custom CSS for loading spinner

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
