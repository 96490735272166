

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   loginSuccess: !!localStorage.getItem("accessToken"), // Check for token in localStorage
//   accessToken: localStorage.getItem("accessToken") || null, // JWT token
//   user: JSON.parse(localStorage.getItem("user")) || null
// };

// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     setLoginSuccess: (state, action) => {
//       const { user, accessToken } = action.payload;
//       state.loginSuccess = true;
//       state.accessToken = accessToken;
//       state.user = user;
//       // Save to localStorage
//       localStorage.setItem("accessToken", accessToken);
//       localStorage.setItem("user", JSON.stringify(user));
//     },
//     logout: (state) => {
//       state.loginSuccess = false;
//       state.accessToken = null;
//       state.user = null;
//       // Clear localStorage
//       localStorage.removeItem("accessToken");
//       localStorage.removeItem("user");
//     },
//   },
// });

// export const { setLoginSuccess, logout } = authSlice.actions;
// export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import endpoints from "../api/endpoints";

// Initial state
const initialState = {
  loginSuccess: !!localStorage.getItem("accessToken"), // Check for token in localStorage
  accessToken: localStorage.getItem("accessToken") || null, // JWT token
  user: JSON.parse(localStorage.getItem("user")) || null,
  logoutLoading: false, // Track logout status
  logoutError: null, // Track logout error
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginSuccess: (state, action) => {
      const { user, accessToken } = action.payload;
      state.loginSuccess = true;
      state.accessToken = accessToken;
      state.user = user;

      // Save to localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("user", JSON.stringify(user));
    },
    logoutStart: (state) => {
      state.logoutLoading = true;
      state.logoutError = null;
    },
    logoutSuccess: (state) => {
      state.loginSuccess = false;
      state.accessToken = null;
      state.user = null;
      state.logoutLoading = false;

      // Clear localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    },
    logoutFailure: (state, action) => {
      state.logoutLoading = false;
      state.logoutError = action.payload; // Capture error message
    },
  },
});

export const { setLoginSuccess, logoutStart, logoutSuccess, logoutFailure } =
  authSlice.actions;

// Thunk to handle logout with API call
export const logout = () => async (dispatch, getState) => {
  try {
    dispatch(logoutStart());

    // Get the access token from the state
    const state = getState();
    const token = state.auth.accessToken;

    // Make the GET API call to logout with Authorization header
    const response = await fetch(endpoints.logout, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add Bearer token
      },
    });
   console.log(response)
    if (!response.ok) {
      throw new Error("Logout failed. Please try again.");
    }
   
    dispatch(logoutSuccess()); // Clear the state
    
  } catch (error) {
    dispatch(logoutFailure(error.message));
  }
};

export default authSlice.reducer;
