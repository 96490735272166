import React from 'react';
import { useLocation } from 'react-router-dom';
import { BsHouseDoor } from "react-icons/bs";
import { BsChevronRight } from "react-icons/bs";

const Breadcrumb = () => {
  const location = useLocation();

  // Custom titles for specific routes
  const customTitles = {
    '/about': 'About Us',
    '/services': 'Our Services',
    '/contact': 'Contact Us',
    '/Signup_jahangir': 'Sign Up',
    '/signup_jahangir': 'Sign Up',
    '/signup': 'Sign Up',
    '/signup_abir': 'Sign Up',
    '/checkout': 'Cart',
    '/signin': 'Sign In',
    '/forgotpassword': 'Forgot Password',
    '/cartform': 'Cart Form'
  };

  // Generate breadcrumb items from the current path
  const getBreadcrumbs = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    return pathnames.map((name, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
      return { name, routeTo };
    });
  };

  const breadcrumbs = getBreadcrumbs();

  // Extract page title: use custom title if available, otherwise format the last breadcrumb
  const pageTitle = breadcrumbs.length > 0
    ? customTitles[location.pathname] ||
      breadcrumbs[breadcrumbs.length - 1].name.replace(/-/g, ' ')
    : 'Home';

  return (
    <div className="breadcrumb pageTitleArea">
      <div className="pageTitleAreaWrap">
        {/* Page Title */}
        <h1>{pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)}</h1>

        {/* Breadcrumb Navigation */}
        <ul className="breadcrumb-list d-flex">
          <li>
            <a href="/">
            <BsHouseDoor />
            </a>
          </li>
          <li>
            <a href="/">Home</a>
          </li>
          {breadcrumbs.map((crumb, index) => (
            <li key={index}>
              <span><BsChevronRight /></span>
              <a href={crumb.routeTo}>
                {customTitles[crumb.routeTo] ||
                  crumb.name.replace(/-/g, ' ').charAt(0).toUpperCase() +
                    crumb.name.replace(/-/g, ' ').slice(1)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
